import images from "../../abstracts/Images";
import Functions from "../../abstracts/Functions";

const Offline = () => {
  Functions.pageInfo({
    title: "Ordapple | Return Policy!",
    desc: "Our Return policy",
  });

  return (
    <div className="account">
      <div className="error-container" style={{ padding: "10px" }}>
        <h2>Return Policy!</h2>
        <br />
        <p style={{ color: "#777", fontSize: "20px" }}>Physical Products</p>
        <p style={{ color: "#000" }}>
          In order to be eligible for a refund, you have to return the product
          within 30 calendar days of your purchase. The product must be in the
          same condition that you receive it and undamaged in any way. <br />
          <br />
          After we receive your item, our team of professionals will inspect it
          and process your refund. The refund may take 5 to 10 business days for
          the refund to appear on your credit card statement. <br />
          <br />
          If the product is damaged in any way, or you have initiated the return
          after 30 calendar days have passed, you will not be eligible for a
          refund. Refunds will not be paid on delivery.
        </p>
        <br />
        <p style={{ color: "#777", fontSize: "20px" }}>Perishable Products</p>
        <p style={{ color: "#000" }}>
          Due to the nature of some shops and products they sell, items that
          expire sooner than 1 month from the date of the purchase are not
          eligible for a refund. <br />
          <br />
          For any other items to be eligible for a refund, you have to return
          the item you have purchased to us within 7 calendar days of the
          purchase. The item must unopened and in its original condition. <br />
          <br />
          If our products arrived damaged, rotten or contaminated in any way,
          please contact us right away. Refunds will not be paid on delivery.
          <br />
          <br />
          If anything is unclear or you have more questions feel free to contact
          our customer support team.
        </p>{" "}
        <br />
      </div>
    </div>
  );
};

export default Offline;
