import { Link } from "react-router-dom";
import images from "../abstracts/Images";
import { useEffect, useState } from "react";
import Functions from "../abstracts/Functions";
import axios from "axios";
import TimeAgo from "react-timeago";

const InboxCard = ({
  seen,
  setSeenStatus,
  _id,
  username,
  msg,
  time,
  from,
  url,
}) => {
  return (
    <Link
      to={url ? url : "/inbox"}
      className={`list-card  ${!seen && "unseen"}`}
      onClick={(e) => {
        setSeenStatus({ seen: true, _id });
      }}
    >
      <div className="img foll">
        <img src={images.avatar} alt="artist" />
      </div>
      <div className="info">
        <h2>{username}</h2>
        <h3>
          {" "}
          {msg} <span className="dot"></span>
          <TimeAgo date={time} />{" "}
        </h3>
      </div>
    </Link>
  );
};
export default InboxCard;
