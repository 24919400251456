const ReviewForm = ({
  formDisplay,
  toggleFormDisplay,
  changeReviewVal,
  msg,
  reviewVal,
  addReview,
}) => {
  return (
    <div
      className="glowing-form"
      style={{ display: formDisplay ? "flex" : "none" }}
    >
      <div className="contents">
        <div className="close" onClick={toggleFormDisplay}>
          X
        </div>
        <input
          type="text"
          name="review"
          placeholder={`Write review`}
          onChange={changeReviewVal}
          value={reviewVal}
        />
        <p style={{ color: "red" }}>{msg}</p>
        <div className="row-btns">
          <button
            onClick={(e) => {
              addReview(e, "bad");
            }}
            className="red"
          >
            Bad <i className="fas fa-thumbs-down"></i>
          </button>
          <button
            onClick={(e) => {
              addReview(e, "good");
            }}
            className="green"
          >
            Good <i className="fas fa-thumbs-up"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewForm;
