const Loader = () => {
  return (
    <div className="in-spinner">
      <div className="one"></div>
      <div className="two"></div>
    </div>
  );
};

export default Loader;
