const FaqCard = ({ head, body }) => {
  return (
    <div className="help-card">
      <h1>{head}</h1>
      {body}
    </div>
  );
};

export default FaqCard;
