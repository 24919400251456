const GET_USERS = (data) => {
  return {
    type: "GET_USERS",
    payload: data,
  };
};
const EDIT_USER = (data) => {
  return {
    type: "EDIT_USER",
    payload: data,
  };
};
const GET_STATS = (data) => {
  return {
    type: "GET_STATS",
    payload: data,
  };
};

const Employee = {
  GET_USERS,
  EDIT_USER,
  GET_STATS,
};

export default Employee;
