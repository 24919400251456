import ProtectedRoute from "../ProtectedRoute";
import BuyerAccount from "../buyer/BuyerAccount";
import BuyerOrders from "../buyer/BuyerOrders";
import BuyerDelAcc from "../buyer/BuyerDelAcc";
import BuyerEditAcc from "../buyer/BuyerEditAcc";
import Inbox from "../buyer/Inbox";

const Buyer = [
  {
    routeType: ProtectedRoute,
    path: "/buyer/account",
    exact: true,
    component: BuyerAccount,
  },
  {
    routeType: ProtectedRoute,
    path: "/buyer/orders/",
    exact: true,
    component: BuyerOrders,
  },
  {
    routeType: ProtectedRoute,
    path: "/delete-account",
    exact: true,
    component: BuyerDelAcc,
  },
  {
    routeType: ProtectedRoute,
    path: "/buyer/edit-account",
    exact: true,
    component: BuyerEditAcc,
  },
  {
    routeType: ProtectedRoute,
    path: "/inbox",
    exact: true,
    component: Inbox,
  },
];

export default Buyer;
