import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <div className="services-container">
      <div id="pricing" className="pricing-container">
        <div className="heading">
          <h1>Sell here</h1>
          <span></span>
        </div>

        <div className="pricing-content">
          <div className="card">
            <h2>Free</h2>
            <p className="price">R0</p>
            <p className="includes">Includes:</p>
            <ul>
              <li>valid for 1 month</li>
              <li>Space for 5 items</li>
              <li>Transaction tracking system</li>
              <li>Order tracking system</li>
              <li>Unlimited cashout requests</li>
              <li>Limited client support</li>
              <li>No social media promotion</li>
            </ul>
            <Link to="/admin/apply/free">Get started</Link>
          </div>
          <div className="card">
            <h2>Basic</h2>
            <p className="price">R120</p>
            <p className="includes">Includes:</p>
            <ul>
              <li>Unlimited access</li>
              <li>Space for 24 items</li>
              <li>Transaction tracking system</li>
              <li>Order tracking system</li>
              <li>Unlimited cashout requests</li>
              <li>Limited client support</li>
              <li>Social media promotion</li>
            </ul>
            <Link to="/admin/apply/basic">Get started</Link>
          </div>
          <div className="card">
            <h2>Standard</h2>
            <p className="price">R220</p>
            <p className="includes">Includes:</p>
            <ul>
              <li>Unlimited access</li>
              <li>Space for 50 items</li>
              <li>Transaction tracking system</li>
              <li>Order tracking system</li>
              <li>Unlimited cashout requests</li>
              <li>Unlimited client support</li>
              <li>In-app promotion</li>
              <li>Social media promotion</li>
            </ul>
            <Link to="/admin/apply/standard">Get started</Link>
          </div>
          <div className="card">
            <h2>Mega</h2>
            <p className="price">R350</p>
            <p className="includes">Includes:</p>
            <ul>
              <li>Unlimited access</li>
              <li>Unlimited space for items</li>
              <li>Transaction tracking system</li>
              <li>Order tracking system</li>
              <li>Unlimited cashout requests</li>
              <li>Unlimited client support</li>
              <li>In-app promotion</li>
              <li>Social media promotion</li>
            </ul>
            <Link to="/admin/apply/mega">Get started</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
