import AccountInfo from "../../assets/AccountInfo";
import ItemCard from "./assets/ItemCard";
import AdminNav from "./assets/AdminNav";
import Whs from "./assets/Whs";
import { createRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Functions from "../../abstracts/Functions";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import AdminActions from "../../redux/actions/AdminActions";
import Loading from "../../redux/actions/Loading";
import { useEffect } from "react";
import axios from "axios";
import Popup from "../../assets/Popup";
import UploadPP from "../../assets/UploadPP";
import PreLoader from "../../assets/PreLoader";
import EditProduct from "./assets/EditProduct";
import Loader from "../../abstracts/Loader";

const AdminAcc = ({ history }) => {
  // Get state value from redux
  const user = useSelector((state) => state.user);
  const admin_info = useSelector((state) => state.admin_info);
  const admin_products = useSelector((state) => state.admin_products);
  const flash = useSelector((state) => state.flash);

  // Use state
  const [showWhs, setShowWhs] = useState(false);
  const [showProd, setShowProd] = useState(false);
  const [display, setDisplay] = useState(true);
  const [uploadDisplay, setUploadDisplay] = useState(false);
  const [fileName, setFileName] = useState("");
  const [waiting, setWaiting] = useState(true);
  const [imgSrc, setImgSrc] = useState(null);
  const [selectedProd, setSelectedProd] = useState({});

  // Refs
  const imgRef = createRef();

  Functions.pageInfo({
    title: "Ordapple | Manage Admin account",
    desc: "Manage your Admin account",
  });

  // Redux
  const dispatch = useDispatch();

  // Use effect
  useEffect(() => {
    // Removing deafults
    dispatch(RESTORE_FLASH());
    dispatch(Loading.FINISHED_LOADING());

    axios({
      url: `${Functions.getAPI()}/admin/${user._id}`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        dispatch(AdminActions.ADMIN_INFO(data.shop));
        dispatch(AdminActions.PRODUCTS(data.products));
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
          history.push("/admin/edit-account");
        } else {
          console.log(e);
        }
      });
  }, [dispatch, user._id, history]);

  // File reader
  const reader = new FileReader();

  // Toggle display of the working hours editor
  const toggleWhsDisplay = (e) => {
    e.preventDefault();
    setShowWhs(!showWhs);
  };

  // Toggle display of the clicked product editor
  const toggleProdDisplay = (e) => {
    e.preventDefault();
    setShowProd(!showProd);
  };

  // Toggle image updater
  const togglePopupDisplay = () => {
    setUploadDisplay(!uploadDisplay);
    setImgSrc(null);
    setWaiting(true);
  };

  const deleteItem = (e, productId) => {
    e.preventDefault();
    dispatch(RESTORE_FLASH());
    dispatch(Loading.LOADING());

    axios({
      url: `${Functions.getAPI()}/admin/product/${productId}`,
      method: "POST",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Display a popup message
        setDisplay(true);

        // Close the loader
        dispatch(Loading.FINISHED_LOADING());

        dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        dispatch(AdminActions.REMOVE_PRODUCT(productId));
      })
      .catch((err) => {
        // Display a popup message
        setDisplay(true);

        // Close the loader
        dispatch(Loading.FINISHED_LOADING());

        const res = err.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(err);
        }
      });
  };

  // Getting the image uploaded
  const uploadingImage = async (e) => {
    // Getting the currently uploaded file
    const file = await e.target.files[0];

    // Setting the file name
    setFileName(file.name);

    // Updating the waiting state
    setWaiting(false);

    if (file) {
      if (file.type.match(/image/i)) {
        // Reads the uploaded file as dataUrl
        reader.readAsDataURL(file);

        // After reading the file it fires the load function
        reader.onload = async (e) => {
          // This is the uploaded file as DataUrl
          const uploadedFile = e.target.result;

          // Dynamically creating an image elem to use
          const img = document.createElement("img");
          img.src = uploadedFile;

          // After the image source has loaded the function below fires
          img.onload = (e) => {
            // Dynamically creating an image elem to use
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // The maximum width for resizing
            const mx_wid = 400;

            // Getting the correct scale for the image
            const scaleSize = mx_wid / e.target.width;

            // Setting the width and height for canvas
            canvas.width = mx_wid;
            canvas.height = e.target.height * scaleSize;

            // Drawing the image before resizing
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

            // Getting the resized image as dataUrl
            const url = ctx.canvas.toDataURL(e.target, file.type);

            // Setting the source (state) for the image
            setImgSrc(url);
          };
        };
      } else {
        setUploadDisplay(false);
        setDisplay(true);
        dispatch(FLASH({ err: "", success: "", warn: "Only images allowed!" }));
      }
    } else {
      // If no image is uploaded, set the img source to null
      setImgSrc(null);
    }
  };

  // updating profile picture
  const updateProfilePic = () => {
    // Data
    const data = {};
    data["profilePic"] = imgSrc;
    data["filename"] = fileName;
    data["image"] = admin_info.image ? admin_info.image : {};

    // Restoring the flash messages
    dispatch(RESTORE_FLASH());

    // Showing loader
    dispatch(Loading.LOADING());
    // remove image uploader window
    setUploadDisplay(false);

    // Server request
    const request = () => {
      axios({
        url: `${Functions.getAPI()}/admin/profile-pic`,
        method: "POST",
        withCredentials: true,
        data,
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Remove the loader
          dispatch(Loading.FINISHED_LOADING());

          // Display popup
          setDisplay(true);

          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
          dispatch(AdminActions.UPDATE_PROFILE_PIC(data.image));
        })
        .catch((e) => {
          // Remove the loader
          dispatch(Loading.FINISHED_LOADING());

          // Display popup
          setDisplay(true);

          const res = e.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
            console.log(res.data);
          } else {
            console.log(e);
          }
        });
    };

    if (imgSrc) {
      request();
    } else {
      // Display popup
      setDisplay(true);

      // Remove updateAcc window
      setUploadDisplay(false);

      // Dispatch a popup message
      dispatch(FLASH({ err: "", success: "", warn: "No image selected!" }));
    }
  };

  // Getting the product to edit
  const edit_prod = (e, id) => {
    e.preventDefault();
    toggleProdDisplay(e);
    const prod = admin_products.filter((i) => i._id === id);
    setSelectedProd(prod[0]);
  };

  return (
    <div>
      {/* Loader */}
      <PreLoader />

      {/* Popup messages */}
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}

      {admin_info.name && <AdminNav page="account" />}

      <div className="account">
        <div className="account-container">
          {admin_info.name ? (
            <>
              <AccountInfo
                buyer={false}
                toggleWhsDisplay={toggleWhsDisplay}
                email={user.email}
                name={admin_info.name}
                img={admin_info?.image}
                followers={admin_info.followers}
                shopVerified={admin_info.verified}
                togglePopupDisplay={togglePopupDisplay}
                uploadingImage={uploadingImage}
              />
              <div className="account-products-container">
                <div className="line"></div>
                <h2>Items</h2>
                {admin_products.length >= 1 ? (
                  admin_products.map((prod, ind) => (
                    <ItemCard
                      key={ind}
                      name={prod.name}
                      price={prod.price}
                      id={prod._id}
                      shopId={admin_info._id}
                      img={prod.image.Location}
                      deleteItem={deleteItem}
                      currency={prod.currency}
                      edit_prod={edit_prod}
                    />
                  ))
                ) : (
                  <p className="no-items">No Products found!</p>
                )}
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <Whs
        toggleWhsDisplay={toggleWhsDisplay}
        showWhs={showWhs}
        dispatch={dispatch}
        FLASH={FLASH}
        setDisplay={setDisplay}
      />

      <UploadPP
        updateProfilePic={updateProfilePic}
        imgRef={imgRef}
        display={uploadDisplay}
        waiting={waiting}
        imgSrc={imgSrc}
        toggleDisplay={togglePopupDisplay}
      />

      <EditProduct
        showProd={showProd}
        setShowProd={setShowProd}
        toggleProdDisplay={toggleProdDisplay}
        selectedProd={selectedProd}
        setDisplay={setDisplay}
      />
    </div>
  );
};

export default AdminAcc;
