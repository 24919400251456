import { Link } from "react-router-dom";

const AdminNav = ({ page }) => {
  return (
    <div className="admin-nav">
      <div className="nav-container">
        <Link
          to="/admin/account"
          className={page === "account" ? "active" : ""}
        >
          Account
        </Link>
        <Link to="/admin/orders" className={page === "orders" ? "active" : ""}>
          Orders
        </Link>
        <Link
          to="/admin/add-items"
          className={page === "add-items" ? "active" : ""}
        >
          + Add
        </Link>
      </div>
    </div>
  );
};

export default AdminNav;
