import { Route } from "react-router";
import Offline from "../static/Offline";
import About from "../static/About";
import Contact from "../static/Contact";
import Faq from "../static/Faq";
import ActivateAcc from "../static/ActivateAcc";
import Success from "../static/Success";
import AppSuccess from "../static/AppSuccess";
import ReturnPolicy from "../static/ReturnPolicy";

const Static = [
  {
    routeType: Route,
    path: "/offline",
    exact: true,
    component: Offline,
  },
  {
    routeType: Route,
    path: "/about",
    exact: true,
    component: About,
  },
  {
    routeType: Route,
    path: "/contact",
    exact: true,
    component: Contact,
  },
  {
    routeType: Route,
    path: "/help",
    exact: true,
    component: Faq,
  },
  {
    routeType: Route,
    path: "/account/verify",
    exact: true,
    component: ActivateAcc,
  },
  {
    routeType: Route,
    path: "/success",
    exact: true,
    component: Success,
  },
  {
    routeType: Route,
    path: "/apply/success",
    exact: true,
    component: AppSuccess,
  },
  {
    routeType: Route,
    path: "/returns",
    exact: true,
    component: ReturnPolicy,
  },
];

export default Static;
