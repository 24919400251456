import Functions from "../../abstracts/Functions";
import Tile from "../../assets/Tile";

const Categories = () => {
  const cats = [
    {
      name: "Books",
      route: "/category/book",
    },
    {
      name: "Fashion",
      route: "/category/fashion",
    },
    {
      name: "Food",
      route: "/category/food",
    },
    {
      name: "Gadgets",
      route: "/category/gadget",
    },
    {
      name: "Kitchen",
      route: "/category/kitchen",
    },
    {
      name: "Services",
      route: "/category/service",
    },
    {
      name: "Tech",
      route: "/category/tech",
    },

    {
      name: "Others",
      route: "/category/other",
    },
  ];

  Functions.pageInfo({
    title: "Filter Items By Category",
    desc: "Get Items From A Specific Category",
  });

  return (
    <div className="tile-container">
      <div className="heading">
        <h1>Categories</h1>
      </div>

      <div className="tile-content">
        {cats.map((item, ind) => {
          return <Tile key={ind} option={item.name} route={item.route} />;
        })}
      </div>
    </div>
  );
};
export default Categories;
