const Form = (props) => {
  return (
    <div className="register-login">
      <div className="form">
        <form
          className={`form-slide ${props.className}`}
          method="POST"
          encType="multipart/form-data"
        >
          {props.children}
        </form>
      </div>
    </div>
  );
};

export default Form;
