const Messages = ({ err, success, warn }) => {
  if (err !== undefined) {
    return (
      <div className="msg">
        <div className="error-msg">
          <p> {err} </p>
        </div>
      </div>
    );
  } else if (success !== undefined) {
    return (
      <div className="msg">
        <div className="success-msg">
          <p> {success} </p>
        </div>
      </div>
    );
  } else if (warn !== undefined) {
    return (
      <div className="msg">
        <div className="warning-msg">
          <p> {warn} </p>
        </div>
      </div>
    );
  }
};

export default Messages;
