const USER = (state = {}, { type, payload }) => {
  switch (type) {
    case "USER_INFO":
      return payload;
    case "EDIT_ACCOUNT":
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.phone = payload.phone;
      return state;
    case "FOLLOW_SHOP":
      state.following = [...state.following, payload];
      return state;
    case "UNFOLLOW_SHOP":
      const updatedFollowing = state.following.filter((el) => el !== payload);
      state.following = updatedFollowing;
      return state;
    case "DELETE_ACCOUNT":
      return {};
    default:
      return state;
  }
};
export default USER;
