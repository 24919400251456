import { useSelector } from "react-redux";
import BuyerActions from "../redux/actions/BuyerActions";
import { Link, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import FLASH from "../redux/actions/Flash";
import Popup from "./Popup";
import AdminActions from "../redux/actions/AdminActions";
import Functions from "../abstracts/Functions";
import TimeAgo from "react-timeago";
import { useState, useEffect } from "react";

const OrdersCard = ({
  buyer,
  display,
  setDisplay,
  dispatch,
  orderNumber,
  customerName,
  customerEmail,
  customerPhone,
  customerAddress,
  pickUpTime,
  dateOrdered,
  elegibleForDelivery,
  itemsInOrder,
  totalPrice,
  status,
  agentCode,
}) => {
  // Getting values from redux state
  const admin_info = useSelector((state) => state.admin_info);
  const flash = useSelector((state) => state.flash);
  const path = useLocation().pathname;
  const history = useHistory();

  // Use state
  const [statusVal, setStatusVal] = useState("");

  // Use effect
  useEffect(() => {
    setStatusVal(status);
  }, []);

  // update the status
  const updateStatus = (e) => {
    e.preventDefault();

    const req = (stts) => {
      axios({
        url: `${Functions.getAPI()}/orders/update/${orderNumber}`,
        method: "POST",
        withCredentials: true,
        data: { status: stts },
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          console.log("order update:", data);
        })
        .catch((err) => {
          const res = err.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });
    };

    if (statusVal === "pending delivery") {
      setStatusVal("complete");
      req("complete");
    } else {
      setStatusVal("pending delivery");
      req("pending delivery");
    }
  };

  // Paying
  const pay = (e, on) => {
    e.preventDefault();
    history.push("/order/" + on);
  };

  // Deleting orders

  const deleteOrder = (e) => {
    e.preventDefault();
    setDisplay(true);

    if (buyer) {
      axios({
        url: `${Functions.getAPI()}/orders/delete/${orderNumber}`,
        method: "POST",
        withCredentials: true,
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          dispatch(FLASH({ success: data.success, warn: "", err: "" }));
          dispatch(BuyerActions.BUYER_REMOVE_ORDER(orderNumber));
        })
        .catch((err) => {
          const res = err.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });
    } else {
      axios({
        url: `${Functions.getAPI()}/admin/delete-order/${
          admin_info._id
        }/${orderNumber}`,
        method: "POST",
        withCredentials: true,
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          dispatch(FLASH({ success: data.success, warn: "", err: "" }));
          dispatch(AdminActions.REMOVE_ORDER(orderNumber));
        })
        .catch((err) => {
          const res = err.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });
    }
  };

  // Render orders
  const renderOrders = () => {
    const renderImage = (item) => {
      if (item.productImage !== "") {
        return <img src={item.productImage} alt="Product" />;
      } else {
        return <p style={{ color: "#bbb" }}>No image</p>;
      }
    };
    // Rendering options
    const renderOptions = (item) => {
      // All options
      const k = Object.keys(item.options);
      const v = Object.values(item.options);

      return k.map((i, ind) => {
        return (
          <p key={ind}>
            <strong>{i}:</strong> {v[ind]}
          </p>
        );
      });
    };

    return (
      <div className={buyer ? "card buyer-card" : "card"}>
        <h2>
          Order Number: <span> {orderNumber} </span>
        </h2>

        <div className="heading">
          <h2>Order Details:</h2>
        </div>

        <div className="order-details">
          <p>
            <span>Name:</span> {customerName}
          </p>
          <p>
            <span>E-mail:</span> {customerEmail}
          </p>
          <p>
            <span>Phone:</span> {customerPhone}
          </p>
          <p>
            <span>Address:</span> {customerAddress}
          </p>
          {pickUpTime && (
            <p>
              <span>Pick-up time:</span> {pickUpTime}
            </p>
          )}

          <p>
            <span>Date of Order:</span> <TimeAgo date={Number(dateOrdered)} />
          </p>
          <p>
            <span>Elegible for delivery:</span> {elegibleForDelivery}
          </p>
          {agentCode && (
            <p>
              <span>Agent code:</span> {agentCode}
            </p>
          )}
        </div>

        <div className="heading">
          <h2>Items in this order:</h2>
        </div>

        <div className="items">
          {itemsInOrder.map((item) => (
            <div>
              <Link
                key={item._id}
                to={`/prod/${item.productName}/${item.productId}/${item.shopId}`}
                className="items-card"
              >
                <div className="card-img">{renderImage(item)}</div>
                <div className="card-text">
                  <h1>{item.productName}</h1>
                  <p>{item.shopName}</p>
                  <p className="price">{`${item.productCurrency}${item.productPrice}`}</p>
                </div>
                <div className="qty">
                  <p>[{item.qty}]</p>
                </div>
              </Link>
              <div
                style={{ padding: "10px 0px", borderBottom: "1px solid #ddd" }}
              >
                {renderOptions(item)}
              </div>
            </div>
          ))}
        </div>

        <div className="heading">
          <h2>Status:</h2>
        </div>

        <h3
          className={`total ${statusVal.match("pending") ? "warn" : "success"}`}
        >
          {statusVal.toUpperCase()}{" "}
          {!path.match("/admin") && statusVal !== "pending payment" && (
            <span
              onClick={updateStatus}
              className={statusVal === "pending delivery" ? "" : "undo"}
            >
              <i
                className={`${
                  statusVal === "pending delivery"
                    ? "fas fa-check"
                    : "fas fa-ellipsis-h"
                }`}
              ></i>
            </span>
          )}
        </h3>

        <div className="heading">
          <h2>Total:</h2>
        </div>

        <h3 className="total">R{totalPrice}</h3>

        <form method="POST">
          {!path.match("/admin") && status === "pending payment" && (
            <button
              onClick={(e) => {
                pay(e, orderNumber);
              }}
              className="pay"
              type="submit"
            >
              Pay now
            </button>
          )}
          {!path.match("/admin") && (
            <button
              onClick={(e) => {
                deleteOrder(e);
              }}
              className="delete"
              type="submit"
            >
              Delete
            </button>
          )}
        </form>
      </div>
    );
  };
  return (
    //   If orders are found
    <>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      {renderOrders()}
    </>
  );
};

export default OrdersCard;
