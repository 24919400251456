import { Route } from "react-router";
import HomePage from "../home/Home";

const Home = [
  {
    routeType: Route,
    path: "/",
    exact: true,
    component: HomePage,
  },
  {
    routeType: Route,
    path: "/home",
    exact: true,
    component: HomePage,
  },
];

export default Home;
