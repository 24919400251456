import { createRef } from "react";
import images from "../../abstracts/Images";
import ToggleDisplay from "../../abstracts/ToggleDisplay.js";
import Functions from "../../abstracts/Functions";
import VersionCard from "../../assets/VersionCard";
import { Link } from "react-router-dom";

const About = () => {
  const ulStyles = {
    marginLeft: "20px",
  };

  Functions.pageInfo({
    title: "Ordapple | About Ordapple",
    desc: "More information about ordapple.com",
  });

  const ref1 = createRef();
  const ref2 = createRef();
  const ref3 = createRef();
  const ref4 = createRef();
  const ref5 = createRef();
  const ref6 = createRef();

  const toggleVisibleContent = (e) => {
    ToggleDisplay(e, [ref1, ref2, ref3, ref4, ref5, ref6]);
  };

  return (
    <div className="about">
      <div className="about-container">
        <div className="about-app">
          <img src={images.logos.logo512} alt="logo" />
          {/* <!-- App description --> */}
          <h1>Ordapple</h1>
          <p>You are able.</p>
          <br />
        </div>
        <div className="more-info">
          {/* <!--Changelog --> */}
          <h2 className="view 1" onClick={toggleVisibleContent}>
            <p>Changelog:</p>
            <i className="fas fa-chevron-down"></i>
          </h2>
          <div ref={ref1} className="hide" style={{ display: "none" }}>
            <VersionCard />
          </div>
          <br />

          {/* <!-- About Founders --> */}
          <h2 id="founders" className="view 2" onClick={toggleVisibleContent}>
            <p>About founders:</p>
            <i className="fas fa-chevron-down"></i>
          </h2>
          <div ref={ref2} className="hide" style={{ display: "none" }}>
            <div className="founder">
              <div className="img">
                <img src={images.founders.me} alt="founder" />
              </div>
              <p className="title">Name:</p>
              <p>Katlego Mangoale</p>
              <p className="title">E-mail:</p>
              <p>Katlegodevelops@gmail.com</p>
              <p className="title">Title:</p>
              <p>Founder/CEO</p>
              <br />

              <div className="img">
                <img src={images.founders.him} alt="founder" />
              </div>
              <p className="title">Name:</p>
              <p>Mpho Baloyi</p>
              <p className="title">E-mail:</p>
              <p>balmpho@gmail.com</p>
              <p className="title">Title:</p>
              <p>Co-founder/Sales and marketing</p>
            </div>
          </div>
          <br />

          {/* <!-- Mission and Vission --> */}
          <h2 className="view 3" onClick={toggleVisibleContent}>
            <p>Mission and Vision:</p>
            <i className="fas fa-chevron-down"></i>
          </h2>
          <div ref={ref3} className="hide" style={{ display: "none" }}>
            <p className="title"></p>
            <p>
              We strive to create an online retailing platform for
              <strong>small</strong> and
              <strong>all business sizes</strong> across the globe, to be more
              economically viable and competent by bringing them closer to their
              customers and for shoppers to have a variety of selection to their
              needs at an affordable price.
            </p>
          </div>
          <br />

          <h2 id="contact" className="view 4" onClick={toggleVisibleContent}>
            <p>Contact us:</p>
            <i className="fas fa-chevron-down"></i>
          </h2>
          <div ref={ref4} className="hide" style={{ display: "none" }}>
            <p className="title">Need Help?:</p>
            <p>
              Visit our Help page by clicking the link below for answers to
              frequently asked questions.
            </p>
            <Link to="/help">FAQ</Link>

            <p className="title">Customer Service Working Hours:</p>
            <ul style={ulStyles}>
              <li>
                Weekdays
                <ul style={ulStyles}>
                  <li>09h00 - 22h00</li>
                </ul>
              </li>
              <li>
                Weekends
                <ul style={ulStyles}>
                  <li>09h00 - 17h00</li>
                </ul>
              </li>
            </ul>

            <p className="title">Start selling:</p>
            <p>
              Looking to sell items/goods on ordapple.com? Click the link below
              to apply.
            </p>
            <Link to="/admin/apply/free">Apply to sell</Link>

            <p className="title">Our office:</p>
            <p>
              Stand 245 <br />
              Lebowakgomo Zone F <br />
              Polokwane <br />
              Limpopo
            </p>
          </div>
          <br />

          {/* <!-- Terms and conditions --> */}
          <h2 id="terms" className="view 5" onClick={toggleVisibleContent}>
            <p>Terms and conditions:</p>
            <i className="fas fa-chevron-down"></i>
          </h2>
          <div ref={ref5} className="hide" style={{ display: "none" }}>
            <p className="title">Introduction:</p>
            <ol style={ulStyles}>
              <li>
                This website can be accessed at www.ordapple.com, and is owned
                and operated by Ordapple (Proprietary) Limited.
              </li>
              <li>
                These Website Terms and Conditions (“Terms and Conditions”)
                govern the ordering and the use of the website.
              </li>
              <li>
                These Terms and Conditions are binding and enforceable against
                every person that accesses or uses this Website. By using the
                Website and by registering on the Website, as may be applicable,
                you acknowledge that you have read and agree to be bound by
                these Terms and Conditions.
              </li>
              <li>
                Ordapple allows sellers to sell their Goods on the Website.
              </li>
            </ol>

            <p className="title">Important notice:</p>
            <ol style={ulStyles}>
              <li>
                These Terms and Conditions apply to users who are consumers for
                purposes of the Consumer Protection Act, 68 of 2008 (the “CPA”).
              </li>
              <li>
                Your attention is drawn to these Terms and Conditions because
                they are important and should be carefully noted.
              </li>
              <li>
                Nothing in these Terms and Conditions is intended or must be
                understood to unlawfully restrict, limit or avoid any right or
                obligation, as the case may be, created for either you or
                Ordapple in terms of the CPA.
              </li>
              <li>
                By using our website, you agree that you are 13 years or older.
              </li>
              <li>
                Sellers are not allowed to sell Tobacco, drugs or any harmful
                product on our website.
              </li>
            </ol>

            <p className="title">Registration and use of the website:</p>
            <ol style={ulStyles}>
              <li>Only registered users may order Goods on the Website.</li>
              <li>
                To register as a user, you must provide a unique email address
                and password and provide certain personal details to Ordapple.
                You will need to use your unique email and password to access
                the Website in order to order Goods.
              </li>
              <li>
                You agree and warrant that your email and password shall:
                <ul style={ulStyles}>
                  <li>be used for personal use only; and</li>
                  <li>not be disclosed by you to any third party.</li>
                </ul>
              </li>
              <li>
                For security purposes you agree to enter the correct email and
                password whenever logging in to our website, failing which you
                will be denied access.
              </li>
              <li>
                You agree that, once the correct username and password relating
                to your account have been entered, irrespective of whether the
                use of the username and password is unauthorised or fraudulent,
                you will be liable for any action made.
              </li>
            </ol>

            <p className="title">Ordering:</p>
            <ol style={ulStyles}>
              <li>
                When ordering, you should provide the correct information in
                order to avoid inconvenience.
              </li>
            </ol>

            <p className="title">Deliveries:</p>
            <ol style={ulStyles}>
              <li>
                We do not carry out any deliveries, we only only help connect
                fast food shops with drivers in their area.
              </li>
              <li>
                Sellers must establish (or use third-party delivery service) and
                facilitate their deliveries.
              </li>
              <li>
                We are not going to be liable for deliveries not facilitated not
                reaching relevant customers but the shops on our platform.
              </li>
            </ol>

            <p className="title">Sellers:</p>
            <ol style={ulStyles}>
              <li>
                As part of Ordapple client in compliance with Consumer
                Protection Act 28 of 2008, you are fully responsible for the
                settlement of refunds requested by the customer purchased from
                your store. However, the customer will be refunded on delivery.
              </li>
            </ol>
          </div>
          <br />

          {/* <!-- Privacy policy --> */}
          <h2
            style={{ marginBottom: "50px" }}
            className="view 6"
            onClick={toggleVisibleContent}
          >
            <p>Privacy Policy</p>
            <i className="fas fa-chevron-down"></i>
          </h2>
          <div ref={ref6} className="hide" style={{ display: "none" }}>
            <p className="title"></p>
            <p>
              <span> Purpose of this policy </span>
            </p>
            <p>
              We respect your privacy and take the protection of personal
              information very seriously. The purpose of this policy is to
              describe the way we collect, store, use and protect information
              that can be associated with a specific natural or juristic person
              and can be used to identify that person (“personal information”).
            </p>
            <br />
            <p>
              <span> Your personal information </span>
            </p>
            <p>
              Your personal information is stored in our database to allow you
              to login and use our web app. The user's personal information
              including certain information collected during registration,
              optional information that you voluntarily provide to us or any
              other information will not be shared for no reason at any time. We
              will only share your E-mail, Phone number, your Firstname and
              Lastname when you order so that you can be contacted by sellers to
              come and collect ordered item(s) or for confirmation.
            </p>
            <br />
            <p>
              <span> Changes </span>
            </p>
            <p>
              We may change the terms of this policy at any time. We will notify
              you of any changes by placing a notice in a prominent place on the
              website or by email. If you do not agree with the change you must
              stop using the services. If you continue to use the services
              following notification of a change to the terms, the changed terms
              will apply to you and you will be deemed to have accepted such
              terms.
            </p>
            <br />
            <p>
              <span> Collection </span>
            </p>
            <p>
              On registration. Once you register on our website, you will no
              longer be anonymous to us as you will provide us with personal
              information. This personal information will include your
              Firstname, Lastname, Phone number, E-mail and Password.
            </p>
            <br />
            <p>
              <span> Local storage </span>
            </p>
            <p>
              We strore your e-mail in local storage in order to make it easy
              for you to login to our website.
            </p>
            <br />
            <p>
              <span> Update password </span>
            </p>
            <p>
              If you forgot your password in our website you can simply click
              the "Forgot password" link below the login button on the login
              page.
            </p>
            <br />
            <p>
              <span> Limitation </span>
            </p>
            <p>
              We are not responsible for, give no warranties, nor make any
              representations in respect of the privacy policies or practices of
              any third party websites.
            </p>
            <br />
            <p>
              <span> Enquiries </span>
            </p>
            <p>
              If you have any questions or concerns arising from this privacy
              policy or the way in which we handle personal information, please
              contact us at: <br />
              <Link to="mailto:support@ordapple.com">support@ordapple.com</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
