const ImageEnlarge = ({ src, toggleVisiblity, visible }) => {
  return (
    <div
      className="large-img"
      style={visible ? { display: "flex" } : { display: "none" }}
    >
      <div className="close-cont">
        <span className="close-container" onClick={toggleVisiblity}>
          X
        </span>
      </div>
      <div className="img">
        <img id="image-cont" src={src} alt="product" />
      </div>
    </div>
  );
};

export default ImageEnlarge;
