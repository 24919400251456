// INFORMATION
const ADMIN_INFO = (info) => {
  return {
    type: "ADMIN_INFO",
    payload: info,
  };
};
const EDIT_ACCOUNT = (info) => {
  return {
    type: "ADMIN_EDIT_ACCOUNT",
    payload: info,
  };
};
const UPDATE_PROFILE_PIC = (data) => {
  return {
    type: "UPDATE_PROFILE_PIC",
    payload: data,
  };
};

// PRODUCTS
const PRODUCTS = (data) => {
  return {
    type: "PRODUCTS",
    payload: data,
  };
};
const ADD_PRODUCT = (data) => {
  return {
    type: "ADD_PRODUCT",
    payload: data,
  };
};
const EDIT_PRODUCT = (data) => {
  return {
    type: "EDIT_PRODUCT",
    payload: data,
  };
};
const REMOVE_PRODUCT = (data) => {
  return {
    type: "REMOVE_PRODUCT",
    payload: data,
  };
};

// ORDERS
const ORDERS = (data) => {
  return {
    type: "ORDERS",
    payload: data,
  };
};
const ADMIN_ADD_ORDER = (data) => {
  return {
    type: "ADD_ORDER",
    payload: data,
  };
};
const REMOVE_ORDER = (data) => {
  return {
    type: "REMOVE_ORDER",
    payload: data,
  };
};
const REMOVE_ALL_ORDERS = (data) => {
  return {
    type: "REMOVE_ALL_ORDERS",
    payload: data,
  };
};

const AdminActions = {
  ADMIN_INFO,
  EDIT_ACCOUNT,
  UPDATE_PROFILE_PIC,
  PRODUCTS,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  REMOVE_PRODUCT,
  ORDERS,
  ADMIN_ADD_ORDER,
  REMOVE_ORDER,
  REMOVE_ALL_ORDERS,
};

export default AdminActions;
