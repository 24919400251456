import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Functions from "../../abstracts/Functions";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import axios from "axios";
import JobNav from "./assets/JobNav";
import TimeAgo from "react-timeago";
import Popup from "../../assets/Popup";

const Cashouts = () => {
  Functions.pageInfo({
    title: "Ordapple | Cashout Management System",
    desc: "Manage Cashout requests. For Authorized staff only",
  });

  //   Use selector
  const flash = useSelector((s) => s.flash);

  // Use state
  const [cashouts, setCashouts] = useState([]);
  const [display, setDisplay] = useState(false);

  // Dispatch
  const dispatch = useDispatch();

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());

    // Get cashouts
    axios({
      method: "GET",
      url: `${Functions.getAPI()}/employee/cashout`,
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        setCashouts(data.cashouts);
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  }, [display]);

  //   Delete a cashout
  const deleteCashout = (e, _id) => {
    e.preventDefault();

    // Display popup
    setDisplay(true);

    axios({
      method: "POST",
      url: `${Functions.getAPI()}/employee/cashout/delete`,
      withCredentials: true,
      data: { _id },
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Flash message
        dispatch(FLASH({ err: "", success: data.success, warn: "" }));
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  };

  return (
    <>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}

      <JobNav page="" />
      <div className="account" style={{ marginTop: "-20px" }}>
        <div className="cashout-cont">
          {cashouts.length > 0 ? (
            cashouts.map((i, ind) => (
              <div key={ind} className="cashout-card">
                <div className="top-row">
                  <p>
                    <TimeAgo date={i.requestedOn} />
                  </p>
                  <div
                    className="delete"
                    onClick={(e) => deleteCashout(e, i._id)}
                  >
                    X
                  </div>
                </div>
                <div className="bottom-row">
                  <h1>{i.shopName}</h1>
                  <h2>
                    Amount: <span>R{i.amount}</span>
                  </h2>
                </div>
              </div>
            ))
          ) : (
            <p className="heading" style={{ marginTop: "10px", color: "#bbb" }}>
              No cashouts found!
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Cashouts;
