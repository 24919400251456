import { createRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FLASH from "../../redux/actions/Flash";
import { RESTORE_FLASH } from "../../redux/actions/Flash";
import axios from "axios";
import Popup from "../../assets/Popup";
import Functions from "../../abstracts/Functions";
import Form from "../../assets/Form";

const AdminApply = ({ history }) => {
  // Params
  const { plan } = useParams();

  // Redux state
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Apply To Sell On Ordapple",
    desc: "Application To Sell On Ordapple",
  });

  // Redux disparch
  const dispatch = useDispatch();

  // Use state
  const [display, setDisplay] = useState(true);
  const [firstNameVal, setFirstNameVal] = useState("");
  const [lastNameVal, setLastNameVal] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [categoryVal, setCategoryVal] = useState("");
  const [citizenVal, setCitizenVal] = useState("");
  const [phoneVal, setPhoneVal] = useState("");
  const [addressVal, setAddress] = useState("");
  const [messageVal, setMessageVal] = useState("");

  // Messages
  const [msg, setMsg] = useState({});

  // Refs
  const firstname = createRef();
  const lastname = createRef();
  const email = createRef();
  const category = createRef();
  const citizen = createRef();
  const phone = createRef();
  const address = createRef();
  const message = createRef();

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  // Regex
  const phoneRegx = /^[0-1][6-8]\d{8}$/g;
  const emailRegx = /@/i;

  // EVENTS

  // On change event
  const onChange = (e) => {
    const { value, name, style } = e.target;

    if (name === "firstname") {
      setFirstNameVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "lastname") {
      setLastNameVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "email") {
      const emailVal = value.toLowerCase();
      setEmailVal(emailVal);
      if (!emailRegx.test(value)) {
        setMsg({ email: "Must include '@'" });
        style.border = "3px solid red";
      } else {
        setMsg({ email: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "phone") {
      setPhoneVal(value);
      if (value.length !== 10) {
        style.border = "3px solid red";
        setMsg({ phone: "Must be 10 digits" });
      } else {
        setMsg({ phone: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "category") {
      setCategoryVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "citizen") {
      setCitizenVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "address") {
      setAddress(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "message") {
      setMessageVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    }
  };

  // On submit event
  const apply = (e) => {
    if (firstname.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ firstname: "Required field!" });
    } else if (lastname.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ lastname: "Required field!" });
    } else if (email.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ email: "Required field!" });
    } else if (phone.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ phone: "Required field!" });
    } else if (category.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ category: "Required field!" });
    } else if (citizen.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ citizen: "Required field!" });
    } else if (address.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ address: "Required field!" });
    } else if (message.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ message: "Required field!" });
    } else if (!emailRegx.test(email.current.value)) {
      e.preventDefault();
      setMsg({ email: "Must include '@'" });
    } else if (!phoneRegx.test(phone.current.value)) {
      e.preventDefault();
      setMsg({ phone: "Invalid phone number" });
    } else {
      // Loading animation on submit button
      e.target.classList.add("btn-animate");
      e.target.innerText = "Processing...";

      setDisplay(true);
      e.preventDefault();
      const firstname = firstNameVal;
      const lastname = lastNameVal;
      const email = emailVal;
      const category = categoryVal;
      const citizen = citizenVal;
      const phone = phoneVal;
      const address = addressVal;
      const message = messageVal;

      // Admin plan application
      if (plan !== undefined) {
        axios({
          url: `${Functions.getAPI()}/admin/apply/${plan}`,
          method: "POST",
          data: {
            firstname,
            lastname,
            email,
            citizen,
            category,
            phone,
            address,
            message,
          },
        })
          .then(async (res) => {
            // Waiting for the data before proceeding
            const data = await res.data;

            // Removing the loading animation from the button
            e.target.classList.remove("btn-animate");
            e.target.innerText = "Submit";

            // Dispatching
            dispatch(FLASH({ err: "", success: data.success, warn: "" }));
          })
          .catch((err) => {
            // Removing the loading animation from the button
            e.target.classList.remove("btn-animate");
            e.target.innerText = "Submit";

            const res = err.response;
            if (res) {
              if (res.data.err) {
                dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
              } else {
                dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
              }
            } else {
              console.log(err);
            }
          });
      } else {
        axios({
          url: `${Functions.getAPI()}/admin/apply/free`,
          method: "POST",
          data: {
            firstname,
            lastname,
            email,
            citizen,
            category,
            phone,
            address,
            message,
          },
        })
          .then(async (res) => {
            // Waiting for the data before proceeding
            const data = await res.data;

            // Removing the loading animation from the button
            e.target.classList.remove("btn-animate");
            e.target.innerText = "Submit";

            // Dispatching
            dispatch(FLASH({ err: "", success: data.success, warn: "" }));
          })
          .catch((err) => {
            // Removing the loading animation from the button
            e.target.classList.remove("btn-animate");
            e.target.innerText = "Submit";

            const res = err.response;
            if (res) {
              if (res.data.err) {
                dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
              } else {
                dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
              }
            } else {
              console.log(err);
            }
          });
      }

      setMsg({});
    }
  };

  // Rendering the component
  return (
    <Form>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
          history={history}
        />
      )}

      <div className="heading">
        <h1>Apply to sell</h1>
      </div>

      <input
        type="text"
        className="formInput"
        name="firstname"
        id="firstname"
        placeholder="First name"
        required
        onChange={onChange}
        ref={firstname}
        value={firstNameVal}
      />
      <p style={{ color: "red" }} id={`${msg.firstname && "err"}`}>
        {msg.firstname}
      </p>

      <input
        type="text"
        className="formInput"
        name="lastname"
        id="lastname"
        placeholder="Last name"
        required
        onChange={onChange}
        ref={lastname}
        value={lastNameVal}
      />
      <p style={{ color: "red" }} id={`${msg.lastname && "err"}`}>
        {msg.lastname}
      </p>

      <input
        type="email"
        className="formInput"
        name="email"
        id="email"
        placeholder="Email"
        required
        onChange={onChange}
        ref={email}
        value={emailVal}
      />
      <p style={{ color: "red" }} id={`${msg.email && "err"}`}>
        {msg.email}
      </p>

      <input
        type="tel"
        className="formInput"
        name="phone"
        id="phone"
        placeholder="Phone"
        required
        onChange={onChange}
        ref={phone}
        value={phoneVal}
      />
      <p style={{ color: "red" }} id={`${msg.phone && "err"}`}>
        {msg.phone}
      </p>

      <input
        type="text"
        className="formInput"
        name="category"
        id="category"
        placeholder="What are you selling?"
        required
        onChange={onChange}
        ref={category}
        value={categoryVal}
      />
      <p style={{ color: "red" }} id={`${msg.category && "err"}`}>
        {msg.category}
      </p>

      <select
        name="citizen"
        id="citizen"
        onChange={onChange}
        ref={citizen}
        value={citizenVal}
      >
        <option value="YES">SA citizen?: (YES)</option>
        <option value="NO">SA citizen?: (NO)</option>
      </select>
      <p style={{ color: "red" }} id={`${msg.citizen && "err"}`}>
        {msg.citizen}
      </p>

      <input
        type="text"
        className="formInput"
        name="address"
        id="address"
        placeholder="location of your business"
        required
        onChange={onChange}
        ref={address}
        value={addressVal}
      />
      <p style={{ color: "red" }} id={`${msg.address && "err"}`}>
        {msg.address}
      </p>

      <textarea
        type="text"
        className="formInput"
        name="message"
        id="message"
        placeholder="Tell us more about you..."
        required
        onChange={onChange}
        ref={message}
        value={messageVal}
      ></textarea>
      <p style={{ color: "red" }} id={`${msg.message && "err"}`}>
        {msg.message}
      </p>

      {plan !== undefined && <p style={{ marginTop: "10px" }}>Plan: {plan}</p>}

      <button type="submit" className="submit-button" onClick={apply}>
        Submit
      </button>
    </Form>
  );
};

export default AdminApply;
