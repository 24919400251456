import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import Employee from "../../redux/actions/Employee";
import JobNav from "./assets/JobNav";
import Loader from "../../abstracts/Loader";
import axios from "axios";
import UserTable from "./assets/UserTable";
import Popup from "../../assets/Popup";
import Functions from "../../abstracts/Functions";
import UserUpdater from "./assets/UserUpdater";

const Users = () => {
  // Redux state
  const user = useSelector((state) => state.user);
  const flash = useSelector((state) => state.flash);
  const employee_users = useSelector((state) => state.employee_users);
  const [pagination, setPagination] = useState({});
  const [userFilter, setUserFilter] = useState("all");

  Functions.pageInfo({
    title: "Ordapple | Users Info",
    desc: "View users Info. For Authorized staff only",
  });

  // Redux disparch
  const dispatch = useDispatch();

  // Use state
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayUpdater, setDisplayUpdater] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [nameProp, setNameProp] = useState(null);
  const [searchQ, setSearchQ] = useState("");
  const [updateVal, setUpdateVal] = useState("");
  const [msg, setMsg] = useState("");
  const [userId, setUserId] = useState("");

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());

    axios({
      method: "GET",
      url: `${Functions.getAPI()}/employee/users?filter=${userFilter}`,
      withCredentials: true,
    })
      .then(async (res) => {
        // Display popup
        setDisplay(true);

        // Waiting for the data before proceeding
        const data = await res.data;

        // Dispatching
        dispatch(Employee.GET_USERS(data.usersData.data));

        // Setting state values
        setPagination({
          next: data.usersData.next,
          previous: data.usersData.previous,
          numOfPages: data.usersData.numOfPages,
          error: data.usersData.error,
        });
      })
      .catch((e) => {
        // Display popup
        setDisplay(true);

        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  }, [dispatch, user._id, userFilter]);

  // Filtering the users you want to see (all, admins or buyers)
  const filter = (e) => {
    const { value } = e.target;
    setUserFilter(value);
  };

  // Searching for users by name
  const typeSearch = (e) => {
    const { value } = e.target;
    setSearchQ(value);
  };

  // Going to the next page or previous page
  const paginate = (e, limit, page) => {
    e.preventDefault();

    // Reset the states
    setPagination({});
    dispatch(Employee.GET_USERS([]));

    axios({
      url: `${Functions.getAPI()}/employee/users?filter=${userFilter}&pagelimit=${limit}&page=${page}`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // If an error occurs while doing pagination
        // When reaching the end of the pages available for access
        // Take the user back to the 1st page
        if (pagination.error && "page" in data.usersData.previous) {
          paginate(e, data.usersData.previous.limit, 1);
        }

        // Dispatching
        dispatch(Employee.GET_USERS(data.usersData.data));

        // Setting state values
        setPagination({
          next: data.usersData.next,
          previous: data.usersData.previous,
          numOfPages: data.usersData.numOfPages,
          error: data.usersData.error,
        });
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  };

  // Toggling the display of the user updater component
  // and setting the name property from the clicked item
  const toggleDisplayUpdater = (e, id) => {
    setDisplayUpdater(!displayUpdater);
    setUpdateVal("");

    // Messages
    setMsg("");

    // Setting the user's id to use when updating their account
    setUserId(id);

    if (!displayUpdater) {
      // Getting the data attribute's value
      const name = e.target.dataset.name;

      // Setting the name prop to the data attr's value
      setNameProp(name);
    }
  };

  // Toggle the display of the search feature
  const toggleSearch = (e) => {
    e.preventDefault();
    setDisplaySearch(!displaySearch);

    if (!displaySearch) {
      setSearchQ("");
    }
  };

  // On change
  const changeUpdateVal = (e) => {
    const { value } = e.target;
    if (
      nameProp === "admin" ||
      nameProp === "accountActive" ||
      nameProp === "paid" ||
      nameProp === "verified"
    ) {
      if (value !== "yes" && value !== "no") {
        setUpdateVal(value);
        setMsg("Invalid value (Enter yes or no)");
      } else {
        setUpdateVal(value);
        setMsg("");
      }
    } else {
      if (
        value !== "free" &&
        value !== "basic" &&
        value !== "standard" &&
        value !== "mega"
      ) {
        setUpdateVal(value);
        setMsg("Invalid value (Enter free or basic or standard or mega)");
      } else {
        setUpdateVal(value);
        setMsg("");
      }
    }
  };

  //  Update user's account
  const updateAccount = (id) => {
    // DATA
    const data = {};
    if (
      nameProp === "admin" ||
      nameProp === "accountActive" ||
      nameProp === "paid" ||
      nameProp === "verified"
    ) {
      if (updateVal === "yes") {
        data[nameProp] = Boolean(true);
      } else {
        data[nameProp] = Boolean(false);
      }
    } else {
      data[nameProp] = updateVal;
    }

    // Server request tp update user
    const request = () => {
      // Display popup
      setDisplay(true);

      // remove accUpdate window
      setDisplayUpdater(false);

      setMsg("");

      axios({
        url: `${Functions.getAPI()}/employee/update-user/${id}`,
        method: "POST",
        withCredentials: true,
        data,
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const DATA = await res.data;

          // Dispatching
          dispatch(FLASH({ err: "", success: DATA.success, warn: "" }));
          dispatch(Employee.EDIT_USER({ data, _id: id }));
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
            console.log(res.data);
          } else {
            console.log(e);
          }
        });
    };

    if (nameProp === "admin" || nameProp === "accountActive") {
      if (updateVal !== "yes" && updateVal !== "no") {
        setMsg("Invalid value (Enter yes or no)");
      } else {
        setMsg("");
        request();
      }
    } else if (nameProp === "subscription") {
      if (
        updateVal !== "free" &&
        updateVal !== "basic" &&
        updateVal !== "standard" &&
        updateVal !== "mega"
      ) {
        setMsg("Invalid value (Enter free or basic or standard or mega)");
      } else {
        setMsg("");
        request();
      }
    } else {
      request();
    }
  };

  // Search for users
  const search = (e) => {
    e.preventDefault();
    setDisplaySearch(!displaySearch);
    // Display popup
    setDisplay(false);
    // Showing the loading message
    setLoading(true);

    axios({
      method: "GET",
      url: `${Functions.getAPI()}/employee/users?q=${searchQ}`,
      withCredentials: true,
    })
      .then(async (res) => {
        // Display popup
        setDisplay(true);
        // Showing the loading message
        setLoading(false);

        // Waiting for the data before proceeding
        const data = await res.data;

        // Dispatching
        dispatch(Employee.GET_USERS(data.usersData.data));

        // Setting state values
        setPagination({
          next: data.usersData.next,
          previous: data.usersData.previous,
          numOfPages: data.usersData.numOfPages,
          error: data.usersData.error,
        });
      })
      .catch((e) => {
        // Display popup
        setDisplay(true);
        // Showing the loading message
        setLoading(false);

        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  };

  // render the pagination buttons
  const renderPgBtns = () => {
    if (Object.keys(pagination).length > 0) {
      // Pagination users data
      if ("page" in pagination.previous || "page" in pagination.next) {
        return (
          <>
            {/*  Previous page */}
            <div className="pageForms">
              {"page" in pagination.previous && (
                <form className="pageForm">
                  <button
                    type="submit"
                    className="back"
                    onClick={(e) => {
                      paginate(
                        e,
                        pagination.previous.limit,
                        pagination.previous.page
                      );
                    }}
                  >
                    <i className="fas fa-chevron-left back"></i> Back
                  </button>
                </form>
              )}

              {/* Next page  */}
              {"page" in pagination.next && (
                <form className="pageForm">
                  <button
                    type="submit"
                    className="next"
                    onClick={(e) => {
                      paginate(e, pagination.next.limit, pagination.next.page);
                    }}
                  >
                    Next <i className="fas fa-chevron-right next"></i>
                  </button>
                </form>
              )}
            </div>
          </>
        );
      }
    }
  };

  // Render function
  const renderUsers = () => {
    if (employee_users.length >= 1) {
      return (
        <>
          {/* Users data */}
          {employee_users.map((user, ind) => (
            <div className="account-container" key={ind}>
              <UserTable
                user={user}
                toggleDisplayUpdater={toggleDisplayUpdater}
                setUserId={setUserId}
              />
            </div>
          ))}
          {/* Pagination */}
          {renderPgBtns()}
        </>
      );
    } else {
      const loader = () => {
        if (searchQ) {
          if (loading) {
            return <Loader />;
          } else {
            return (
              <h2
                className="non"
                style={{ fontSize: "20px", marginTop: "10px", color: "#bbb" }}
              >
                No results found!
              </h2>
            );
          }
        } else {
          <Loader />;
        }
      };
      return <div className="account-container">{loader()}</div>;
    }
  };

  return (
    <div>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}

      <JobNav page="users" />

      <UserUpdater
        displayUpdater={displayUpdater}
        toggleDisplayUpdater={toggleDisplayUpdater}
        nameProp={nameProp}
        changeUpdateVal={changeUpdateVal}
        msg={msg}
        updateVal={updateVal}
        updateAccount={updateAccount}
        id={userId}
      />

      <div className="account">
        {/* Filter users */}
        <div className="btn-holder">
          {/* Search button */}
          <button type="submit" onClick={toggleSearch}>
            <i className="fas fa-search"></i>
          </button>

          {/* Search feature */}
          <div
            className="search-cont"
            style={{ display: displaySearch ? "flex" : "none" }}
          >
            <button onClick={toggleSearch}>X</button>
            <input
              type="text"
              className="search-box"
              placeholder="Search name or ID"
              onChange={typeSearch}
              value={searchQ}
            />
            <button onClick={search}>
              <i className="fas fa-arrow-right"></i>
            </button>
          </div>
          {/* Decoy */}
          <div
            className="decoy-cont"
            style={{ display: displaySearch ? "flex" : "none" }}
          ></div>

          {/* Filter users */}
          <select
            name="filter"
            id="filter"
            value={userFilter}
            onChange={filter}
          >
            <option value="all">All</option>
            <option value="admins">Admins</option>
            <option value="buyers">Buyers</option>
          </select>
        </div>
        {/* Users data */}
        {renderUsers()}
      </div>
    </div>
  );
};

export default Users;
