import { useState } from "react";
import { useSelector } from "react-redux";
import Popup from "../../assets/Popup";
import ProdCard from "../../assets/ProdCard";
import Functions from "../../abstracts/Functions";

const ItemResults = () => {
  const viewed_shop = useSelector((state) => state.viewed_shop);
  const searched_products = useSelector((state) => state.searched_products);
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Searched items!",
    desc: "Viewing searched items",
  });

  // Use state
  const [display, setDisplay] = useState(false);

  // Render functions

  const renderProdCard = () => {
    if (searched_products.length === 0) {
      return (
        <div className="heading" style={{ marginTop: "-20px", width: "100%" }}>
          <h1 style={{ color: "#bbb" }}>There's nothing here!</h1>
        </div>
      );
    } else {
      return searched_products.map((product, ind) => (
        <ProdCard
          key={ind}
          page="itemResults"
          setDisplay={setDisplay}
          shopName={viewed_shop.name}
          img={product.item.image.Location}
          productName={product.item.name}
          productCurrency={product.item.currency}
          productPrice={product.item.price}
          productId={product.item._id}
          shopId={viewed_shop._id}
          shopOwner={viewed_shop.shopOwner}
          shopVerified={viewed_shop.verified}
          likes={product.item.likes}
        />
      ));
    }
  };

  return (
    <div className="shopname">
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      <div className="shopname-container">
        <div className="shop">
          <div className="opacity">
            <h1>Search Results</h1>
            <p>
              <i className="fas fa-store"></i> {viewed_shop.name}
            </p>
          </div>
        </div>

        <div className="products-container">
          {/* Products */}
          <div className="prod-card-container pagination">
            {renderProdCard()}
          </div>
        </div>

        {/* About shop */}
        <div className="shop-description">
          <h3>About shop:</h3>
          <p>{viewed_shop.about}</p>
        </div>
      </div>
    </div>
  );
};

export default ItemResults;
