const UploadPP = ({
  updateProfilePic,
  imgRef,
  display,
  waiting,
  imgSrc,
  toggleDisplay,
}) => {
  return (
    <div className="img-upload" style={{ display: display ? "flex" : "none" }}>
      <div className="contents">
        <div className="img-container">
          <img
            style={{ display: !waiting ? "flex" : "none" }}
            src={imgSrc}
            alt="upload source"
            ref={imgRef}
            className="square-img"
          />
          <img
            style={{ display: !waiting ? "flex" : "none" }}
            src={imgSrc}
            alt="upload source"
            ref={imgRef}
          />
          <p style={{ display: waiting ? "flex" : "none" }}>
            Waiting for chosen image...
          </p>
        </div>
        <div className="buttons">
          <button
            onClick={updateProfilePic}
            style={{ display: !waiting ? "flex" : "none" }}
          >
            Save
          </button>
          <button className="cancel" onClick={toggleDisplay}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadPP;
