const LOADING = (state = true, { type }) => {
  switch (type) {
    case "LOADING":
      return true;
    case "FINISHED_LOADING":
      return false;
    default:
      return state;
  }
};
export default LOADING;
