const MapData = {
  latLong: { lng: 29.4486, lat: -23.8962 },
};
// // GOOGLE MAPS

// let map;
// const google = window.google;

function createMap(pos) {
  if (pos) {
    console.log(
      "lat: " + pos.coords.latitude + "",
      "Long: " + pos.coords.longitude + ""
    );
    MapData.latLong.lat = pos.coords.latitude;
    MapData.latLong.lng = pos.coords.longitude;
  }
}

//   map = new google.maps.Map(MapData.mapCont, options);
// }

const getUserLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(createMap);
  } else {
    console.log("Geolocation not enabled or is not supported!");
    return "Geolocation not enabled or is not supported!";
  }
};

getUserLocation();

export default MapData;
