import ShopCard from "../../assets/ShopCard";
import { useSelector } from "react-redux";
import { useState } from "react";
import Popup from "../../assets/Popup";
import Functions from "../../abstracts/Functions";

const ShopResults = () => {
  // Redux state
  const searched_shops = useSelector((state) => state.searched_shops);
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Searched Shops!",
    desc: "Viewing searched shops",
  });

  console.log(searched_shops);

  // Use state
  const [display, setDisplay] = useState(false);

  const renderShopCard = () => {
    if (searched_shops.shops.length >= 1) {
      return searched_shops.shops.map((shop, ind) => (
        <ShopCard
          key={ind}
          page="shopResults"
          shopId={shop._id}
          shopName={shop.name}
          aboutShop={shop.about}
          shopLocation={shop.location}
          workingHours={shop.workingHours}
          visits={shop.visits}
          followers={shop.followers.length}
          img={shop.image.Location}
          shopVerified={shop.verified}
          setDisplay={setDisplay}
        />
      ));
    } else {
      return (
        <div className="heading">
          <h1 style={{ color: "#bbb" }}>No Shops found!</h1>
        </div>
      );
    }
  };
  return (
    <div className="shops">
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      <div className="heading">
        <h1>Results for "{searched_shops.search}"</h1>
      </div>
      {renderShopCard()}
    </div>
  );
};

export default ShopResults;
