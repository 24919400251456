import axios from "axios";
import Functions from "../abstracts/Functions";

// Setting the seen and number (number of unseen inboxes) to followee
export const SetStatus = async (owner, cb) => {
  return await axios({
    url: `${Functions.getAPI()}/inbox/status`,
    method: "POST",
    withCredentials: true,
    data: { owner: owner },
  })
    .then(async (res) => {
      const data = res.data;
      // If status is 200, let the user login
      if (res.status === 200) {
        if (cb) {
          return cb(data);
        }
      }
    })
    .catch((e) => {
      const res = e.response;
      if (res) console.log(res.data);
      else console.log(e);
    });
};

export const NewInbox = async (
  options = {
    text: null,
    owner: null,
    sender: null,
    username: null,
    url: null,
  },
  cb
) => {
  // Sending an inbox notification to the followee
  return await axios({
    url: `${Functions.getAPI()}/inbox/new`,
    method: "POST",
    withCredentials: true,
    data: {
      text: options.text,
      owner: options.owner,
      sender: options.sender,
      username: options.username,
      url: options.url,
    },
  })
    .then(async (res) => {
      const data = res.data;
      if (res.status === 200) {
        if (cb) {
          return cb(data);
        }
      }
    })
    .catch((e) => {
      const res = e.response;
      if (res) console.log(res.data);
      else console.log(e);
    });
};
