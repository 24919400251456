import { Link } from "react-router-dom";

const ProdCardLoader = () => {
  // Render functions
  const renderProductCard = () => {
    return (
      <Link
        to={`/prod/`}
        onClick={(e) => {
          e.preventDefault();
        }}
        className="card"
      >
        <div className="prod-img load"></div>
        <div className="prod-info load">
          <p className="prod-name load"></p>
          <p className="shop-name load">
            <span>
              <strong>None</strong>
            </span>
          </p>
          <div className="price-likes load">
            <h3> None</h3>
            <p className="likes load"></p>
          </div>

          <form action={`/`} method="POST">
            <input type="hidden" name="shop" />
            <button className="unlike load" type="submit"></button>
          </form>
        </div>

        <div className="slide-anime"></div>
      </Link>
    );
  };

  return <>{renderProductCard()}</>;
};

export default ProdCardLoader;
