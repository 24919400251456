import { createRef } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import FLASH, { RESTORE_FLASH } from "../redux/actions/Flash";
import BuyerActions from "../redux/actions/BuyerActions";
import axios from "axios";
import Functions from "./Functions";

const DesktopSearch = (props) => {
  // Get the current path's name
  const path = useLocation().pathname;

  const viewed_shop = useSelector((state) => state.viewed_shop);

  // History
  const history = useHistory();

  // States
  const [val, setVal] = useState("");

  // Redux
  const dispatch = useDispatch();

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  // Refs
  const searchField = createRef();

  // On change event
  const onchange = (e) => {
    const { value } = e.target;
    setVal(value);
  };

  // Functions to run when submitting

  const searchShops = (e) => {
    if (searchField.current.value.trim() === "") {
      e.preventDefault();
    } else {
      e.preventDefault();
      const q = val;

      axios({
        url: `${Functions.getAPI()}/shops/search?q=${q}`,
        method: "GET",
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Dispatching
          dispatch(BuyerActions.SEARCHED_SHOPS(data));

          // Redirecting the user
          history.push(`/shops/search?q=${q}`);
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(e);
          }
        });
    }
  };

  const searchProducts = (e) => {
    if (searchField.current.value.trim() === "") {
      e.preventDefault();
    } else {
      e.preventDefault();
      const q = val;

      axios({
        url: `${Functions.getAPI()}/products/search?q=${q}`,
        method: "GET",
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Dispatching
          dispatch(BuyerActions.SEARCHED_PRODUCTS(data.products));

          // Redirecting the user
          if (
            path === "/shop" ||
            path === "/products/search" ||
            path === "/" ||
            path.match("/category") ||
            path.match("/department")
          ) {
            history.push(`/products/search?q=${q}`);
          } else {
            history.push(`/${viewed_shop._id}/products-results?q=${q}`);
          }
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(e);
          }
        });
    }
  };

  const checkPath = () => {
    if (
      path === `/shop/${viewed_shop._id}` ||
      path === "/shop" ||
      path === "/" ||
      path.match("/category") ||
      path.match("/department") ||
      path === "/products/search" ||
      path === `/${viewed_shop._id}/products-results`
    ) {
      // Search field
      return (
        <div
          className="search-bar"
          style={{ display: props.showSearch ? "flex" : "none" }}
        >
          <div className="search-container">
            {/* If the topShops' length is greater than 0 */}
            <form action="/shopID/products-results" method="GET">
              <input
                type="text"
                className="searchBox"
                name="q"
                placeholder="Search items, categories or departments"
                ref={searchField}
                onChange={onchange}
                value={val}
              />
              <button type="submit" onClick={searchProducts}>
                <i className="fas fa-search"></i>
              </button>
            </form>
          </div>
        </div>
      );
    } else if (path === "/shops" || path === "/shops/search") {
      // Search field
      return (
        <div
          className="search-bar"
          style={{ display: props.showSearch ? "flex" : "none" }}
        >
          <div className="search-container">
            {/* If the topShops' length is greater than 0 */}
            <form action="shops/search" method="GET">
              <input
                type="text"
                className="searchBox"
                name="q"
                placeholder="Search location"
                ref={searchField}
                onChange={onchange}
                value={val}
              />
              <button type="submit" onClick={searchShops}>
                <i className="fas fa-search"></i>
              </button>
            </form>
          </div>
        </div>
      );
    } else {
      return <div style={{ display: "none" }} className="search-bar"></div>;
    }
  };
  return checkPath();
};

export default DesktopSearch;
