const Functions = {
  msToMinSec: (m) => {
    var min = Math.floor(m / 60000);
    var sec = ((m % 60000) / 1000).toFixed(0);
    return min + ":" + (sec < 10 ? "0" : "") + sec;
  },
  percentage: (n) => {
    var perc = Math.floor(n);
    return perc;
  },
  // Format number from 1 to 1K to 1M to 1B
  numFormatter: (num) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000 && num < 999999) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num > 1000000000) {
      return (num / 1000000000).toFixed(1) + "B"; // convert to B for number from > 1 billion
    } else if (num < 999) {
      return num; // if value < 1000, nothing to do
    }
  },
  // Round number to 2 decimal places
  roundTo2: (n) => {
    const preciseNum = Number((Math.abs(n) * 100).toPrecision(15));
    return (Math.round(preciseNum) / 100) * Math.sign(n);
  },
  limitText: (textToLimit, limit) => {
    let finalText = "",
      text2 = textToLimit.replace(/\s+/g, " "),
      text3 = text2.split(""),
      numOfWords = text3.length,
      i = 0;

    if (numOfWords > limit) {
      for (i; i < limit; i++) finalText = finalText + "" + text3[i] + "";
      return finalText + "...";
    } else {
      return textToLimit;
    }
  },
  uniqueArr: (val, ind, arr) => {
    return arr.indexOf(val) === ind;
  },
  formatDate: (date) => {
    const spl = date.split("");
    let y, m, d;
    y = spl[0] + spl[1] + spl[2] + spl[3];
    m = spl[4] + spl[5];
    d = spl[6] + spl[7];

    return `${y}/${m}/${d}`;
  },
  pageInfo: (data = {}) => {
    data.title = data.title || "Default title";
    data.desc = data.desc || "Default description";

    document.title = data.title;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", data.desc);
  },
  getAPI: () => {
    if (process.env.NODE_ENV === "development") {
      return process.env.REACT_APP_DEV_API_URL;
    } else if (process.env.NODE_ENV === "production") {
      return process.env.REACT_APP_PROD_API_URL;
    }
  },
  sendNotif: (
    payload = { sub: null, _id: null, body: null, url: null, fetchUrl: null }
  ) => {
    const data = {
      subscription: payload.sub,
      _id: payload._id,
      body: payload.body,
      url: payload.url,
    };

    // Post the data to server
    fetch(payload.fetchUrl, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => console.log("Push success..."))
      .catch((err) => console.log("err: ", err));
  },
  // Algorithm for getting setting custom options for buyer
  str2Obj: (str) => {
    // Splitting the string by spaces
    const spltBySpaces = str.split(/\s+/g);

    // Turn the splited string into array of objects
    const objArr = spltBySpaces.map((i) => {
      // Split the string by the equal sign
      const splitByEqual = i.split("=");

      return {
        [splitByEqual[0]]: splitByEqual[1].split(/,/g),
      };
    });

    // Keys and Values from the object array created above
    const keys = objArr.map((i) => Object.keys(i));
    const values = objArr.map((i) => Object.values(i));

    // Getting the main keys and values to create a main object
    const k = keys.map((i) => i[0]);
    const v = values.map((i) => i[0]);

    // Main object containing the keys and values
    let mainObj = {};

    k.forEach((i, ind) => {
      mainObj[i] = v[ind];
    });

    return mainObj;
  },
  // Transaction data
  transactionData(o, tf) {
    // Orders paid for
    const paidOrders = o.filter((i) => i.status !== "pending payment");

    const transData = paidOrders.map((i) => {
      // Getting the deduction value (amount)
      let deducted = 0;

      // Order
      const items = i.itemsInOrder.map((i) => {
        return {
          name: i.productName,
          price: i.productPrice,
          qty: i.qty,
        };
      });

      // The function to return the transaction fee
      const transFee = (tf) => {
        return tf
          .filter((t) => {
            if (t.transNum === `${i.orderNumber}`) {
              return t;
            } else {
              return false;
            }
          })
          .map((i) => i.fee)[0];
      };

      // Deduct agent's commission if an agent reffered the item,
      // to the user (If the value of agentCode is available)
      if (i.agentCode) {
        if (i.totalPrice >= 500) {
          deducted = i.totalPrice * (8 / 100);
          let fee = 0;
          if (tf.length >= 1) {
            fee = transFee(tf);
          }
          deducted -= fee;
        } else {
          deducted = i.totalPrice * (10 / 100);
          let fee = 0;
          if (tf.length >= 1) {
            fee = transFee(tf);
          }
          deducted -= fee;
        }
      }
      // If the item was bought without being reffered by any agent
      // then we don't deduct any addditional fee from the total amount
      // to be received by the seller
      else {
        deducted = 0;
        let fee = 0;
        if (tf.length >= 1) {
          fee = transFee(tf);
        }
        deducted -= fee;
      }

      // Available amount after deductions
      const available = this.roundTo2(i.totalPrice - deducted);

      return {
        available,
        dateOrdered: i.dateOrdered,
        deducted: this.roundTo2(deducted),
        transNum: i.orderNumber,
        total: parseInt(i.totalPrice).toFixed(2),
        items,
      };
    });

    return transData;
  },
  // Agent commissions
  agentCommission: (o) => {
    // Orders paid for
    const paidOrders = o.filter((i) => i.status !== "pending payment");

    // Amount deducted from the total price
    let deducted = 0;

    // The total amount for the commission earned
    let total = [0];
    total = paidOrders.map((i) => {
      // Deduct agent's commission if an agent reffered the item,
      // to the user (If the value of agentCode is available)
      if (i.agentCode) {
        if (i.totalPrice >= 500) {
          deducted = i.totalPrice * (8 / 100);
        } else {
          deducted = i.totalPrice * (10 / 100);
        }
      }

      return parseInt(deducted).toFixed(2);
    });

    return total.reduce((sum, comm) => (sum += comm));
  },
};

export default Functions;
