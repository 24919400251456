const UserUpdater = ({
  displayUpdater,
  toggleDisplayUpdater,
  nameProp,
  changeUpdateVal,
  msg,
  updateVal,
  updateAccount,
  id,
}) => {
  return (
    <div
      className="glowing-form"
      style={{ display: displayUpdater ? "flex" : "none" }}
    >
      <div className="contents">
        <div
          className="close"
          onClick={(e) => {
            toggleDisplayUpdater(e, "");
          }}
        >
          X
        </div>
        <input
          type="text"
          name={nameProp}
          placeholder={`Enter ${nameProp} value`}
          onChange={changeUpdateVal}
          value={updateVal}
        />
        <p style={{ color: "red" }}>{msg}</p>
        <button
          onClick={(e) => {
            e.preventDefault();
            updateAccount(id);
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default UserUpdater;
