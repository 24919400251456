import { useState } from "react";
import GoogleMaps from "../../assets/GoogleMaps";
import OrderForm from "./assets/OrderForm";
import ErrorBoundary from "../ErrorBoundary";
import Functions from "../../abstracts/Functions";
import DeliveryAddress from "./assets/DeliveryAddress";

const OrderInfo = ({ history }) => {
  const [done, setDone] = useState(false);
  const [mapInputVal, setMapInputVal] = useState("");
  const [location, setLocation] = useState("");

  Functions.pageInfo({
    title: "Ordapple | Order Information!",
    desc: "Order information",
  });

  const getValue = (val) => {
    setMapInputVal(val);
  };

  const updateDone = (e) => {
    e.preventDefault();
    setDone(!done);
  };
  const orderProcess = () => {
    if (done) {
      return (
        <OrderForm
          updateDone={updateDone}
          mapInputVal={mapInputVal}
          location={location}
          history={history}
        />
      );
    } else {
      return (
        // <GoogleMaps
        //   updateDone={updateDone}
        //   getValue={getValue}
        //   mapInputVal={mapInputVal}
        //   setLocation={setLocation}
        //   location={location}
        // />
        <DeliveryAddress updateDone={updateDone} setLocation={setLocation} />
      );
    }
  };
  return (
    <>
      <ErrorBoundary>{orderProcess()}</ErrorBoundary>
    </>
  );
};

export default OrderInfo;
