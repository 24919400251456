const CardMenu = ({ title, desc, toggle, cl }) => {
  return (
    <div className={`card-menu ${cl}`} onClick={toggle}>
      <div className="text" style={{ pointerEvents: "none" }}>
        <h2>{title}</h2>
        <p>{desc}</p>
      </div>
      <div className="icon" style={{ pointerEvents: "none" }}>
        <i className="fas fa-chevron-down"></i>
      </div>
    </div>
  );
};

export default CardMenu;
