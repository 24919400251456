import ImageEnlarge from "../../abstracts/ImageEnlarge";
import images from "../../abstracts/Images";
import { useState, useEffect } from "react";
import BuyerActions from "../../redux/actions/BuyerActions";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Loader from "../../abstracts/Loader";
import Popup from "../../assets/Popup";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import Functions from "../../abstracts/Functions";
import ReviewForm from "./assets/ReviewForm";
import { Link } from "react-router-dom";

const ItemPreview = ({ history }) => {
  // Use State
  const [visible, setVisible] = useState(false);
  const [display, setDisplay] = useState(true);
  const [formDisplay, setFormDisplay] = useState(false);
  const [msg, setMsg] = useState(false);
  // The range within which the fast food shop is located
  const [inRange, setInRange] = useState(false);
  const [location, setLocation] = useState("");
  const [reviewVal, setReviewVal] = useState("");
  const [reviews, setReviews] = useState([]);
  const [selectedOpt, setSelectedOpt] = useState({});

  // Get selected item
  const product_preview = useSelector((state) => state.product_preview);
  const flash = useSelector((state) => state.flash);
  const shoppingCart = useSelector((state) => state.cart);
  const cart = "items" in shoppingCart ? shoppingCart : { items: [], total: 0 };
  const user = useSelector((state) => state.user);
  const userID = user._id;
  const selected_item =
    "product" in product_preview ? product_preview.product : undefined;

  Functions.pageInfo({
    title: `Ordapple | ${selected_item?.product?.name}`,
    desc: "Product/Item Preview",
  });

  // Params
  const { shopID, prodID } = useParams();

  // Dispatch
  const dispatch = useDispatch();

  // Request the product info
  const reqProdInfo = () => {
    dispatch(RESTORE_FLASH());

    // Clearing the product preview state
    dispatch(BuyerActions.REMOVE_PREVIEWED_PRODUCT());

    // Getting the query data
    // If the user is logged in, set both the userId and
    // shopId as the query params, otherwise only set the shopId
    let q = "";
    if (userID) {
      q = `?userId=${userID}&shopId=${shopID}`;
    } else {
      q = `?shopId=${shopID}`;
    }

    axios({
      url: `${Functions.getAPI()}/product/${prodID}${q}`,
      method: "GET",
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Dispatching
        dispatch(BuyerActions.PRODUCT_PREVIEW(data));
        setReviews(data.product.reviews.reverse());
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  };

  // Render default selected options on first render
  const selectedOptions = () => {
    if (selected_item?.product?.options) {
      const mainObj = Functions.str2Obj(selected_item?.product?.options);
      const k = Object.keys(mainObj);
      const v = Object.values(mainObj);
      let selected = {};
      // Setting the default selected options
      const sltd = k.map((prop, ind) => {
        selected[prop] = v[ind][0];
        return selected;
      });
      setSelectedOpt(sltd[0]);
    }
  };

  // Use effect
  useEffect(reqProdInfo, [userID, shopID, prodID, dispatch]);

  // Use effect
  useEffect(selectedOptions, [reviews]);

  // Toggles

  // Toggle image elarger
  const toggleVisiblity = () => {
    setVisible(!visible);
  };

  // Toggle review form display
  const toggleFormDisplay = () => {
    setFormDisplay(!formDisplay);
    setMsg("");
    setReviewVal("");
  };

  // Typing/Writing a review
  const changeReviewVal = (e) => {
    const { value } = e.target;
    setReviewVal(value);
  };

  // Add a review
  const addReview = (e, status) => {
    e.preventDefault();

    if (reviewVal) {
      setDisplay(true);
      setFormDisplay(false);

      axios({
        url: `${Functions.getAPI()}/review/${prodID}`,
        method: "POST",
        withCredentials: true,
        data: { review: reviewVal, status },
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Dispatching
          setReviews([data.data, ...reviews]);
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(e);
          }
        });
    } else {
      setMsg("Write a review to continue!");
    }
  };

  const addToCart = (e) => {
    e.preventDefault();

    // Restoring the flash messages
    dispatch(RESTORE_FLASH());

    if (userID) {
      axios({
        url: `${Functions.getAPI()}/cart`,
        method: "POST",
        withCredentials: true,
        data: { shop: shopID, product: prodID, options: selectedOpt },
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Showing the popup message
          setDisplay(true);

          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
          const price = parseInt(data.data.price);
          dispatch(
            BuyerActions.ADD_TO_CART({
              items: [...cart.items, data.data],
              total: cart.total + price,
            })
          );
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            // Showing the popup message
            setDisplay(true);

            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(e);
          }
        });
    } else {
      // Showing the popup message
      setDisplay(true);
      dispatch(FLASH({ err: "", success: "", warn: "You are not logged in!" }));
    }
  };

  const renderSelectedProd = () => {
    if (selected_item !== undefined) {
      // Showing the picture of the selected product
      const imageRender = () => {
        if (selected_item.product.image.Key !== "") {
          return (
            <>
              <img
                src={selected_item.product.image.Location}
                onClick={toggleVisiblity}
                alt="Product"
              />
            </>
          );
        } else {
          return <p>No image</p>;
        }
      };

      // Showing whether the product is eligible for delivery or not
      // const paymentMethods = () => {
      //   if (selected_item.product.elegibleForDelivery === "Yes") {
      //     return (
      //       <ul>
      //         <li>Cash on delivery</li>
      //         <li>Cash on pick-up</li>
      //       </ul>
      //     );
      //   } else {
      //     return (
      //       <ul>
      //         <li>Cash on pick-up</li>
      //       </ul>
      //     );
      //   }
      // };

      // Calculating how many people made a bad/good review on a product
      // in percentage form
      const reviewRates = () => {
        const g = reviews.filter((r) => r.status === "good").length;
        const b = reviews.filter((r) => r.status === "bad").length;
        const l = reviews.length;
        const p = (v, len) => {
          return Math.round((v / len) * 100);
        };

        return (
          <div className="ratings">
            <p className="green">Good: {p(g, l)}%</p>
            <p className="red">Bad: {p(b, l)}%</p>
          </div>
        );
      };

      // Redering all reviews from users
      const renderReviews = () => {
        return reviews.map((r, ind) => {
          return (
            <div className="review" key={ind}>
              <div className="img">
                <img src={images.avatar} alt="avatar" />
              </div>
              <div className="content">
                <h2>{r.username}</h2>
                <p>{r.text}</p>
              </div>
            </div>
          );
        });
      };

      // Selecting an option
      const select = (prop, val) => {
        // Updating the selected option state
        let updated = { ...selectedOpt };
        updated[prop] = val;
        setSelectedOpt(updated);
      };

      const renderOptions = (sltd) => {
        const mainObj = Functions.str2Obj(selected_item?.product?.options);

        // All options
        const k = Object.keys(mainObj);
        const v = Object.values(mainObj);

        // Values for default options
        let selected = Object.values(sltd);

        return k.map((prop, ind) => {
          return (
            <div
              className="options-cont"
              key={ind}
              style={{ marginTop: "5px" }}
            >
              <h3
                style={{
                  fontWeight: "bolder",
                  padding: "0px 0px 10px 0px",
                  fontSize: "18px",
                }}
              >
                {" "}
                <span style={{ fontWeight: "normal" }}>{prop}:</span>{" "}
                {selected[ind]}
              </h3>
              <div className="options">
                {v[ind].map((i, index) => (
                  <p
                    key={index}
                    onClick={() => {
                      select(k[ind], i);
                    }}
                    className={selected[ind] == i ? "active" : ""}
                  >
                    {i}
                  </p>
                ))}
              </div>
            </div>
          );
        });
      };

      // Render the clicked product info
      const productInfo = () => {
        return (
          <div className="product-container">
            <div className="prod-image">{imageRender()}</div>

            <div className="prod-info">
              <h1 className="prod-name">{selected_item.product.name}</h1>
              <p style={{ padding: "0px 0px 12px 0px" }}>
                {selected_item.shop.name}{" "}
                {selected_item.shop.verified && (
                  <i className="fas fa-check verified"></i>
                )}
              </p>
              <div className="price-likes">
                <h1>{`${selected_item.product.currency} ${selected_item.product.price}`}</h1>
                <p className="likes">
                  <i className="fas fa-heart"></i>
                  <span className="dot"></span>
                  <span className="stat">{selected_item.product.likes}</span>
                  <span className="sep"></span> <i className="fas fa-eye"></i>
                  <span className="dot"></span>
                  <span className="stat">{selected_item.product.views}</span>
                </p>
              </div>
              <span className="line"></span>
              <br />
              {/* If product is eligible for delivery */}
              {/* <p>Payment methods:</p> */}

              {/* {paymentMethods()} */}

              {selected_item.product.options && renderOptions(selectedOpt)}
              <br />
              <form method="POST">
                <button type="submit" onClick={addToCart}>
                  + Add to cart
                </button>
              </form>
            </div>
          </div>
        );
      };

      // Search available shops in the entered location
      const searchShops = (e) => {
        e.preventDefault();

        axios({
          url: `${Functions.getAPI()}/shops/search?q=${location}`,
          method: "GET",
        })
          .then(async (res) => {
            // Waiting for the data before proceeding
            const data = await res.data;

            // Dispatching
            dispatch(BuyerActions.SEARCHED_SHOPS(data));

            // Redirecting the user
            history.push(`/shops/search?q=${location}`);
          })
          .catch((e) => {
            const res = e.response;
            if (res) {
              if (res.data.err) {
                dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
              } else {
                dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
              }
            } else {
              console.log(e);
            }
          });
      };

      // Input change of the location
      const locationChange = (e) => {
        const { value } = e.target;
        setLocation(value);
      };

      // Check the location of the shop and the user if they match
      const checkLocation = (e) => {
        e.preventDefault();
        const foundLocation = selected_item.shop.location.split(/,\s*/g)[1];
        if (foundLocation === location.toLowerCase()) {
          setInRange(true);
        } else {
          setInRange(false);
        }
      };

      // Render the product
      const renderProduct = () => {
        // Getting the product's tags
        const tags = selected_item.product.tags.split("#");

        // Checking if the product is a fast food item or not
        if (tags.includes("fast food")) {
          // If it's a fast food product,
          // give them a form to fill their location,
          // then if the location enter is the same as that of the shop,
          // render the fast food product,
          // otherwise let them enter another location.
          return (
            <>
              <div className="check-location">
                <div className="inputs">
                  <input
                    type="text"
                    placeholder="Enter suburb/township"
                    onChange={locationChange}
                    value={location}
                  />
                  <button type="submit" onClick={checkLocation}>
                    Check
                  </button>
                </div>
                <p style={{ color: "#bbb", fontSize: "14px" }}>
                  Check if the shop is available in your area!
                </p>
                <h1>
                  Status: <span>{inRange ? "available" : "Unavailable"}</span>{" "}
                </h1>
              </div>
              {inRange && productInfo()}

              {/* Link to search shops in the entered location */}
              {location && !inRange && (
                <Link
                  className="browse"
                  to={`/shops/search?q=${location}`}
                  onClick={searchShops}
                >
                  Browse shops in {location}
                </Link>
              )}
            </>
          );
        } else {
          return productInfo();
        }
      };

      return (
        <>
          {flash.err !== "" && (
            <Popup display={display} setDisplay={setDisplay} err={flash.err} />
          )}
          {flash.success !== "" && (
            <Popup
              display={display}
              setDisplay={setDisplay}
              success={flash.success}
            />
          )}
          {flash.warn !== "" && (
            <Popup
              display={display}
              setDisplay={setDisplay}
              warn={flash.warn}
            />
          )}

          {/* Render the product */}
          {renderProduct()}

          {/* Product details */}
          <div className="product-details">
            <h1>Product details:</h1>
            <p className="desc">{selected_item.product.description}</p>
          </div>

          {/* Product reviews */}
          <div className="reviews">
            <div className="review-nav">
              <h1>Reviews:</h1>
              <button onClick={toggleFormDisplay}>Add review</button>
            </div>

            {reviews.length > 0 ? (
              <>
                {reviewRates()}
                {renderReviews()}
              </>
            ) : (
              <h2 className="not-found">No reviews</h2>
            )}
          </div>

          <ReviewForm
            formDisplay={formDisplay}
            toggleFormDisplay={toggleFormDisplay}
            changeReviewVal={changeReviewVal}
            msg={msg}
            reviewVal={reviewVal}
            addReview={addReview}
          />
        </>
      );
    } else {
      return <Loader />;
    }
  };
  return (
    <div>
      {selected_item && (
        <ImageEnlarge
          src={selected_item.product.image.Location}
          toggleVisiblity={toggleVisiblity}
          visible={visible}
        />
      )}

      <div className="products">{renderSelectedProd()}</div>
    </div>
  );
};

export default ItemPreview;
