// USER INFO
const USER_INFO = (user) => {
  return {
    type: "USER_INFO",
    payload: user,
  };
};
// EDIT ACCOUNT
const EDIT_ACCOUNT = (user) => {
  return {
    type: "EDIT_ACCOUNT",
    payload: user,
  };
};
// FOLLOW SHOP
const FOLLOW_SHOP = (id) => {
  return {
    type: "FOLLOW_SHOP",
    payload: id,
  };
};
// UNFOLLOW SHOP
const UNFOLLOW_SHOP = (id) => {
  return {
    type: "UNFOLLOW_SHOP",
    payload: id,
  };
};
// DELETE ACCOUNT
const DELETE_ACCOUNT = () => {
  return {
    type: "DELETE_ACCOUNT",
  };
};

const USER = {
  USER_INFO,
  FOLLOW_SHOP,
  UNFOLLOW_SHOP,
  EDIT_ACCOUNT,
  DELETE_ACCOUNT,
};

export default USER;
