import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import axios from "axios";
import { RESTORE_FLASH } from "../../redux/actions/Flash";
import Functions from "../../abstracts/Functions";
import images from "../../abstracts/Images";

const Order = () => {
  Functions.pageInfo({
    title: "Ordapple | Order review",
    desc: "Completing the ordering process",
  });

  // State
  const [pfHost, setPfHost] = useState("");
  const [pfData, setPfData] = useState({});
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState(true);

  // Params
  const { order_num } = useParams();

  // Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RESTORE_FLASH());
    axios({
      url: `${Functions.getAPI()}/order/${order_num}`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const { data } = await res.data;
        setPfHost(data.data.pfHost);

        // Setting data
        const myData = [];
        // Merchant details
        myData["merchant_id"] = data.data.merchant_id;
        myData["merchant_key"] = data.data.merchant_key;
        // URLS
        myData["return_url"] = data.data.return_url;
        myData["cancel_url"] = data.data.cancel_url;
        myData["notify_url"] = data.data.notify_url;
        // Buyer details
        myData["name_first"] = data.data.name_first;
        myData["name_last"] = data.data.name_last;
        myData["email_address"] = data.data.email_address;
        // Transaction details
        myData["m_payment_id"] = data.data.m_payment_id;
        myData["amount"] = data.data.amount;
        myData["item_name"] = data.data.item_name;
        // Transaction options (Sending confirmation email to merchant)
        myData["email_confirmation"] = data.data.email_confirmation;
        myData["confirmation_address"] = data.data.confirmation_address;
        // Payment methods
        /*
        ‘eft’ – EFT
        ‘cc’ – Credit card
        ‘dc’ – Debit card
        ’mp’ – Masterpass
        ‘mc’ – Mobicred
        ‘sc’ – SCode
        ‘ss’ – SnapScan
        ‘zp’ – Zapper
        ‘mt’ – MoreTyme
        */
        myData["payment_method"] = data.data.payment_method;

        // Generate signature
        myData["signature"] = data.data.signature;

        setPfData(myData);
        setLoading(false);
        setAddress(data.data.address);
      })
      .catch((err) => {
        const res = err.response;
        if (res) {
          console.log(res.data);
        } else {
          console.log(err);
        }
      });
  }, [dispatch]);

  const hiddednInputs = () => {
    const keys = Object.keys(pfData);
    const values = Object.values(pfData);

    return keys.map((i, ind) => {
      return (
        <input name={i} type="hidden" key={ind} value={values[ind].trim()} />
      );
    });
  };

  return (
    <div className="register-login">
      <div className="form">
        <div className="heading">
          <h1>Step 3 (Payment)</h1>
          <p>Order #{order_num}</p>
        </div>
        <form action={pfHost} className="form-slide" method="post">
          <img src={images.package_box} alt="package box" />
          {hiddednInputs()}
          <h2
            style={{ fontSize: "18px", fontWeight: "400", marginTop: "40px" }}
          >
            Total:{" "}
            <span
              style={{
                fontWeight: "bolder",
                color: "rgb(14, 136, 14)",
                marginLeft: "12px",
              }}
            >
              R{pfData.amount}
            </span>
          </h2>
          <button
            style={loading ? { display: "none" } : { display: "flex" }}
            type="submit"
            className="submit-button"
            id="success"
          >
            Continue
          </button>

          <div className="two-col">
            <h2 className="heading">Delivery Address</h2>
            <h4 style={{ fontWeight: "bold" }}>
              {pfData.name_first} {pfData.name_last}
            </h4>
            <h4>{address}</h4>
            <h2 className="heading">Delivery date</h2>
            <h4>After 7-14 working days (Excluding Fast Food items)</h4>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Order;
