import React from "react";

const CardContent = React.forwardRef(({ children }, ref) => {
  return (
    <div className="card-content" ref={ref} style={{ display: "none" }}>
      {children}
    </div>
  );
});

export default CardContent;
