import { createRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminActions from "../../redux/actions/AdminActions";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import AdminNav from "./assets/AdminNav";
import axios from "axios";
import Popup from "../../assets/Popup";
import Form from "../../assets/Form";
import Functions from "../../abstracts/Functions";

const AdminEditAcc = ({ history }) => {
  // Redux state
  const admin_info = useSelector((state) => state.admin_info);
  const user = useSelector((state) => state.user);
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Edit Your Admin Account",
    desc: "Editing Your Admin Account",
  });

  // Redux disparch
  const dispatch = useDispatch();

  // Use state
  const [display, setDisplay] = useState(true);
  const [shopNameVal, setShopNameVal] = useState(
    admin_info.name ? admin_info.name : ""
  );
  const [adminNameVal, setAdminNameVal] = useState(
    admin_info.adminName ? admin_info.adminName : ""
  );
  const [aboutShopVal, setAboutShopVal] = useState(
    admin_info.about ? admin_info.about : ""
  );
  const [locationVal, setLocationVal] = useState(
    admin_info.location ? admin_info.location : ""
  );

  // Messages
  const [msg, setMsg] = useState({});

  // Refs
  const shopName = createRef();
  const adminName = createRef();
  const aboutShop = createRef();
  const location = createRef();

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  const onChange = (e) => {
    const { value, name, style } = e.target;

    if (name === "shopName") {
      setShopNameVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "adminName") {
      setAdminNameVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "aboutShop") {
      setAboutShopVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "location") {
      setLocationVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    }
  };

  // Edit account function
  const edit_account = (e) => {
    if (shopName.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ shopName: "Required field!" });
    } else if (adminName.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ adminName: "Required field!" });
    } else if (aboutShop.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ aboutShop: "Required field!" });
    } else if (location.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ location: "Required field!" });
    } else {
      setDisplay(true);
      e.preventDefault();

      // Loading animation on submit button
      e.target.classList.add("btn-animate");
      e.target.innerText = "Processing...";

      const shopName = shopNameVal;
      const adminName = adminNameVal;
      const aboutShop = aboutShopVal;
      const shopLocation = locationVal;

      // Request url
      const url = `${Functions.getAPI()}/admin/update/${user._id}`;

      const data = {
        shopName,
        adminName,
        aboutShop,
        shopLocation,
      };

      axios({
        url: url,
        method: "POST",
        data,
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
          dispatch(AdminActions.EDIT_ACCOUNT(data.data));
        })
        .catch((err) => {
          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          const res = err.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });

      setMsg({});
    }
  };

  return (
    <div>
      {admin_info?.name && <AdminNav page="" />}

      {flash.err !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          err={flash.err}
          history={history}
        />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
          history={history}
        />
      )}
      {flash.warn !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          warn={flash.warn}
          history={history}
        />
      )}

      <Form>
        <div className="heading">
          <h1>Edit shop</h1>
        </div>

        <input
          type="text"
          className="formInput"
          name="shopName"
          id="shopName"
          placeholder="Shop name"
          value={shopNameVal}
          onChange={onChange}
          ref={shopName}
        />
        <p style={{ color: "red" }} id={msg.shopName && "err"}>
          {msg.shopName}
        </p>

        <input
          type="text"
          className="formInput"
          name="adminName"
          id="adminName"
          placeholder="Admin name"
          value={adminNameVal}
          onChange={onChange}
          ref={adminName}
        />
        <p style={{ color: "red" }} id={msg.adminName && "err"}>
          {msg.adminName}
        </p>

        <input
          type="text"
          className="formInput"
          name="aboutShop"
          id="aboutShop"
          placeholder="About your shop"
          value={aboutShopVal}
          onChange={onChange}
          ref={aboutShop}
        />
        <p style={{ color: "red" }} id={msg.aboutShop && "err"}>
          {msg.aboutShop}
        </p>

        <input
          type="text"
          className="formInput"
          name="location"
          id="location"
          onKeyUp={(e) => (e.target.value = e.target.value.toLowerCase())}
          placeholder="Location of your shop"
          value={locationVal}
          onChange={onChange}
          ref={location}
        />
        <p style={{ color: "red" }} id={msg.location && "err"}>
          {msg.location}
        </p>
        <p>Format: Street/Unit, Suburb/Township, City, Proivince</p>

        <button type="submit" className="submit-button" onClick={edit_account}>
          Submit
        </button>
      </Form>
    </div>
  );
};

export default AdminEditAcc;
