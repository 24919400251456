import images from "../../abstracts/Images";
import Functions from "../../abstracts/Functions";

const Offline = () => {
  Functions.pageInfo({
    title: "Ordapple | Offline!",
    desc: "You are offline",
  });

  return (
    <div className="error">
      <div className="error-container">
        <h2>OOPS!</h2>
        <br />
        <div className="img">
          <img src={images.illustrator} alt="illustration" />
        </div>
        <p>
          You are offline! <br />
          Check your internet connection.
        </p>
        <br />
        {/* If user is logged in */}
        <a style={{ textAlign: "center" }} href="/shops">
          Continue shopping
        </a>
        <p>OR</p>
        <a style={{ textAlign: "center", marginTop: "10px" }} href="/">
          Go back home
        </a>
        {/* If user is not logged in */}
        {/* <a style={{ textAlign: "center" }} href="/">
          Go back home
        </a> */}
      </div>
    </div>
  );
};

export default Offline;
