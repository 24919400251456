import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
// import Messages from "../../abstracts/Messages";
import ShopCard from "../../assets/ShopCard";
import BuyerActions from "../../redux/actions/BuyerActions";
import axios from "axios";
import { RESTORE_FLASH } from "../../redux/actions/Flash";
import Loader from "../../abstracts/Loader";
import Popup from "../../assets/Popup";
import Functions from "../../abstracts/Functions";

const Shops = () => {
  // Redux state
  const shops = useSelector((state) => state.shops);
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | All Available Shops",
    desc: "Viewing All Available Shops",
  });

  // Dispatch
  const dispatch = useDispatch();

  // State
  const [display, setDisplay] = useState(false);

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
    axios({
      url: `${Functions.getAPI()}/shops`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;
        // If status is 200, let the user login
        if (res.status === 200) {
          // Dispatching
          dispatch(BuyerActions.SHOPS(data));
        }
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  }, [dispatch]);

  // Rendering all available shops
  const renderShops = () => {
    // All available shops that are not in the top 10 shops
    if ("shops" in shops) {
      if (shops.shops.length >= 1) {
        return (
          <>
            <div className="heading">
              <h1>All Shops</h1>
            </div>
            {shops.shops.map((shop, ind) => (
              <>
                <ShopCard
                  key={ind}
                  page="shops"
                  shopId={shop._id}
                  owner={shop.owner}
                  shopName={shop.name}
                  aboutShop={shop.about}
                  shopLocation={shop.location}
                  workingHours={shop.workingHours}
                  visits={shop.visits}
                  followers={shop.allFollowers}
                  img={shop.image.Location}
                  shopVerified={shop.verified}
                  setDisplay={setDisplay}
                />
              </>
            ))}
          </>
        );
      }

      // Top shops
      if (shops.topShops.length >= 1) {
        return (
          <>
            <div className="heading">
              <h1>Top Shops</h1>
            </div>
            {shops.topShops.map((shop, ind) => (
              <Fragment key={ind}>
                <ShopCard
                  key={ind}
                  page="shops"
                  shopId={shop._id}
                  owner={shop.owner}
                  shopName={shop.name}
                  aboutShop={shop.about}
                  shopLocation={shop.location}
                  workingHours={shop.workingHours}
                  visits={shop.visits}
                  followers={shop.allFollowers}
                  img={shop.image.Location}
                  setDisplay={setDisplay}
                />
              </Fragment>
            ))}
          </>
        );
      } else {
        return (
          <div className="heading">
            <h1 style={{ color: "#bbb" }}>No Shops found!</h1>
          </div>
        );
      }
    } else {
      return <Loader />;
    }
  };

  return (
    <div className="shops">
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}

      {renderShops()}
    </div>
  );
};

export default Shops;
