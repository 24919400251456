import images from "../../abstracts/Images";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Functions from "../../abstracts/Functions";

const Error404 = () => {
  const user = useSelector((state) => state.user);

  Functions.pageInfo({
    title: "Ordapple | Error 404!",
    desc: "Error 404!",
  });

  return (
    <div className="error">
      <div className="error-container">
        <h2>404</h2>
        <br />
        <div className="img">
          <img src={images.illustrator} alt="illustration" />
        </div>
        <p>Resource not found!</p>
        <br />
        {/* If a user is logged in */}
        {"_id" in user ? (
          <>
            <Link style={{ textAlign: "center" }} to="/shops">
              Continue shopping
            </Link>
            <Link
              style={{
                textAlign: "center",
              }}
              to="/"
            >
              Go back home
            </Link>
          </>
        ) : (
          <Link style={{ textAlign: "center" }} to="/">
            Go back home
          </Link>
        )}
      </div>
    </div>
  );
};

export default Error404;
