import AccountInfo from "../../assets/AccountInfo";
import ShopCard from "../../assets/ShopCard";
import ProdCard from "../../assets/ProdCard";
import { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import BuyerActions from "../../redux/actions/BuyerActions";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import Loader from "../../abstracts/Loader";
import Popup from "../../assets/Popup";
import Functions from "../../abstracts/Functions";

const BuyerAcc = () => {
  // Redux state
  const buyer_account = useSelector((state) => state.buyer_account);
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Manage Your Buyer Account",
    desc: "Manage your user account",
  });

  // State
  const [display, setDisplay] = useState(true);

  // Refs
  const following = createRef();
  const liked = createRef();
  const follBtn = createRef();
  const likedBtn = createRef();

  // Get data from state
  let user = useSelector((state) => state.user);

  // vars
  let name, email;

  if (user !== undefined) {
    name = `${user.firstName} ${user.lastName}`;
    email = user.email;
  }

  // Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RESTORE_FLASH());
    axios({
      url: `${Functions.getAPI()}/buyer/account`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;
        dispatch(BuyerActions.FOLLOWING(data.shops));
        dispatch(BuyerActions.LIKED(data.liked));
      })
      .catch((err) => {
        const res = err.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(err);
        }
      });
  }, [dispatch, user]);

  const toggleNav = (e) => {
    dispatch(FLASH({ err: "", success: "", warn: "" }));
    const cList = e.target.classList;
    if (cList.contains("following")) {
      if (likedBtn.current.classList.contains("active")) {
        likedBtn.current.classList.remove("active");
      }
      if (!follBtn.current.classList.contains("active")) {
        follBtn.current.classList.add("active");
      }

      liked.current.style.display = "none";
      following.current.style.display = "flex";
    } else if (cList.contains("liked")) {
      if (!likedBtn.current.classList.contains("active")) {
        likedBtn.current.classList.add("active");
      }
      if (follBtn.current.classList.contains("active")) {
        follBtn.current.classList.remove("active");
      }
      liked.current.style.display = "flex";
      following.current.style.display = "none";
    }
  };

  // Render function
  const renderShopCard = () => {
    if ("following" in buyer_account) {
      if (buyer_account.following.length >= 1) {
        return buyer_account.following.map((shop, ind) => (
          <ShopCard
            key={ind}
            page="buyer-account"
            shopId={shop._id}
            shopName={shop.shopName}
            aboutShop={shop.aboutShop}
            shopLocation={shop.shopLocation}
            workingHours={shop.workingHours}
            visits={shop.visits}
            followers={shop.followers}
            shopVerified={shop.verified}
            img={shop.adminImage}
            setDisplay={setDisplay}
          />
        ));
      } else {
        return (
          <div className="heading">
            <h1 style={{ color: "#bbb" }}>There's nothing here!</h1>
          </div>
        );
      }
    } else {
      return <Loader />;
    }
  };

  const renderProdCard = () => {
    if ("liked" in buyer_account) {
      if (buyer_account.liked.length === 0) {
        return (
          <div className="heading">
            <h1 style={{ color: "#bbb" }}>There's nothing here!</h1>
          </div>
        );
      } else {
        return buyer_account.liked.map((product, ind) => (
          <ProdCard
            key={ind}
            page="buyer-account"
            setDisplay={setDisplay}
            shopName={product.shopName}
            shopVerified={product.shopVerified}
            img={product.productImage}
            productName={product.productName}
            productCurrency={product.productCurrency}
            productPrice={product.productPrice}
            productId={product.id}
            shopId={product.shopId}
            likes={product.allLikes}
          />
        ));
      }
    } else {
      return <Loader />;
    }
  };

  return (
    <div className="account buyer">
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      <div className="account-container buyer-acc-cont">
        <AccountInfo buyer={true} email={email} name={name} />
      </div>

      <div className="account-nav">
        <p ref={follBtn} className="following active" onClick={toggleNav}>
          Following
        </p>
        <p ref={likedBtn} className="liked" onClick={toggleNav}>
          Liked
        </p>
      </div>

      {/* Following */}
      <div ref={following} className="following-container">
        {renderShopCard()}
      </div>

      {/* Liked items */}
      <div ref={liked} className="liked-container">
        <div className="prod-card-container">{renderProdCard()}</div>
      </div>
    </div>
  );
};

export default BuyerAcc;
