const Styles = {
  embla: {
    overflow: "hidden",
  },
  embla__container: {
    display: "flex",
  },
  embla__slide: {
    position: "relative",
    flex: "0 0 100%",
  },
};

export default Styles;
