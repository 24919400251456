// Logos
const logo512 = require("../img/logos/ordapple (512x512).png").default;

// Avatar
const avatar = require("../img/avatar.png").default;
const illustrator = require("../img/404 illustrator.png").default;
const package_box = require("../img/package_box.png").default;
const check = require("../img/check.png").default;
const cross = require("../img/cross.png").default;

// Founders' images
const founders = {
  me: require("../img/founders/my-pic.jpg").default,
  him: require("../img/founders/his-pic.jpg").default,
};

// Banner images
const banners = [
  require("../img/banners/Ordapple home banner(fashion)_comp.jpg").default,
  require("../img/banners/Ordapple home banner(food)_comp.jpg").default,
  require("../img/banners/Ordapple home banner(gadgets)_comp.jpg").default,
  require("../img/banners/Ordapple home banner(books)_comp.jpg").default,
];

const images = {
  logos: {
    logo512: logo512,
  },
  avatar,
  banners,
  founders,
  illustrator,
  package_box,
  check,
  cross,
};

export default images;
