import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import BuyerActions from "../../redux/actions/BuyerActions";
import axios from "axios";
import Loader from "../../abstracts/Loader";
import Popup from "../../assets/Popup";
import { useState } from "react";
import CartItem from "./assets/CartItem";
import { Link } from "react-router-dom";
import Functions from "../../abstracts/Functions";

const ShopResults = () => {
  // Getting state values
  const user = useSelector((state) => state.user);
  const userID = user._id;
  const cart = useSelector((state) => state.cart);
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Shopping Cart!",
    desc: "View your shopping cart",
  });

  // Dispatch
  const dispatch = useDispatch();

  // State
  const [display, setDisplay] = useState(false);

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
    axios({
      url: `${Functions.getAPI()}/cart`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;
        // If status is 200, let the user login
        if (res.status === 200) {
          // Dispatching
          dispatch(BuyerActions.ADD_TO_CART(data));
        }
      })
      .catch((e) => console.log(e));
  }, [dispatch, userID]);

  // Cart Actions

  // Remove
  const removeItem = (e, productId) => {
    setDisplay(true);
    e.preventDefault();

    axios({
      url: `${Functions.getAPI()}/cart/remove/${productId}`,
      method: "POST",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Dispatching
        dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        dispatch(BuyerActions.REMOVE_FROM_CART(productId));
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(e);
        }
      });
  };

  // Onclick events
  const add = (qty) => {
    return parseInt(qty) + 1;
  };
  const minus = (qty) => {
    return parseInt(qty) - 1;
  };

  // Update
  const updateCart = (e, productId, cb) => {
    setDisplay(true);
    e.preventDefault();
    dispatch(RESTORE_FLASH());

    if (cb >= 1) {
      axios({
        url: `${Functions.getAPI()}/cart/update/${productId}`,
        method: "POST",
        withCredentials: true,
        data: { quantity: cb },
      })
        .then(() => {
          dispatch(BuyerActions.UPDATE_CART({ id: productId, qty: cb }));
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(e);
          }
        });
    } else {
      dispatch(
        FLASH({
          err: "",
          success: "",
          warn: "You can't have 0 quantity (Remove item instead)",
        })
      );
    }
  };

  // Render functions

  const renderCartItems = () => {
    if (cart !== undefined) {
      if (cart.items.length === 0) {
        return <h3 className="no-items">No items in Cart</h3>;
      } else {
        return (
          <>
            {flash.err !== "" && (
              <Popup
                display={display}
                setDisplay={setDisplay}
                err={flash.err}
              />
            )}
            {flash.success !== "" && (
              <Popup
                display={display}
                setDisplay={setDisplay}
                success={flash.success}
              />
            )}
            {flash.warn !== "" && (
              <Popup
                display={display}
                setDisplay={setDisplay}
                warn={flash.warn}
              />
            )}
            {cart.items.map((item, ind) => {
              return (
                <CartItem
                  key={item.productId}
                  item={item}
                  updateCart={updateCart}
                  removeItem={removeItem}
                  add={add}
                  minus={minus}
                />
              );
            })}
            <div className="cart-summary">
              <h1>Cart summary</h1>
              <div className="total">
                <p>Total:</p>
                <h4>R{cart.total}</h4>
              </div>
              <br />
              <Link to="/order-info" className="submit-button">
                Proceed to checkout
              </Link>
            </div>
          </>
        );
      }
    } else {
      <Loader />;
    }
  };

  return (
    <div className="carts">
      <div className="heading">
        <h1>Shopping cart</h1>
      </div>

      {renderCartItems()}
    </div>
  );
};

export default ShopResults;
