import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "../../../assets/Form";
import FLASH from "../../../redux/actions/Flash";
import Popup from "../../../assets/Popup";

const ImgUploader = ({ setFile, forward, setFileName }) => {
  // Use state
  const [src, setSrc] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [flashDisplay, setFlashDisplay] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const flash = useSelector((state) => state.flash);

  // File reader
  const reader = new FileReader();

  // Handle file change
  const onChange = async (e) => {
    // Getting the currently uploaded file
    const file = await e.target.files[0];

    // File's size
    const fileSize = file.size / 1024;

    // Setting the file name
    setFileName(file.name);

    // If there's a file selected
    if (file) {
      // If the file type is an image
      if (file.type.match(/image/i)) {
        if (fileSize > 1024 * 3) {
          //   Showing the popup
          setFlashDisplay(true);

          // Display a popup
          dispatch(
            FLASH({
              err: "File too large (Should be 3mb or less)",
              success: "",
              warn: "",
            })
          );
        } else {
          // Reads the uploaded file as dataUrl
          reader.readAsDataURL(file);

          reader.onload = async (e) => {
            // This is the uploaded file as DataUrl
            const uploadedFile = e.target.result;

            // Dynamically creating an image elem to use
            const img = document.createElement("img");
            img.src = uploadedFile;

            // After the image source has loaded the function below fires
            img.onload = (e) => {
              // Dynamically creating an image elem to use
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              // The maximum width for resizing
              const mx_wid = 500;

              // Getting the correct scale for the image
              const scaleSize = mx_wid / e.target.width;

              // Setting the width and height for canvas
              canvas.width = mx_wid;
              canvas.height = e.target.height * scaleSize;

              // Drawing the image before resizing
              ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

              // Getting the resized image as dataUrl
              const url = ctx.canvas.toDataURL(e.target, file.type);

              // Set src for the file to display after upload
              setSrc(url);

              // Setting the file for uploading
              setFile(url);
            };
          };
        }
      } else {
        //   Showing the popup
        setFlashDisplay(true);

        // Display a popup
        dispatch(FLASH({ err: "", success: "", warn: "Only images allowed!" }));
      }
    } else {
      // If no image is uploaded, set the img source to null
      setFile(null);
    }
  };

  // Skip the song cover upload
  const skip = (e) => {
    e.preventDefault();
    forward();
  };

  // Handling dragging and dropping

  const dragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const dragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(false);
    }
  };

  const drag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const drop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      // Getting the currently uploaded file
      const file = await e.dataTransfer.files[0];
      // File's size
      const fileSize = file.size / 1024;

      // Setting the file name
      setFileName(file.name);

      // Clearing the data from dataTransfer to be left
      // with the file just uploaded
      e.dataTransfer.clearData();

      // If there's a file selected
      if (file) {
        // If the file type is an image
        if (file.type.match(/image/i)) {
          if (fileSize > 1024 * 3) {
            //   Showing the popup
            setFlashDisplay(true);

            // Display a popup
            dispatch(
              FLASH({
                err: "File too large (Should be 3mb or less)",
                success: "",
                warn: "",
              })
            );
          } else {
            // Reads the uploaded file as dataUrl
            reader.readAsDataURL(file);

            reader.onload = async (e) => {
              // This is the uploaded file as DataUrl
              const uploadedFile = e.target.result;

              // Dynamically creating an image elem to use
              const img = document.createElement("img");
              img.src = uploadedFile;

              // After the image source has loaded the function below fires
              img.onload = (e) => {
                // Dynamically creating an image elem to use
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // The maximum width for resizing
                const mx_wid = 500;

                // Getting the correct scale for the image
                const scaleSize = mx_wid / e.target.width;

                // Setting the width and height for canvas
                canvas.width = mx_wid;
                canvas.height = e.target.height * scaleSize;

                // Drawing the image before resizing
                ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

                // Getting the resized image as dataUrl
                const url = ctx.canvas.toDataURL(e.target, file.type);

                // Set src for the file to display after upload
                setSrc(url);

                // Setting the file for uploading
                setFile(url);
              };
            };
          }
        } else {
          //   Showing the popup
          setFlashDisplay(true);

          // Display a popup
          dispatch(
            FLASH({ err: "", success: "", warn: "Only images allowed!" })
          );
        }
      } else {
        // If no image is uploaded, set the img source to null
        setFile(null);
      }
    }
  };

  // Undo file upload in order to upload again
  const undo = (e) => {
    e.preventDefault();
    setSrc(null);
  };

  const renderLoader = () => {
    const mainColor = "rgb(255, 214, 204)";
    return (
      <Form className="expand">
        <div className="heading">
          <h1>Step 1 (optional)</h1>
        </div>

        <input type="file" id="cover" onChange={onChange} />
        <label
          className="drag-drop"
          htmlFor="cover"
          style={{
            color: "#777",
            display: src ? "none" : "flex",
            backgroundColor: !dragging ? "#eee" : `${mainColor}`,
          }}
          onDragEnter={dragIn}
          onDragLeave={dragOut}
          onDragOver={drag}
          onDrop={drop}
        >
          <i className="fas fa-cloud-upload"></i>
          Click here or drag-drop to upload image
        </label>

        <img
          style={{ display: src ? "flex" : "none" }}
          src={src}
          alt="product"
          loading="lazy"
        />

        <button
          onClick={(e) => {
            e.preventDefault();
            forward();
          }}
          style={{ display: src ? "flex" : "none", marginTop: "10px" }}
          className="submit-button"
        >
          Next
        </button>
        <button
          style={{ display: !src ? "flex" : "none" }}
          className="drop-btn dry"
          onClick={skip}
        >
          Skip
        </button>
        <button
          style={{ display: src ? "flex" : "none" }}
          className="drop-btn dry"
          onClick={undo}
        >
          Undo
        </button>
      </Form>
    );
  };
  return (
    <>
      {/* Popups */}
      {flash.err !== "" && (
        <Popup
          display={flashDisplay}
          setDisplay={setFlashDisplay}
          err={flash.err}
        />
      )}
      {flash.success !== "" && (
        <Popup
          display={flashDisplay}
          setDisplay={setFlashDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup
          display={flashDisplay}
          setDisplay={setFlashDisplay}
          warn={flash.warn}
        />
      )}
      {renderLoader()}
    </>
  );
};
export default ImgUploader;
