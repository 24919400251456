import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Functions from "../../../abstracts/Functions";
import AdminActions from "../../../redux/actions/AdminActions";
import FLASH from "../../../redux/actions/Flash";

const EditProduct = (props) => {
  // Use state
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [currency, setCurrency] = useState("");
  const [delivery, setDelivery] = useState("");
  const [description, setDescription] = useState("");
  const [inStock, setInStock] = useState("");
  const [options, setOptions] = useState("");

  // Setting the values for the product before editing
  useEffect(() => {
    if (props.selectedProd.name) {
      setName(props.selectedProd.name);
      setPrice(props.selectedProd.price);
      setCategory(props.selectedProd.category);
      setTags(props.selectedProd.tags);
      setCurrency(props.selectedProd.currency);
      setDelivery(props.selectedProd.delivery);
      setDescription(props.selectedProd.description);
      setInStock(props.selectedProd.inStock);
      setOptions(props.selectedProd.options);
    }
  }, [
    props.selectedProd.name,
    props.selectedProd.price,
    props.selectedProd.category,
    props.selectedProd.tags,
    props.selectedProd.currency,
    props.selectedProd.delivery,
    props.selectedProd.description,
    props.selectedProd.inStock,
    props.selectedProd.options,
  ]);

  // Redux
  const dispatch = useDispatch();

  // Modifying the product information
  const onChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        setName(value);
        break;
      case "price":
        setPrice(value);
        break;
      case "category":
        setCategory(value);
        break;
      case "tags":
        setTags(value);
        break;
      case "currency":
        setCurrency(value);
        break;
      case "delivery":
        setDelivery(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "inStock":
        setInStock(value);
        break;
      case "options":
        setOptions(value);
        break;
      default:
        break;
    }
  };

  // Save edited product
  const save = (e) => {
    e.preventDefault();

    // Display a popup message
    props.setDisplay(true);

    axios({
      url: `${Functions.getAPI()}/product/edit/${props.selectedProd._id}`,
      method: "POST",
      withCredentials: true,
      data: {
        name,
        category,
        currency,
        description,
        tags,
        price,
        delivery,
        options,
        inStock: inStock === "Yes" ? true : false,
      },
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Dispatching
        dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        dispatch(
          AdminActions.EDIT_PRODUCT({
            _id: props.selectedProd._id,
            name,
            category,
            currency,
            description,
            tags,
            price,
            delivery,
            inStock,
            options,
          })
        );

        // Close the product editor
        props.setShowProd(false);
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(e);
        }
      });
  };
  return (
    <div
      className="form-popup-container"
      style={props.showProd ? { display: "flex" } : { display: "none" }}
    >
      <div className="form">
        <form action="/whs" method="POST">
          <div className="day-card">
            <div className="heading">
              <h3 style={{ marginBottom: "8px" }}>Edit item</h3>
            </div>
            <div className="inp">
              <input
                type="text"
                className="formInput"
                name="name"
                id="name"
                placeholder="Product name"
                value={name}
                onChange={onChange}
              />

              <input
                type="number"
                className="formInput"
                name="price"
                id="price"
                placeholder="Product price"
                value={price}
                onChange={onChange}
              />

              <input
                type="text"
                className="formInput"
                name="options"
                id="options"
                placeholder="Custom options"
                value={options}
                onChange={onChange}
              />

              <select
                name="category"
                id="category"
                value={category}
                onChange={onChange}
              >
                <option value="">Select product category:</option>
                <option value="book">Book</option>
                <option value="fashion">Fashion</option>
                <option value="food">Food</option>
                <option value="gadget">Gadget</option>
                <option value="kitchen">Kitchen</option>
                <option value="service">Service</option>
                <option value="tech">Tech</option>
                <option value="other">Other</option>
              </select>

              {/* <input
                type="text"
                className="formInput"
                name="tags"
                placeholder="Tags (e.g #bag #women #fruit)"
                value={tags}
                onChange={onChange}
              /> */}

              <select
                name="currency"
                id="currency"
                value={currency}
                onChange={onChange}
              >
                <option value="R">Currency: (R)</option>
                <option value="$">Currency: ($)</option>
              </select>

              <select
                name="delivery"
                id="delivery"
                value={delivery}
                onChange={onChange}
              >
                <option value="No">Elegible for Delivery: (No)</option>
                <option value="Yes">Elegible for Delivery: (Yes)</option>
              </select>

              <select name="inStock" value={inStock} onChange={onChange}>
                <option value="No">In stock: ({inStock ? "Yes" : "No"})</option>
                <option value="Yes">
                  In stock: ({!inStock ? "Yes" : "No"})
                </option>
              </select>

              <textarea
                type="text"
                className="formInput"
                name="description"
                id="description"
                placeholder="Product details"
                value={description}
                onChange={onChange}
              ></textarea>
            </div>
          </div>
          <button className="submit-button" type="submit" onClick={save}>
            Submit
          </button>
          <button className="cancel" onClick={props.toggleProdDisplay}>
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProduct;
