import Form from "../../../assets/Form";
import Messages from "../../../abstracts/Messages";

const ProductOpts = (props) => {
  // On change function
  const onChange = (e) => {
    const { value } = e.target;
    props.setOpts(value);
  };

  return (
    <Form>
      <div className="heading">
        <h1>Step 3 (optional)</h1>
      </div>

      <Messages success="Format: type=value1,value2,value3" />

      <input
        type="text"
        className="formInput"
        name="custom"
        placeholder="Custom options (e.g sizes=2L,5L colors=red,blue)"
        value={props.opts}
        onChange={onChange}
      />

      <p style={{ color: "#bbb" }}>
        Make sure there's no spaces between commas!
      </p>
      <button
        type="submit"
        className="submit-button dry"
        onClick={(e) => {
          e.preventDefault();
          props.back();
        }}
      >
        Back
      </button>
      <button
        type="submit"
        className="submit-button"
        onClick={(e) => {
          e.preventDefault();
          props.forward();
        }}
        style={{ marginTop: "10px" }}
      >
        Next
      </button>
    </Form>
  );
};

export default ProductOpts;
