import { useEffect, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import axios from "axios";
import Messages from "../../abstracts/Messages";
import Popup from "../../assets/Popup";
import USER from "../../redux/actions/User";
import Functions from "../../abstracts/Functions";

const BuyerEditAcc = ({ history }) => {
  // Redux
  const dispatch = useDispatch();
  const flash = useSelector((state) => state.flash);
  const user = useSelector((state) => state.user);

  Functions.pageInfo({
    title: "Ordapple | Manage Your Buyer Account",
    desc: "Manage your user account",
  });

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  // Messages
  const [msg, setMsg] = useState({});

  // States
  const [emailVal, setEmailVal] = useState(user.email);
  const [firstNameVal, setFirstNameVal] = useState(user.firstName);
  const [lastNameVal, setLastNameVal] = useState(user.lastName);
  const [phoneVal, setPhoneVal] = useState(user.phone);
  const [display, setDisplay] = useState(true);

  // Refs
  const email = createRef();
  const firstName = createRef();
  const lastName = createRef();
  const phone = createRef();

  // Regex
  const phoneRegx = /^[0-1][6-8]\d{8}$/g;
  const emailRegx = /@/i;

  // On change events
  const onChange = (e) => {
    const { value, name, style } = e.target;

    if (name === "firstName") {
      setFirstNameVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "lastName") {
      setLastNameVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "email") {
      const emailVal = value.toLowerCase();
      setEmailVal(emailVal);
      if (!emailRegx.test(value)) {
        setMsg({ email: "Must include '@'" });
        style.border = "3px solid red";
      } else {
        setMsg({ email: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "phone") {
      setPhoneVal(value);
      if (value.length !== 10) {
        style.border = "3px solid red";
        setMsg({ phone: "Must be 10 digits" });
      } else {
        setMsg({ phone: "" });
        style.border = "3px solid rgb(0, 200, 0)";
      }
    }
  };

  // Edit account function
  const edit_account = (e) => {
    if (firstName.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ first: "Required field!" });
    } else if (lastName.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ last: "Required field!" });
    } else if (email.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ email: "Required field!" });
    } else if (phone.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ phone: "Required field!" });
    } else if (!emailRegx.test(email.current.value)) {
      e.preventDefault();
      setMsg({ email: "Must include '@'" });
    } else if (!phoneRegx.test(phone.current.value)) {
      e.preventDefault();
      setMsg({ phone: "Invalid phone number" });
    } else {
      setDisplay(true);
      e.preventDefault();

      // Loading animation on submit button
      e.target.classList.add("btn-animate");
      e.target.innerText = "Processing...";

      const firstName = firstNameVal;
      const lastName = lastNameVal;
      const email = emailVal;
      const phone = phoneVal;

      axios({
        url: `${Functions.getAPI()}/buyer/update`,
        method: "POST",
        withCredentials: true,
        data: {
          firstName,
          lastName,
          email,
          phone,
        },
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
          dispatch(USER.EDIT_ACCOUNT({ firstName, lastName, email, phone }));
        })
        .catch((err) => {
          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          const res = err.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });

      setMsg({ email: "", pass: "" });
    }
  };

  return (
    <>
      {flash.err !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          err={flash.success}
          history={history}
        />
      )}
      {flash.warn !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          warn={flash.warn}
          history={history}
        />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
          history={history}
        />
      )}
      <div className="register-login lgoin">
        <div className="form">
          <div className="heading">
            <h1>Edit account</h1>
          </div>

          {/* Falsh messages */}
          {flash.err !== "" && <Messages err={flash.err} />}

          <form className="form-slide" action="/buyer/update" method="POST">
            <input
              type="text"
              className="formInput"
              name="firstName"
              id="firstName"
              placeholder="First name"
              value={firstNameVal}
              onChange={onChange}
              ref={firstName}
            />
            <p style={{ color: "red" }} id="firstName-error">
              {msg.firstName}
            </p>

            <input
              type="text"
              className="formInput"
              name="lastName"
              id="lastName"
              placeholder="Last name"
              value={lastNameVal}
              onChange={onChange}
              ref={lastName}
            />
            <p style={{ color: "red" }} id="lastName-error">
              {msg.lastName}
            </p>

            <input
              type="text"
              className="formInput"
              name="email"
              id="email"
              placeholder="E-mail"
              value={emailVal}
              onChange={onChange}
              ref={email}
            />
            <p style={{ color: "red" }} id="email-error">
              {msg.email}
            </p>

            <input
              type="text"
              className="formInput"
              name="phone"
              id="phone"
              placeholder="Phone"
              value={phoneVal}
              onChange={onChange}
              ref={phone}
            />
            <p style={{ color: "red" }} id="phone-error">
              {msg.phone}
            </p>

            <button
              type="submit"
              className="submit-button"
              onClick={edit_account}
            >
              Submit
            </button>
            <br />
            <Link to="/delete-account/">Delete my account</Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default BuyerEditAcc;
