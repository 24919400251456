import images from "./Images";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FLASH from "../redux/actions/Flash";
import BuyerActions from "../redux/actions/BuyerActions";
import { createRef } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Functions from "./Functions";

const MobileNav = ({ toggleSearch, toggleMenu, menu, search }) => {
  // History and location
  const history = useHistory();
  const path = useLocation().pathname;

  // States
  const [val, setVal] = useState("");

  // Redux
  const dispatch = useDispatch();
  const viewed_shop = useSelector((state) => state.viewed_shop);

  // Use effect
  useEffect(() => {
    dispatch(FLASH({ err: "", success: "", warn: "" }));
  }, [dispatch]);

  // Refs
  const searchField = createRef();

  // On change event
  const onchange = (e) => {
    const { value } = e.target;
    setVal(value);
  };

  // Functions to run when submitting

  const searchShops = (e) => {
    if (searchField.current.value.trim() === "") {
      e.preventDefault();
    } else {
      e.preventDefault();
      const q = val;

      axios({
        url: `${Functions.getAPI()}/shops/search?q=${q}`,
        method: "GET",
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Dispatching
          dispatch(BuyerActions.SEARCHED_SHOPS(data));

          // Redirecting the user
          history.push(`/shops/search?q=${q}`);
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(e);
          }
        });
    }
  };

  const searchProducts = (e) => {
    if (searchField.current.value.trim() === "") {
      e.preventDefault();
    } else {
      e.preventDefault();
      const q = val;

      axios({
        url: `${Functions.getAPI()}/products/search?q=${q}`,
        method: "GET",
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Dispatching
          dispatch(BuyerActions.SEARCHED_PRODUCTS(data.products));

          // Redirecting the user
          if (
            path === "/shop" ||
            path === "/products/search" ||
            path === "/" ||
            path.match("/category") ||
            path.match("/department")
          ) {
            history.push(`/products/search?q=${q}`);
          } else {
            history.push(`/${viewed_shop._id}/products-results?q=${q}`);
          }
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(e);
          }
        });
    }
  };

  // Check path and return appropriate logic
  const checkPath = (todo) => {
    if (todo === "ph") {
      if (path === "/shops" || path === "/shops/search") {
        return "Search location";
      } else {
        return "Search items, categories or departments";
      }
    } else if (todo === "event") {
      if (path === "/shops" || path === "/shops/search") {
        return searchShops;
      } else {
        return searchProducts;
      }
    }
  };
  return (
    // Mobile navigation
    <div className="mobile-nav">
      <div className="ham-nav">
        <div className={menu ? "closeNav" : "openNav"} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>

      <div className="logo">
        <Link to="/">
          <img src={images.logos.logo512} alt="logo" />
        </Link>
      </div>

      {path === "/shops" ||
      path === "/shop" ||
      path === "/products/search" ||
      path === "/shops/search" ||
      path === "/" ||
      path.match("/category") ||
      path.match("/department") ||
      path === `/shop/${viewed_shop._id}` ||
      path === `/${viewed_shop._id}/products-results` ? (
        <div className="search">
          <button className="open-search" onClick={toggleSearch}>
            <i className="fas fa-search"></i>
          </button>
        </div>
      ) : (
        <div className="search">
          <button
            className="open-search"
            style={{ pointerEvents: "none", color: "#ddd" }}
          >
            <i className="fas fa-search"></i>
          </button>
        </div>
      )}

      <div
        className="search-form"
        style={search ? { display: "flex" } : { display: "none" }}
      >
        <button className="close-search" onClick={toggleSearch}>
          X
        </button>
        <form action="/shopIDHere/products-results" method="GET">
          <input
            type="text"
            name="q"
            id="searchInput"
            placeholder={checkPath("ph")}
            ref={searchField}
            onChange={onchange}
            value={val}
          />
          <button type="submit" onClick={checkPath("event")}>
            <i className="fas fa-arrow-right"></i>
          </button>
        </form>
      </div>
    </div>
  );
};

export default MobileNav;
