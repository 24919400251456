import ProtectedRoute from "../ProtectedRoute";
import Mail from "../job/Mail";
import Users from "../job/Users";
import Stats from "../job/Stats";
import Cashouts from "../job/Cashouts";
import AgentStats from "../job/AgentStats";

const Job = [
  {
    routeType: ProtectedRoute,
    path: "/employee/mail",
    exact: true,
    component: Mail,
  },
  {
    routeType: ProtectedRoute,
    path: "/employee/users",
    exact: true,
    component: Users,
  },
  {
    routeType: ProtectedRoute,
    path: "/employee/stats",
    exact: true,
    component: Stats,
  },
  {
    routeType: ProtectedRoute,
    path: "/employee/agents",
    exact: true,
    component: AgentStats,
  },
  {
    routeType: ProtectedRoute,
    path: "/employee/cashouts",
    exact: true,
    component: Cashouts,
  },
];

export default Job;
