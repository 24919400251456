import { createRef } from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import axios from "axios";
import Popup from "../../assets/Popup";
import Functions from "../../abstracts/Functions";

const ActivateAcc = ({ history }) => {
  // Redux
  const dispatch = useDispatch();
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Activate Your Account",
    desc: "Activate Your Account",
  });

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  // Messages
  const [msg, setMsg] = useState({});

  // States
  const [tokenVal, setEmailVal] = useState("");
  const [display, setDisplay] = useState(true);

  // Refs
  const token = createRef();

  // On change events
  const tokenOnchange = (e) => {
    const { value, style } = e.target;
    setEmailVal(value);
    if (value === "") {
      setMsg({ token: "Required firld!" });
      style.border = "3px solid red";
    } else {
      setMsg({ token: "" });
      style.border = "3px solid rgb(0, 200, 0)";
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (token.current.value.trim() === "") {
      token.current.style.border = "3px solid red";
      setMsg({ token: "Required field!" });
    } else {
      const token = tokenVal;

      // Loading animation on submit button
      e.target.classList.add("btn-animate");
      e.target.innerText = "Processing...";

      axios({
        url: `${Functions.getAPI()}/account/verify`,
        method: "POST",
        data: {
          token,
        },
      })
        .then(async (res) => {
          setMsg({ email: "", pass: "" });

          // Waiting for the data before proceeding
          const data = await res.data;

          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        })
        .catch((err) => {
          const res = err.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });
    }
  };
  return (
    <div className="register-login login">
      <div className="form register-form">
        <div className="heading">
          <h1>Verify account</h1>
        </div>

        {flash.err !== "" && (
          <div className="msg">
            <div className="error-msg">
              <p> {flash.err} </p>
            </div>
          </div>
        )}
        {flash.warn !== "" && (
          <div className="msg">
            <div className="warning-msg">
              <p> {flash.warn} </p>
            </div>
          </div>
        )}
        {flash.success !== "" && (
          <Popup
            display={display}
            setDisplay={setDisplay}
            success={flash.success}
            history={history}
            redirect="/login"
          />
        )}

        <form className="form-slide" method="POST">
          <input
            type="text"
            className="formInput"
            name="token"
            id="token"
            placeholder="Enter token"
            value={tokenVal}
            onChange={tokenOnchange}
            ref={token}
          />
          <p style={{ color: "red" }} id="token-error">
            {msg.token}
          </p>

          <br />
          <button
            type="submit"
            className="submit-button"
            onClick={submitHandler}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ActivateAcc;
