const HOME_DATA = (data) => {
  return {
    type: "HOME_DATA",
    payload: data,
  };
};

const HomeActions = {
  HOME_DATA,
};

export default HomeActions;
