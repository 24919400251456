import ProdCard from "../../assets/ProdCard";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BuyerActions from "../../redux/actions/BuyerActions";
import axios from "axios";
import Loader from "../../abstracts/Loader";
import { RESTORE_FLASH } from "../../redux/actions/Flash";
import Popup from "../../assets/Popup";
import Functions from "../../abstracts/Functions";

const ShopItems = () => {
  const [pagination, setPagination] = useState({
    next: {},
    previous: {},
    numOfPages: 0,
  });

  // get state
  const flash = useSelector((state) => state.flash);
  const shop_products = useSelector((state) => state.shop_products);
  const viewed_shop = useSelector((state) => state.viewed_shop);

  Functions.pageInfo({
    title: `Ordapple | Shop Items - ${viewed_shop.name}`,
    desc: "Viewing searched items",
  });

  // Dispatch
  const dispatch = useDispatch();

  // Use state
  const [display, setDisplay] = useState(false);

  // Params
  const shopID = useParams().id;

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
    dispatch(BuyerActions.REMOVE_SHOP_PRODUCTS());
    // Fetch data
    axios({
      url: `${Functions.getAPI()}/shop/${shopID}`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;
        // If status is 200, let the user login
        if (res.status === 200) {
          // Dispatching
          setPagination({
            next: data.response.next,
            previous: data.response.previous,
            numOfPages: data.response.numOfPages,
          });
          dispatch(BuyerActions.SHOP_PRODUCTS(data.response.data));
          dispatch(BuyerActions.VIEWED_SHOP(data.shop));
        }
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  }, [dispatch, shopID]);

  // Going to the next page or previous page
  const paginate = (e, limit, page) => {
    e.preventDefault();

    axios({
      url: `${Functions.getAPI()}/shop/${shopID}?limit=${limit}&page=${page}`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;
        // If status is 200, let the user login
        if (res.status === 200) {
          // Dispatching
          dispatch(BuyerActions.SHOP_PRODUCTS(data.response));
          dispatch(BuyerActions.VIEWED_SHOP(data.shop));
        }
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  };

  const renderProdCard = () => {
    if (shop_products.length === 0) {
      return (
        <div className="heading" style={{ marginTop: "-20px", width: "100%" }}>
          <h1 style={{ color: "#bbb" }}>There's nothing here!</h1>
        </div>
      );
    } else {
      return shop_products.map((product, ind) => (
        <ProdCard
          key={ind}
          page="shopItems"
          setDisplay={setDisplay}
          shopName={viewed_shop.name}
          img={product.image.Location}
          productName={product.name}
          productCurrency={product.currency}
          productPrice={product.price}
          productId={product._id}
          shopId={shopID}
          shopOwner={viewed_shop.owner}
          shopVerified={viewed_shop.verified}
          likes={product.likes}
        />
      ));
    }
  };

  const productsRender = () => {
    if (Object.keys(pagination).length > 0) {
      //  Products
      return "page" in pagination.previous || "page" in pagination.next ? (
        <>
          <div className="prod-card-container pagination">
            {/* Use the page in the ProdCard component */}
            {renderProdCard()}
          </div>
          {/*  Previous page */}
          <div className="pageForms">
            {"page" in pagination.previous && (
              <form action={`/shop/${shopID}`} className="pageForm">
                <input
                  type="hidden"
                  name="page"
                  value={pagination.previous.page}
                  id="previous"
                />
                <input type="hidden" name="limit" value="24" id="limit" />
                <button
                  type="submit"
                  className="back"
                  onClick={(e) => {
                    paginate(e, 12, pagination.previous.page);
                  }}
                >
                  <i className="fas fa-chevron-left back"></i> Back
                </button>
              </form>
            )}

            {/* Next page  */}
            {"page" in pagination.next && (
              <form action={`/shop/${shopID}`} className="pageForm">
                <input
                  type="hidden"
                  name="page"
                  value={pagination.next.page}
                  id="next"
                />
                <input type="hidden" name="limit" value="24" id="limit" />
                <button
                  type="submit"
                  className="next"
                  onClick={(e) => {
                    paginate(e, 12, pagination.next.page);
                  }}
                >
                  Next <i className="fas fa-chevron-right next"></i>
                </button>
              </form>
            )}
          </div>{" "}
        </>
      ) : (
        <>
          {shop_products.length === 0 ? (
            <div className="heading">
              <h1 style={{ color: "#bbb" }}>No Items found!</h1>
            </div>
          ) : (
            <div className="prod-card-container pagination">
              {/* Use the page in the ProdCard component */}
              {renderProdCard()}
            </div>
          )}
        </>
      );
    } else {
      return (
        <div
          className="prod-card-container pagination"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      );
    }
  };

  return (
    <div className="shopname">
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      <div className="shopname-container">
        <div className="shop">
          <div className="opacity">
            <h1>Shop items</h1>
            <p>
              <i className="fas fa-store"></i> {viewed_shop.name}
            </p>
          </div>
        </div>

        <div className="products-container">{productsRender()}</div>

        {/* About shop */}
        <div className="shop-description">
          <h3>About shop:</h3>
          <p>{viewed_shop.about}</p>
        </div>
      </div>
    </div>
  );
};

export default ShopItems;
