import { Route } from "react-router";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ForgotPass from "../auth/ForgotPass";
import ResetPass from "../auth/ResetPass";

const Auth = [
  {
    routeType: Route,
    path: "/login",
    exact: true,
    component: Login,
  },
  {
    routeType: Route,
    path: "/buyer-login",
    exact: true,
    component: Login,
  },
  {
    routeType: Route,
    path: "/register",
    exact: true,
    component: Register,
  },
  {
    routeType: Route,
    path: "/forgot-password",
    exact: true,
    component: ForgotPass,
  },
  {
    routeType: Route,
    path: "/reset-password/:userId/:token",
    exact: true,
    component: ResetPass,
  },
];

export default Auth;
