import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// For redux
import Store from "./redux/Store";

// For redux persist
// import { PersistGate } from "redux-persist/integration/react";
// import configureStore from "./redux/configureStore";
// import PreLoader from "./assets/PreLoader";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>

    {/* Redux Persist */}
    {/* <Provider store={configureStore().store}>
      <PersistGate
        loading={<PreLoader />}
        persistor={configureStore().persistor}
      >
        <App />
      </PersistGate>
    </Provider> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    alert("New version available!  Ready to update?");
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
