import { combineReducers } from "redux";
import Loading from "./Loading";
import Buyer from "./Buyer.js";
import Auth from "./Auth.js";
import Counter from "./Counter";
import USER from "./User.js";
import Flash from "./Flash.js";
import Admin from "./Admin";
import Employee from "./Employee";
import HOME_DATA from "./HomeData.js";
import Inbox from "./Inbox";

const AllReducers = combineReducers({
  counter: Counter,
  loading: Loading,
  auth: Auth,
  shops: Buyer.SHOPS,
  viewed_shop: Buyer.VIEWED_SHOP,
  searched_shops: Buyer.SEARCHED_SHOPS,
  shop_products: Buyer.SHOP_PRODUCTS,
  searched_products: Buyer.SEARCHED_PRODUCTS,
  product_preview: Buyer.PRODUCT_PREVIEW,
  cart: Buyer.CART,
  buyer_account: Buyer.BUYER_ACCOUNT,
  buyer_orders: Buyer.ORDERS_REDUCER,
  inbox: Inbox.INBOX,
  unseen_inbox: Inbox.UNSEEN_INBOX,
  user: USER,
  home_data: HOME_DATA,
  flash: Flash,
  admin_info: Admin.ADMIN_INFO,
  admin_products: Admin.PRODUCTS,
  admin_orders: Admin.ORDERS_REDUCER,
  employee_users: Employee.USERS,
  employee_stats: Employee.STATS,
});

export default AllReducers;
