import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Functions from "../../abstracts/Functions";
import InboxCard from "../../assets/InboxCard";
import InboxActions from "../../redux/actions/Inbox";
import axios from "axios";

const Inbox = ({ history }) => {
  // use states
  // const [inboxFilter, setInboxFilter] = useState("all");

  // Redux state and dispatch
  const dispatch = useDispatch();

  const inbox = useSelector((state) => state.inbox);
  const user = useSelector((state) => state.user);

  Functions.pageInfo({
    title: "User's Inbox",
    desc: "A User's Inbox",
  });

  // Set the user's inbox to seen
  // and get user's inbox
  useEffect(() => {
    axios({
      url: `${Functions.getAPI()}/inbox/status`,
      method: "POST",
      withCredentials: true,
      data: { seenInbox: true, owner: user._id },
    })
      .then(async (res) => {
        const data = await res.data;

        // If status is 200, let the user login
        if (res.status === 200) {
          // Dispatching
          dispatch(InboxActions.INBOX(data.inbox));
        }
      })
      .catch((e) => {
        const res = e.response;
        if (res) console.log(res.data);
        else console.log(e);
      });
  }, []);

  // Filtering the inboxes
  // useEffect(() => {
  //   if ("inboxes" in inbox) {
  //     if (inbox.inboxes.length >= 1) {
  //       // seen
  //       if (inboxFilter === "seen") {
  //         const updatedInbox = { ...inbox };
  //         updatedInbox["inboxes"] = updatedInbox.inboxes
  //           .filter((i) => {
  //             if (i.seen) {
  //               return true;
  //             } else {
  //               return false;
  //             }
  //           })
  //           .map((i) => i);
  //         InboxActions.INBOX(inbox.inboxes.filter((i) => i.seen));
  //       } else if (inboxFilter === "unseen") {
  //         // unseen
  //         const updatedInbox = { ...inbox };
  //         updatedInbox["inboxes"] = updatedInbox.inboxes
  //           .filter((i) => {
  //             if (!i.seen) {
  //               return true;
  //             } else {
  //               return false;
  //             }
  //           })
  //           .map((i) => i);
  //         InboxActions.INBOX(inbox.inboxes.filter((i) => !i.seen));
  //       } else {
  //         dispatch(InboxActions.INBOX(inbox));
  //       }
  //     }
  //   }
  // }, [inboxFilter]);

  // Set inner inbox seen status (i.e) when a user
  // has clicked on a list card
  const setSeenStatus = async (data) => {
    return axios({
      url: `${Functions.getAPI()}/inbox/status/${data._id}`,
      method: "POST",
      withCredentials: true,
      data,
    })
      .then(async (res) => {
        const data = await res.data;

        // If status is 200, let the user login
        if (res.status === 200) {
          console.log(data);
        }
      })
      .catch((e) => {
        const res = e.response;
        if (res) console.log(res.data);
        else console.log(e);
      });
  };

  // Filtering inboxes by seen or unseen
  // const filter = (e) => {
  //   const { value } = e.target;
  //   setInboxFilter(value);
  // };

  const deleteInbox = () => {};
  // const deleteAllInboxes = () => {};

  // Render all user's inboxes
  const renderInboxes = () => {
    if ("inboxes" in inbox) {
      if (inbox.inboxes.length >= 1) {
        return inbox.inboxes.reverse().map((inbox, ind) => {
          return (
            <InboxCard
              key={ind}
              msg={inbox.msg}
              username={inbox.username}
              from={inbox.from}
              time={inbox.time}
              _id={inbox._id}
              url={inbox.url}
              setSeenStatus={setSeenStatus}
              seen={inbox.seen}
              deleteInbox={deleteInbox}
            />
          );
        });
      } else {
        return (
          <p className="not-found" style={{ margin: "10px" }}>
            No inboxes found!
          </p>
        );
      }
    } else {
      return (
        <p className="not-found" style={{ margin: "10px" }}>
          No inboxes found!
        </p>
      );
    }
  };

  return (
    <div className="account">
      <div className="btn-holder">
        <h2>Inbox</h2>

        {/* Filter users */}
        {/* <select name="filter" id="filter" value={inboxFilter} onChange={filter}>
          <option value="all">All</option>
          <option value="seen">Seen</option>
          <option value="unseen">Unseen</option>
        </select> */}

        {/* Delete button */}
        {/* <button className="delete-all" type="submit" onClick={deleteAllInboxes}>
          Delete All
        </button> */}
      </div>

      <div className="cards-container">{renderInboxes()}</div>
    </div>
  );
};
export default Inbox;
