import { Link } from "react-router-dom";
import Hero from "./Hero";

const Welcome = () => {
  return (
    <div className="welcome">
      <Hero />
      <Link to="/categories" className="link-to-shops"></Link>
    </div>
  );
};

export default Welcome;
