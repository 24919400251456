import { Link } from "react-router-dom";
const HelpData = [
  {
    head: "Have a question?",
    body: (
      <p>
        All questions can be submitted to our email at
        <Link to="mailto:support@ordapple.com">support@ordapple.com</Link>, then
        we will get back to you with the legit answer to your question and if
        other people ask the same question, then we will also update the FAQ
        page with the answer to that question.
      </p>
    ),
  },
  {
    head: "How to see my orders?",
    body: (
      <p>
        If you are using a mobile Phone just press the hamburger menu located on
        top-left (three lines), then a pop up menu will be shown to you, then on
        the pop up menu click "Orders", then you will be taken to orders page
        where you will see all your order history. If you are using a Tablet or
        a Desktop PC, click a link in the navigation on the top name "Orders".
        Or click this
        <Link to="/orders/userID">link</Link> to see your orders.
      </p>
    ),
  },
  {
    head: "How to become an Admin and start selling?",
    body: (
      <p>
        Being an admin is fairly easy, all you need to do is fill in our online
        form to apply.
        <Link to="/admin/apply/free">Apply now</Link> to get started.
      </p>
    ),
  },
];
export default HelpData;
