import AdminNav from "./assets/AdminNav";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import AdminActions from "../../redux/actions/AdminActions";
import axios from "axios";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import Popup from "../../assets/Popup";
import Functions from "../../abstracts/Functions";
import { useEffect } from "react";
import Transaction from "./assets/Transaction";

// Fee tile
const FeeTile = (prop) => {
  return (
    <div className="tile">
      <h3>{prop.title}</h3>
      <p>{prop.fee}</p>
    </div>
  );
};

const AdminStats = () => {
  // Getting values from redux state
  const user = useSelector((state) => state.user);
  const orders = useSelector((state) => state.admin_orders);
  const admin_info = useSelector((state) => state.admin_info);
  const flash = useSelector((state) => state.flash);

  //   // Use state
  //   const [filterOrders, setFilterOrders] = useState("all");
  const [display, setDisplay] = useState(false);
  const [balance, setBalance] = useState(0);
  const [transactionFees, setTransactionFees] = useState([]);

  Functions.pageInfo({
    title: "Ordapple | Manage Your Admin Transactions",
    desc: "Managing Your Admin Transactions",
  });

  //   // Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RESTORE_FLASH());

    axios({
      url: `${Functions.getAPI()}/admin/${user._id}`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        dispatch(AdminActions.ORDERS(data.shop.orders.reverse()));
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(e);
        }
      });
  }, [dispatch, user._id]);

  // Request the transaction fees
  const getTransactionFees = () => {
    // Orders paid for
    const paidOrders = orders.filter((i) => i.status !== "pending payment");
    const transNums = paidOrders.map((i) => {
      return `${i.orderNumber}`;
    });

    // Request
    axios({
      url: `${Functions.getAPI()}/transaction/fees`,
      method: "POST",
      withCredentials: true,
      data: { trans: transNums }, // transNums is order numbers
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        setTransactionFees(data.data);
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(e);
        }
      });
  };

  // Use effect for rendering balance
  useEffect(() => {
    getTransactionFees();

    // Getting the balance after deductions
    const avail = Functions.transactionData(orders, transactionFees).map(
      (i) => {
        return Number(i.available);
      }
    );
    let balance;
    if (avail.length >= 1) {
      balance = avail.reduce((sum, price) => (sum += price));
    }

    // Checking if the balance is not undefined which means there are orders
    // which have status as PENDING DELIVERY OR COMPLETE
    if (balance) {
      setBalance(balance);
    } else {
      setBalance(0);
    }
  }, [transactionFees]);

  // Request cashout function
  const requestCashout = (e, amount) => {
    e.preventDefault();

    // Remove the button from the screen
    e.target.style.display = "none";
    setDisplay(false);

    // admin data
    const data = {
      name: admin_info.name,
      _id: admin_info._id,
      amount,
    };

    // Request
    axios({
      url: `${Functions.getAPI()}/cashout`,
      method: "POST",
      withCredentials: true,
      data,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        setDisplay(true);
        dispatch(FLASH({ err: "", success: data.success, warn: "" }));
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          setDisplay(true);
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(e);
        }
      });
  };

  // Get transactions
  const transactions = () => {
    return Functions.transactionData(orders, transactionFees).map((i, ind) => (
      <Transaction
        key={ind}
        transNum={i.transNum}
        total={i.total}
        deducted={i.deducted}
        available={i.available}
        dateOrdered={i.dateOrdered}
        items={i.items}
      />
    ));
  };

  // Payment method fees
  const paymentFees = [
    {
      title: "Credit card",
      fee: "3.5% plus R2.00",
    },
    {
      title: "Instant EFT",
      fee: "2.0% (minimum R2.00)",
    },
    {
      title: "MoreTyme",
      fee: "5.5% plus R2.00",
    },
    {
      title: "Masterpass",
      fee: "3.5% plus R2.00",
    },
    {
      title: "Zapper",
      fee: "3.5% plus R2.00",
    },
    {
      title: "Debit card",
      fee: "3.5% plus R2.00",
    },
    {
      title: "Mobi cred",
      fee: "3.5%",
    },
    {
      title: "Scode",
      fee: "4.5%",
    },
  ];

  return (
    <div>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      <AdminNav page="account" />
      <div className="account">
        <div className="account-container">
          <div className="stats">
            <div className="mobile-view">
              {/* <!-- USERS --> */}
              <div className="users-container">
                <span></span>
                <span></span>
                <span></span>
                <span></span>

                <h1>Balance</h1>
                <h2 id="active-users">R{balance}</h2>
                <p>Available in account</p>
              </div>

              {/* Request cashout button */}
              {balance > 0 && (
                <button
                  className="btn"
                  onClick={(e) => requestCashout(e, balance)}
                >
                  REQUEST CASHOUT
                </button>
              )}

              <div className="heading">
                <h2>Transactions:</h2>
              </div>

              {transactions()}

              <div className="heading">
                <h2>Transaction fees:</h2>
              </div>

              <div className="fees-cont">
                <h2>Payment method fees:</h2>
                <div className="tile-cont">
                  {paymentFees.map((i, ind) => (
                    <FeeTile key={ind} title={i.title} fee={i.fee} />
                  ))}
                </div>
                <h2>Agent fees:</h2>
                <div className="tile-cont">
                  <FeeTile title="Less than R500" fee="10% from price" />
                  <FeeTile title="R500 and above" fee="8% from price" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminStats;
