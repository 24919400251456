const CartItem = ({ item, updateCart, removeItem, add, minus }) => {
  const renderImage = (item) => {
    if (item.productImage) {
      if (item.productImage) {
        return <img src={item.productImage} alt="Product" />;
      } else {
        return <p>No image</p>;
      }
    }
  };

  const btnStyles = {
    width: "40px",
    height: "24px",
  };

  return (
    <div className="prod-cart-container" key={item.productId}>
      <div className="prod-image">{renderImage(item)}</div>

      <div className="prod-info">
        <div className="name-price">
          <h1>{item.productName}</h1>
          <p className="price">{`${item.productCurrency} ${item.productPrice}`}</p>
        </div>
        <p className="shopName" style={{ padding: "0px 0px 12px 0px" }}>
          {item.shopName}
        </p>
      </div>

      <div className="prod-edit">
        <form action={`/cart/update/${item.productId}`} method="POST">
          <p>
            Qty:
            <span>{item.qty}</span>
            <button
              style={btnStyles}
              className="qty-btn minus"
              onClick={(e) => {
                updateCart(e, item.productId, minus(item.qty));
              }}
            >
              -
            </button>
            <button
              style={btnStyles}
              className="qty-btn plus"
              onClick={(e) => {
                updateCart(e, item.productId, add(item.qty));
              }}
            >
              +
            </button>
          </p>
        </form>

        <form action={`/cart/${item.productId}`} method="POST">
          <button
            type="submit"
            onClick={(e) => {
              removeItem(e, item.productId);
            }}
          >
            <i className="fas fa-trash"></i> Remove
          </button>
        </form>
      </div>
    </div>
  );
};

export default CartItem;
