import { createRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../assets/Popup";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import JobNav from "./assets/JobNav";
import axios from "axios";
import Functions from "../../abstracts/Functions";
import { Link } from "react-router-dom";

const Mail = ({ history }) => {
  // Redux state
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Send emails to users | For Authorized staff only",
    desc: "Send emails to users. For Authorized staff only",
  });

  // Redux disparch
  const dispatch = useDispatch();

  // Use state
  const [display, setDisplay] = useState(true);
  const [emailToVal, setEmailToVal] = useState("");
  const [subjectVal, setSubjectVal] = useState("");
  const [messageVal, SetMessageVal] = useState("");

  // Messages
  const [msg, setMsg] = useState({});

  // Refs
  const emailTo = createRef();
  const subject = createRef();
  const message = createRef();

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  const onChange = (e) => {
    const { value, name, style } = e.target;

    if (name === "emailTo") {
      setEmailToVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "subject") {
      setSubjectVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "message") {
      SetMessageVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    }
  };

  // Send email
  const send_email = (e) => {
    const emailRegx = /\w@\w./i;
    if (emailTo.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ emailTo: "Required field!" });
    } else if (!emailRegx.test(emailTo.current.value)) {
      e.preventDefault();
      setMsg({ emailTo: "Must include '@'", pass: "" });
    } else if (subject.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ subject: "Required field!" });
    } else if (message.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ message: "Required field!" });
    } else {
      setDisplay(true);
      e.preventDefault();

      // Loading animation on submit button
      e.target.classList.add("btn-animate");
      e.target.innerText = "Processing...";

      const emailTo = emailToVal;
      const subject = subjectVal;
      const message = messageVal;

      const data = { emailTo, subject, message };

      axios({
        method: "POST",
        url: `${Functions.getAPI()}/sendMail`,
        data,
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        })
        .catch((err) => {
          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          const res = err.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });

      setMsg({});
    }
  };

  return (
    <div>
      <JobNav page="mail" />
      <div className="register-login " style={{ marginTop: "40px" }}>
        <div style={{ marginTop: "-30px" }} className="form">
          <div className="heading">
            <h1>Mail form</h1>
          </div>

          {flash.err !== "" && (
            <Popup display={display} setDisplay={setDisplay} err={flash.err} />
          )}
          {flash.success !== "" && (
            <Popup
              display={display}
              setDisplay={setDisplay}
              success={flash.success}
              history={history}
            />
          )}
          {flash.warn !== "" && (
            <Popup
              display={display}
              setDisplay={setDisplay}
              warn={flash.warn}
            />
          )}

          <form className="form-slide" method="POST" action="/sendMail">
            <input
              required
              type="email"
              className="formInput"
              name="emailTo"
              id="emailTo"
              placeholder="Email to"
              value={emailToVal}
              onChange={onChange}
              ref={emailTo}
            />
            <p style={{ color: "red" }} id="emailTo-error">
              {msg.emailTo}
            </p>

            <input
              required
              type="text"
              className="formInput"
              name="subject"
              id="subject"
              placeholder="Subject"
              value={subjectVal}
              onChange={onChange}
              ref={subject}
            />
            <p style={{ color: "red" }} id="subject-error">
              {msg.subject}
            </p>

            <textarea
              required
              style={{ height: "200px" }}
              name="message"
              id="message"
              placeholder="Type message..."
              value={messageVal}
              onChange={onChange}
              ref={message}
            ></textarea>
            <p style={{ color: "red" }} id="message-error">
              {msg.message}
            </p>
            <br />

            <button
              type="submit"
              className="submit-button"
              onClick={send_email}
            >
              Submit
            </button>
            <br />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Mail;
