import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import OrdersCard from "../../assets/OrdersCard";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import BuyerActions from "../../redux/actions/BuyerActions";
import { useState } from "react";
import { useEffect } from "react";
import Popup from "../../assets/Popup";
import Functions from "../../abstracts/Functions";

const BuyerOrders = () => {
  // Getting values from redux state
  const orders = useSelector((state) => state.buyer_orders);
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Your Ordered Items",
    desc: "View Your Ordered Items",
  });

  // Use state
  const [display, setDisplay] = useState(false);
  const [filterOrders, setFilterOrders] = useState("all");
  const [filteredOrders, setFilteredOrders] = useState(orders);

  // Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RESTORE_FLASH());

    if (filterOrders === "all") {
      setFilteredOrders(orders);
    } else if (filterOrders === "pending payment") {
      setFilteredOrders(orders.filter((o) => o.status === "pending payment"));
    } else if (filterOrders === "pending delivery") {
      setFilteredOrders(orders.filter((o) => o.status === "pending delivery"));
    } else if (filterOrders === "complete") {
      setFilteredOrders(orders.filter((o) => o.status === "complete"));
    }
  }, [dispatch, orders, filterOrders]);

  // DELETING ALL ORDERS
  const deleteAllOrders = (e) => {
    e.preventDefault();
    setDisplay(true);

    axios({
      url: `${Functions.getAPI()}/orders/delete`,
      method: "POST",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        dispatch(FLASH({ success: data.success, warn: "", err: "" }));
        dispatch(BuyerActions.BUYER_REMOVE_ALL_ORDERS());
      })
      .catch((err) => {
        const res = err.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(err);
        }
      });
  };

  const filter = (e) => {
    const { value } = e.target;
    setFilterOrders(value);
  };

  return (
    <div className="orders ">
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}

      <div className="orders-card-container">
        {/* Delete all orders */}
        <form
          className="delete-all-form"
          action="/buyer/delete-orders"
          method="POST"
        >
          <select
            name="filter"
            id="filter"
            value={filterOrders}
            onChange={filter}
          >
            <option value="all"> All</option>
            <option value="pending payment">Pending payment</option>
            <option value="pending delivery">Pending delivery</option>
            <option value="complete">Complete</option>
          </select>
          <button
            className="delete-all"
            type="submit"
            onClick={deleteAllOrders}
          >
            Delete All
          </button>
        </form>

        {/* Note */}
        <div className="note">
          <p>Do not delete orders before they are delivered!</p>
        </div>

        {/* Orders */}
        {filteredOrders.length >= 1 ? (
          filteredOrders
            .reverse()
            .map((order, ind) => (
              <OrdersCard
                key={ind}
                buyer={true}
                display={display}
                setDisplay={setDisplay}
                dispatch={dispatch}
                orderNumber={order.orderNumber}
                dateOrdered={order.dateOrdered}
                itemsInOrder={order.itemsInOrder}
                customerName={order.customerName}
                customerEmail={order.customerEmail}
                customerPhone={order.customerPhone}
                customerAddress={order.customerAddress}
                pickUpTime={order.pickUpTime}
                elegibleForDelivery={order.elegibleForDelivery}
                totalPrice={order.totalPrice}
                status={order.status}
                agentCode={order.agentCode}
              />
            ))
        ) : (
          <h2 className="no-orders">No orders available!</h2>
        )}
      </div>
    </div>
  );
};

export default BuyerOrders;
