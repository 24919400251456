import { Link } from "react-router-dom";
import images from "../abstracts/Images";
import Styles from "../abstracts/Styles";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import FLASH from "../redux/actions/Flash";
import Functions from "../abstracts/Functions";
import User from "../redux/actions/User";
import BuyerActions from "../redux/actions/BuyerActions";
import { SetStatus, NewInbox } from "./Requests";

const ShopCard = ({
  page,
  shopId,
  owner,
  shopName,
  shopVerified,
  aboutShop,
  shopLocation,
  workingHours,
  visits,
  followers,
  img,
  setDisplay,
}) => {
  // Using state values from store
  const user = useSelector((state) => state.user);

  // Dispatch
  const dispatch = useDispatch();

  // Card styles depending on the page currently at
  const cardStyles = () => {
    if (page === "home") {
      return Styles.embla__slide;
    } else if (page === "shops" || page === "shopResults") {
      return { marginTop: "15px" };
    } else {
      return { width: "100%" };
    }
  };

  // FOLLOW AND UNFOLLOW

  const followShop = (e, shopID) => {
    dispatch(FLASH({ err: "", success: "", warn: "" }));
    setDisplay(true);

    e.preventDefault();

    axios({
      url: `${Functions.getAPI()}/follow/${shopID}`,
      method: "POST",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        if (user._id !== owner) {
          await NewInbox(
            {
              text: "Followed your shop",
              owner: owner,
              sender: user._id,
              username: `${user.firstName}`,
              url: `/shop/${shopId}`,
            },
            async () => {
              await SetStatus(owner);
            }
          );
        }

        Functions.sendNotif({
          _id: shopID,
          body: `${user.firstName} followed your shop!`,
          url: "https://www.ordapple.com/admin/account",
          fetchUrl: `${Functions.getAPI()}/sendNotif`,
        });
        dispatch(FLASH({ err: "", success: data.success, warn: "" }));

        // DISPATCH
        dispatch(User.FOLLOW_SHOP(shopID));
      })
      .catch((err) => {
        const res = err.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(err);
        }
      });
  };

  const unfollowShop = (e, shopID) => {
    dispatch(FLASH({ err: "", success: "", warn: "" }));
    setDisplay(true);

    e.preventDefault();

    axios({
      url: `${Functions.getAPI()}/unfollow/${shopID}`,
      method: "POST",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // DISPATCH
        dispatch(User.UNFOLLOW_SHOP(shopID));
        dispatch(BuyerActions.UNFOLLOW(shopID));
        dispatch(FLASH({ err: "", success: data.success, warn: "" }));
      })
      .catch((err) => {
        const res = err.response;
        console.log(res);
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(err);
        }
      });
  };

  // RENDER FUNCTIONS

  const renderImage = (img) => {
    return img ? (
      <img src={img} alt="adminImage" loading="lazy" />
    ) : (
      <img loading="lazy" src={images.avatar} alt="Avatar" />
    );
  };

  // Follow and unfollow buttons
  const renderButton = (shopId) => {
    if ("following" in user) {
      return user.following.includes(shopId) ? (
        <form action={`/unfollow/${shopId}`} method="POST">
          <button
            className="unfollow"
            type="submit"
            onClick={(e) => {
              unfollowShop(e, shopId);
            }}
          >
            Unfollow shop
          </button>
        </form>
      ) : (
        <form action={`/follow/${shopId}`} method="POST">
          <button
            type="submit"
            onClick={(e) => {
              followShop(e, shopId);
            }}
          >
            Follow shop
          </button>
        </form>
      );
    } else {
      return (
        <form action={`/follow/${shopId}`} method="POST">
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              setDisplay(true);
              dispatch(
                FLASH({ err: "", success: "", warn: "You are not logged in!" })
              );
            }}
          >
            Follow shop
          </button>
        </form>
      );
    }
  };

  // Redering shop Card
  const renderShopCard = () => {
    return (
      <Link
        to={`/shop/${shopId}`}
        className={page === "home" ? "shop-card embla__slide" : "shop-card"}
        style={cardStyles()}
      >
        {/* Row 1 (contents) */}
        <div className="col-1">
          {/* Top row */}
          <div className="top-row">
            <div className="img">{renderImage(img)}</div>
            <div className="text">
              <h1>
                {shopName}{" "}
                {shopVerified && <i className="fas fa-check verified"></i>}
              </h1>
              <p className="location">
                <i className="fas fa-map-marker-alt"></i> {shopLocation}
              </p>
            </div>
          </div>

          {/* Bottom row */}
          <div className="bottom-row">
            <p className="aboutShop">{aboutShop}</p>
            {/* If admin.workingHours is true and is not empty string (i.e "") */}
            {workingHours && (
              <div
                className="whs"
                dangerouslySetInnerHTML={{
                  __html: workingHours,
                }}
              ></div>
            )}

            <div className="stats">
              <p className="visits">
                Visits<span className="dot"></span>
                <span className="stat">{visits}</span>
              </p>
              <p>
                Followers<span className="dot"></span>
                <span className="stat">
                  {Functions.numFormatter(followers.length)}
                </span>
              </p>
            </div>
          </div>
        </div>

        {/* Row 2 (buttons) */}
        <div className="col-2">{renderButton(shopId)}</div>
      </Link>
    );
  };

  return <>{renderShopCard()}</>;
};

export default ShopCard;
