const ToggleDisplay = (e, elements = []) => {
  elements.forEach((el, ind) => {
    if (e.target.classList.contains(`${ind + 1}`)) {
      if (el.current.style.display === "none") {
        el.current.style.display = "block";
        if (ind === elements.length - 1) {
          e.target.style.marginBottom = "0px";
        }
      } else {
        el.current.style.display = "none";
        if (ind === elements.length - 1) {
          e.target.style.marginBottom = "50px";
        }
      }
    }
  });
};

export default ToggleDisplay;
