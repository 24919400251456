import TimeAgo from "react-timeago";

const Transaction = ({
  transNum,
  total,
  deducted,
  available,
  dateOrdered,
  items,
}) => {
  return (
    <div className="transact-cont">
      <div className="top-row">
        <p>
          <TimeAgo date={Number(dateOrdered)} />
        </p>
        {/* Delete button */}
        {/* <button>X</button> */}
      </div>
      <div className="transact-no">
        <h2>#{transNum}</h2>
      </div>
      <div className="bottom-row">
        <div className="items">
          {items.map((i, ind) => (
            <div className="item" key={ind}>
              <p>
                {i.name} (x{i.qty})
              </p>
              <span>R{i.price}</span>
            </div>
          ))}
        </div>
        <p>Total: R{total}</p>
        <p>Deducted: R{deducted}</p>
        <h3>Available: R{available}</h3>
      </div>
    </div>
  );
};

export default Transaction;
