import React from "react";

const Content = ({ span, content }) => {
  return (
    <p className="content">
      {content}
      <span>
        {span && " - "}
        {span}
      </span>
    </p>
  );
};

export default Content;
