import ProtectedRoute from "../ProtectedRoute";
import Shops from "../shop/Shops";
import ShopResults from "../shop/ShopResults";
import ShopItems from "../shop/ShopItems";
import ItemResults from "../shop/ItemResults";
import ItemPreview from "../shop/ItemPreview";
import Cart from "../shop/Cart";
import OrderInfo from "../shop/OrderInfo";
import Order from "../shop/Order";
import PaymentSuccess from "../shop/PaymentSuccess";
import Explore from "../shop/Explore";
import SearchedProducts from "../shop/SearchedProducts";
import { Route } from "react-router";
import Categories from "../shop/Categories";
import ProductsByCat from "../shop/ProductsByCat";
import Departments from "../shop/Departments";
import ProductsByDep from "../shop/ProductsByDep";
import PaymentCancel from "../shop/PaymentCancel";

const Shop = [
  {
    routeType: ProtectedRoute,
    path: "/shops",
    exact: true,
    component: Shops,
  },
  {
    routeType: Route,
    path: "/shop",
    exact: true,
    component: Explore,
  },
  {
    routeType: ProtectedRoute,
    path: "/shops/search",
    exact: true,
    component: ShopResults,
  },
  {
    routeType: ProtectedRoute,
    path: "/shop/:id",
    exact: true,
    component: ShopItems,
  },
  {
    routeType: Route,
    path: "/products/search",
    exact: true,
    component: SearchedProducts,
  },
  {
    routeType: ProtectedRoute,
    path: "/:shopId/products-results",
    exact: true,
    component: ItemResults,
  },
  {
    routeType: Route,
    path: "/categories",
    exact: true,
    component: Categories,
  },
  {
    routeType: Route,
    path: "/category/:category",
    exact: true,
    component: ProductsByCat,
  },
  {
    routeType: Route,
    path: "/tags",
    exact: true,
    component: Departments,
  },
  {
    routeType: Route,
    path: "/department/:department",
    exact: true,
    component: ProductsByDep,
  },
  {
    routeType: Route,
    path: "/prod/:prodName/:prodID/:shopID",
    exact: true,
    component: ItemPreview,
  },
  {
    routeType: ProtectedRoute,
    path: "/cart",
    exact: true,
    component: Cart,
  },
  {
    routeType: ProtectedRoute,
    path: "/order-info",
    exact: true,
    component: OrderInfo,
  },
  {
    routeType: ProtectedRoute,
    path: "/order/:order_num",
    exact: true,
    component: Order,
  },
  {
    routeType: Route,
    path: "/payment/success",
    exact: true,
    component: PaymentSuccess,
  },
  {
    routeType: Route,
    path: "/payment/cancel",
    exact: true,
    component: PaymentCancel,
  },
];

export default Shop;
