import Pricing from "./Pricing";
import ProdCat from "./ProdCat";
import TopProducts from "./TopProducts";
import TopShops from "./TopShops";

const Content = ({ setDisplay }) => {
  return (
    <div className="content-container">
      <div className="main-container">
        <TopProducts setDisplay={setDisplay} />
        <ProdCat setDisplay={setDisplay} />
        <TopShops setDisplay={setDisplay} />
        <Pricing />
      </div>
    </div>
  );
};

export default Content;
