const ChangeLog = [
  {
    vnum: "1.0.0",
    rd: "29 March 2021",
    vd: [
      "Added a chat feature",
      "Added search functionality",
      "Added admin functionality",
      "Added ordering functionality",
    ],
  },
  {
    vnum: "1.0.1",
    rd: "09 April 2021",
    vd: [
      "Employee app feature",
      "Delete all orders feature",
      "Real-time notification on new orders",
      "Fixed file storaging",
    ],
  },
  {
    vnum: "1.0.2",
    rd: "14 April 2021",
    vd: ["Added contact page", "Added more content on home page"],
  },
  {
    vnum: "1.2.0",
    rd: "16 April 2021",
    vd: [
      "Following, Visits, Views and Like system",
      "Direct applcation for the admin app",
      "Re-design of shops and items layout",
    ],
  },
  {
    vnum: "1.2.1",
    rd: "19 April 2021",
    vd: [
      "Algorithm for top products and top shops on home page",
      "Re-design of products and product preview layout",
      "Added options to choose a category on admin app",
    ],
  },
  {
    vnum: "1.2.2",
    rd: "20 April 2021",
    vd: [
      "Modified the layout of products and shops on the home page and shop items page",
      "Modified the layout of the about page and displaying content when clicking on a heading",
      "Modified styling and added logic on the product preview page to display large image when clicking on the product's image",
      "Modified the the Algorithm for displaying top shops and top products",
    ],
  },
  {
    vnum: "1.2.3",
    rd: "24 April 2021",
    vd: [
      "Re-designed the home page",
      "Re-designed the buyer account page",
      "Removed team, services and mission/vission from the home page",
    ],
  },
  {
    vnum: "1.2.4",
    rd: "28 April 2021",
    vd: [
      "Re-designed the shop cards layout in home page, shops page and buyer's account page",
      "Added a feature for adding working hours on admin's account page",
      "Added a button for displaying the working hours form in admin's account page",
      "Added the algorithm for displaying when a shop closes and when it opens",
      "Re-designed the product preview page to show an icon for zooming in",
      "Modified the algorithm for displaying top shops and top products",
      "Added footer to the about page and removed some links on desktop view",
    ],
  },
  {
    vnum: "1.2.5",
    rd: "30 April 2021",
    vd: [
      "Re-designed the home page, buyer account page, product preview page, admin account page and shop items page",
      "Modified logic for displaying when a shop opens or closes",
      "Added validation when adding working hours",
    ],
  },
  {
    vnum: "1.2.6",
    rd: "06 May 2021",
    vd: ["Modified the chat feature to save messages to the database"],
  },
  {
    vnum: "1.2.7",
    rd: "07 May 2021",
    vd: [
      "Modified the chat feature to only display messages in one appropriate shop",
      "Redesigned the home page",
      "Modified the navigation for the admin application page",
      "Modified the display of users names on the chat feature",
      "Modified logic for displaying if an item is eligible for delivery or not",
      "Modified the algorithm for displaying top shops and top products on the home page",
    ],
  },
  {
    vnum: "1.2.8",
    rd: "10 May 2021",
    vd: [
      "Added google maps for the ordering page",
      "Added the functionality for google maps api to search for location(place) when ordering",
    ],
  },
  {
    vnum: "1.2.9",
    rd: "20 May 2021",
    vd: [
      "Modified the algorithm for getting the tops shops and getting products according to their category on the home page",
    ],
  },
  {
    vnum: "1.3.0",
    rd: "23 May 2021",
    vd: ["Modified styling on the product results page and faq page"],
  },
  {
    vnum: "1.3.1",
    rd: "04 June 2021",
    vd: [
      "Removed inline scripts for better performance",
      "Added logic to get working hours of shops",
      "Added lazy loading for images (for better performance)",
      "Added logic to get format numbers (e.g likes, views etc)",
      "Modified the service worker to serve static files",
    ],
  },
  {
    vnum: "1.3.2",
    rd: "06 June 2021",
    vd: [
      "Updated styling to the product cards and pricing cards",
      "Added animation to the home page banners",
      "Modified the loading spinner",
      "Modified the logo",
      "Resized images (for better performance)",
    ],
  },
  {
    vnum: "1.3.3",
    rd: "08 July 2021",
    vd: [
      "Supports offline usage",
      "Single page application (No page reloads)",
      "Added data storage to retrieve data on reload/in offline mode (i.e. when internet connection is lost)",
    ],
  },
  {
    vnum: "1.3.4",
    rd: "11 July 2021",
    vd: [
      "Added notification capabilities. (Users can now receive relevent notifications from the app)",
      "Fixed the bug for deleting admin orders and buyer orders",
      "Fixed the bug for displaying admin orders and buyer orders",
      "Added the logic to display notifications when an order is placed and when a shop is followed and when a product is liked",
    ],
  },
  {
    vnum: "1.3.5",
    rd: "20 July 2021",
    vd: ["Modified founders section of about page"],
  },
  {
    vnum: "1.3.6",
    rd: "18 Septempber 2021",
    vd: [
      "Fixed admin application bug",
      "Fixed user registeration bug",
      "Restructured the home page (removed top shops, modified the nav-bar for desktop devices)",
      "Redesigned the users page (for employees)",
      "Redesigned the admin account (for admins)",
      "Added a feature to edit an item (for admins)",
      "Restructured the process of adding new items (for admins)",
      "Added a feature to add a review and see reviews of a product",
      "Added a feature to explore every item available on ordapple",
      "Added a feature to browse items by their categories or departments",
      "Added a feature to search items by their name or categories or departments",
    ],
  },
  {
    vnum: "1.3.7",
    rd: "19 Septempber 2021",
    vd: [
      "Fixed admin application bug",
      "Fixed adding of items (for admins)",
      "Fixed the bug in shops page",
    ],
  },
  {
    vnum: "1.3.8",
    rd: "20 Septempber 2021",
    vd: ["Fixed account activation bug"],
  },
  {
    vnum: "1.3.9",
    rd: "27 Septempber 2021",
    vd: [
      "Fixed item preview bug (Blank page when clicking on an item)",
      "Fixed shopping cart item image bug (Item in shopping cart displaying 'Product' instead of the item's image)",
    ],
  },
  {
    vnum: "1.4.0",
    rd: "27 Septempber 2021",
    vd: [
      "Fixed the popup message display bug when a user is not logged in",
      "Added logic to seperate all characters of the string by replacing spaces with dashes in the departments page/route",
    ],
  },
  {
    vnum: "1.4.1",
    rd: "28 Septempber 2021",
    vd: [
      "Fixed the buyer account bug (To show liked items and following shops)",
      "Fixed the explore and home data fetching to load items quickly",
      "Fixed the next and previous button algorithm",
      "Added item placeholder loading animation",
    ],
  },
  {
    vnum: "1.4.2",
    rd: "02 October 2021",
    vd: ["Added pagination logic when fetching users' data"],
  },
  {
    vnum: "1.4.3",
    rd: "07 October 2021",
    vd: [
      "fixed the bug for pagination for fetching users' data",
      "Added item placeholder loading animation when filtering products by departments/tags and categories",
      "Fixed the bug for user's login session",
    ],
  },
  {
    vnum: "1.4.4",
    rd: "15 April 2022",
    vd: [
      "Display 'items not found' when clicking a category or department/tag",
      "Payments can now be accepted",
      "An order can now have a status",
      "A customer can order an pay later",
      "A customer can change the status of an order",
      "Displaying a checkmark on the name of a shop that has been varified",
      "Admins can view their transactions and balance",
      "If an item clicked is a fast food item, the user will be asked to enter their location to match it with location of the shio",
      "An agent code can be entered by a customer in the order information page",
      "Changed the design of the item preview page",
    ],
  },
];

export default ChangeLog.reverse();
