import { useState } from "react";
import { useSelector } from "react-redux";
import Popup from "../../assets/Popup";
import ProdCard from "../../assets/ProdCard";
import Functions from "../../abstracts/Functions";
import { useLocation } from "react-router-dom";

const SearchedProducts = () => {
  const searched_products = useSelector((state) => state.searched_products);
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: "Ordapple | Searched items!",
    desc: "Viewing searched items",
  });

  // Use state
  const [display, setDisplay] = useState(false);

  const q = useLocation().search.split("=")[1];

  // Render functions

  const renderProdCard = () => {
    if (searched_products.length === 0) {
      return (
        <h1 className="sub-heading" style={{ color: "#bbb" }}>
          No items found!
        </h1>
      );
    } else {
      return searched_products.map((product, ind) => (
        <ProdCard
          key={ind}
          page="itemResults"
          setDisplay={setDisplay}
          shopName={product.shop.name}
          img={product.item.image.Location}
          productName={product.item.name}
          productCurrency={product.item.currency}
          productPrice={product.item.price}
          productId={product.item._id}
          shopId={product.shop._id}
          shopOwner={product.shop.shopOwner}
          shopVerified={product.shop.verified}
          likes={product.item.likes}
        />
      ));
    }
  };

  return (
    <div className="shopname">
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      <div className="shopname-container">
        <div className="products-container">
          {/* Products */}
          <div className="heading">
            <h1>Results for "{q}"</h1>
          </div>
          <div className="prod-card-container pagination">
            {renderProdCard()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchedProducts;
