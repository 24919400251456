import { useSelector } from "react-redux";

const PreLoader = () => {
  const loading = useSelector((state) => state.loading);
  return (
    // Pre-loader
    <>
      {loading && (
        <div className="loader">
          <div className="circle"></div>
        </div>
      )}
    </>
  );
};

export default PreLoader;
