import { createRef, useState } from "react";
import axios from "axios";
import Messages from "../../../abstracts/Messages";
import Functions from "../../../abstracts/Functions";
import { useSelector } from "react-redux";

const Whs = ({ toggleWhsDisplay, showWhs, dispatch, FLASH, setDisplay }) => {
  // Use state
  const [monFriOpensVal, setMonFriOpensVal] = useState("");
  const [monFriClosesVal, setMonFriClosesVal] = useState("");
  const [satOpensVal, setSatOpensVal] = useState("");
  const [satClosesVal, setSatClosesVal] = useState("");
  const [sunOpensVal, setSunOpensVal] = useState("");
  const [sunClosesVal, setSunClosesVal] = useState("");

  // Getting values from redux
  const admin_info = useSelector((state) => state.admin_info);

  // Messages
  const [msg, setMsg] = useState({});

  // Refs
  const monFriOpens = createRef();
  const monFriCloses = createRef();
  const satOpens = createRef();
  const satCloses = createRef();
  const sunOpens = createRef();
  const sunCloses = createRef();

  // EVENTS

  // On change event
  const onChange = (e) => {
    const { value, name, style } = e.target;

    const checkValid = (set, field) => {
      const whsExp = /[012]/gi;

      set(value.toLowerCase());
      console.log(value[0] !== "1");

      if (value.length === 4 && !whsExp.test(value[1])) {
        style.border = "3px solid red";
        setMsg({ [field]: "Invalid format!" });
      } else if (value.length === 4 && value[0] !== "1") {
        style.border = "3px solid red";
        setMsg({ [field]: "Invalid format!" });
      } else if (value.length >= 5) {
        style.border = "3px solid red";
        setMsg({ [field]: "Invalid format!" });
      } else if (value[value.length - 1].toLowerCase() !== "m") {
        style.border = "3px solid red";
        setMsg({ [field]: "Invalid format!" });
      } else {
        setMsg({});
        style.border = "3px solid rgb(0, 200, 0)";
      }
    };

    if (name === "monFriOpens") {
      checkValid(setMonFriOpensVal, "monFriOpens");
    } else if (name === "monFriCloses") {
      checkValid(setMonFriClosesVal, "monFriCloses");
    } else if (name === "satOpens") {
      checkValid(setSatOpensVal, "satOpens");
    } else if (name === "satCloses") {
      checkValid(setSatClosesVal, "satCloses");
    } else if (name === "sunOpens") {
      checkValid(setSunOpensVal, "sunOpens");
    } else if (name === "sunCloses") {
      checkValid(setSunClosesVal, "sunCloses");
    }
  };

  // On submit event
  const setWhs = (e) => {
    if (monFriOpens.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ monFriOpens: "Required field!" });
    } else if (monFriCloses.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ monFriCloses: "Required field!" });
    } else if (satOpens.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ satOpens: "Required field!" });
    } else if (satCloses.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ satCloses: "Required field!" });
    } else if (sunOpens.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ sunOpens: "Required field!" });
    } else if (sunCloses.current.value.trim() === "") {
      e.preventDefault();
      setMsg({ sunCloses: "Required field!" });
    } else {
      toggleWhsDisplay(e);
      setDisplay(true);
      e.preventDefault();
      const monFriOpens = monFriOpensVal;
      const monFriCloses = monFriClosesVal;
      const satOpens = satOpensVal;
      const satCloses = satClosesVal;
      const sunOpens = sunOpensVal;
      const sunCloses = sunClosesVal;

      axios({
        url: `${Functions.getAPI()}/whs/${admin_info._id}`,
        method: "POST",
        withCredentials: true,
        data: {
          monFriOpens,
          monFriCloses,
          satOpens,
          satCloses,
          sunOpens,
          sunCloses,
        },
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        })
        .catch((e) => {
          const res = e.response;
          if (res) {
            console.log(res.data);
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(e);
          }
        });

      setMsg({});
    }
  };

  return (
    <div
      className="form-popup-container"
      style={showWhs ? { display: "flex" } : { display: "none" }}
    >
      <div className="form">
        <form action="/whs" method="POST">
          <div className="day-card">
            <div className="heading">
              <h3 style={{ marginBottom: "8px" }}>Monday-Friday</h3>
              <Messages success='Format: 1-12 "am" or "pm"' />
            </div>
            <div className="inp">
              <input
                type="text"
                name="monFriOpens"
                placeholder="Opens at (e.g 8am)"
                id="monFriOpens"
                value={monFriOpensVal}
                onChange={onChange}
                ref={monFriOpens}
              />

              {msg.monFriOpens && (
                <p style={{ color: "red" }} id="err">
                  {msg.monFriOpens}
                </p>
              )}
              <input
                type="text"
                name="monFriCloses"
                placeholder="Closes at (e.g 5pm)"
                id="monFriCloses"
                value={monFriClosesVal}
                onChange={onChange}
                ref={monFriCloses}
              />
              {msg.monFriCloses && (
                <p style={{ color: "red" }} id="err">
                  {msg.monFriCloses}
                </p>
              )}
            </div>
          </div>
          <div className="day-card">
            <div className="heading">
              <h3>Saturday</h3>
            </div>
            <div className="inp">
              <input
                type="text"
                name="satOpens"
                placeholder="Opens at (e.g 8am)"
                id="satOpens"
                value={satOpensVal}
                onChange={onChange}
                ref={satOpens}
              />
              {msg.satOpens && (
                <p style={{ color: "red" }} id="err">
                  {msg.satOpens}
                </p>
              )}

              <input
                type="text"
                name="satCloses"
                placeholder="Closes at (e.g 5pm)"
                id="satCloses"
                value={satClosesVal}
                onChange={onChange}
                ref={satCloses}
              />
              {msg.satCloses && (
                <p style={{ color: "red" }} id="err">
                  {msg.satCloses}
                </p>
              )}
            </div>
          </div>
          <div className="day-card">
            <div className="heading">
              <h3>Sunday</h3>
            </div>
            <div className="inp">
              <input
                type="text"
                name="sunOpens"
                placeholder="Opens at (e.g 8am)"
                id="sunOpens"
                value={sunOpensVal}
                onChange={onChange}
                ref={sunOpens}
              />
              {msg.sunOpens && (
                <p style={{ color: "red" }} id="err">
                  {msg.sunOpens}
                </p>
              )}

              <input
                type="text"
                name="sunCloses"
                placeholder="Closes at (e.g 5pm)"
                id="sunCloses"
                value={sunClosesVal}
                onChange={onChange}
                ref={sunCloses}
              />
              {msg.sunCloses && (
                <p style={{ color: "red" }} id="err">
                  {msg.sunCloses}
                </p>
              )}
            </div>
          </div>
          <button className="submit-button" type="submit" onClick={setWhs}>
            Submit
          </button>
          <button className="cancel" onClick={toggleWhsDisplay}>
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default Whs;
