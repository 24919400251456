// ORDERS
const USERS = (state = [], { type, payload }) => {
  switch (type) {
    case "GET_USERS":
      return payload;
    case "EDIT_USER":
      const k = Object.keys(payload.data)[0];
      const v = Object.values(payload.data)[0];
      const ind = state.findIndex((el) => el._id === payload._id);
      state[ind][k] = v;

      return state;
    default:
      return state;
  }
};
const STATS = (state = {}, { type, payload }) => {
  switch (type) {
    case "GET_STATS":
      return payload;
    default:
      return state;
  }
};

const Admin = {
  USERS,
  STATS,
};
export default Admin;
