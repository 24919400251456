// SHOPS
const SHOPS = (shops) => {
  return {
    type: "SHOPS",
    payload: shops,
  };
};
// VIEWED_SHOP
const VIEWED_SHOP = (shops) => {
  return {
    type: "VIEWED_SHOP",
    payload: shops,
  };
};
// SEARCHED_SHOPS
const SEARCHED_SHOPS = (shops) => {
  return {
    type: "SEARCHED_SHOPS",
    payload: shops,
  };
};
// PRODUCTS
const SHOP_PRODUCTS = (products) => {
  return {
    type: "SHOP_PRODUCTS",
    payload: products,
  };
};
// SEARCHED_PRODUCTS
const SEARCHED_PRODUCTS = (products) => {
  return {
    type: "SEARCHED_PRODUCTS",
    payload: products,
  };
};
const REMOVE_SHOP_PRODUCTS = () => {
  return {
    type: "REMOVE_SHOP_PRODUCTS",
  };
};
const PRODUCT_PREVIEW = (product) => {
  return {
    type: "PRODUCT_PREVIEW",
    payload: product,
  };
};
const REMOVE_PREVIEWED_PRODUCT = () => {
  return {
    type: "REMOVE_PREVIEWED_PRODUCT",
  };
};
// CART
const ADD_TO_CART = (product) => {
  return {
    type: "ADD_TO_CART",
    payload: product,
  };
};
const REMOVE_FROM_CART = (product) => {
  return {
    type: "REMOVE_FROM_CART",
    payload: product,
  };
};
const UPDATE_CART = (product) => {
  return {
    type: "UPDATE_CART",
    payload: product,
  };
};
const EMPTY_CART = () => {
  return {
    type: "EMPTY_CART",
  };
};
// Account
const FOLLOWING = (data) => {
  return {
    type: "FOLLOWING",
    payload: data,
  };
};
const UNFOLLOW = (data) => {
  return {
    type: "UNFOLLOW",
    payload: data,
  };
};
const LIKED = (data) => {
  return {
    type: "LIKED",
    payload: data,
  };
};
const UNLIKE = (data) => {
  return {
    type: "UNLIKE",
    payload: data,
  };
};
// Orders
const BUYER_ORDERS = (data) => {
  return {
    type: "BUYER_ORDERS",
    payload: data,
  };
};
const BUYER_REMOVE_ORDER = (data) => {
  return {
    type: "BUYER_REMOVE_ORDER",
    payload: data,
  };
};
const BUYER_ADD_ORDER = (data) => {
  return {
    type: "BUYER_ADD_ORDER",
    payload: data,
  };
};
const BUYER_REMOVE_ALL_ORDERS = (data) => {
  return {
    type: "BUYER_REMOVE_ALL_ORDERS",
    payload: data,
  };
};

const BuyerActions = {
  SHOPS,
  VIEWED_SHOP,
  SEARCHED_SHOPS,
  SHOP_PRODUCTS,
  SEARCHED_PRODUCTS,
  REMOVE_SHOP_PRODUCTS,
  PRODUCT_PREVIEW,
  REMOVE_PREVIEWED_PRODUCT,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART,
  EMPTY_CART,
  FOLLOWING,
  UNFOLLOW,
  LIKED,
  UNLIKE,
  BUYER_ORDERS,
  BUYER_ADD_ORDER,
  BUYER_REMOVE_ORDER,
  BUYER_REMOVE_ALL_ORDERS,
};

export default BuyerActions;
