import images from "../../abstracts/Images";

const Hero = () => {
  return (
    <div className="imgs">
      {images.banners.map((img, ind) => (
        <img key={ind} src={img} alt="Banner" loading="lazy" />
      ))}
    </div>
  );
};

export default Hero;
