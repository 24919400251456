// INBOXES
const INBOX = (inbox) => {
  return {
    type: "INBOX",
    payload: inbox,
  };
};

// NEW INBOX
const NEW_INBOX = (inbox) => {
  return {
    type: "NEW_INBOX",
    payload: inbox,
  };
};

// UNSEEN INBOX
const UNSEEN_INBOX = (data) => {
  return {
    type: "UNSEEN_INBOX",
    payload: data,
  };
};

const InboxActions = {
  INBOX,
  NEW_INBOX,
  UNSEEN_INBOX,
};

export default InboxActions;
