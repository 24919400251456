import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ProdCard from "../../assets/ProdCard";

const ProdCat = ({ setDisplay }) => {
  // Redux state
  const home_data = useSelector((state) => state.home_data);

  const loadProducts = (k) => {
    if (home_data.productCategories) {
      if (home_data.productCategories[k]) {
        if (home_data.productCategories[k].length >= 1) {
          return (
            <>
              <div className="heading">
                <h2>{k.toUpperCase()}</h2>
              </div>
              <div className="prod-card-container">
                {home_data.productCategories[k].map((prod, ind) => {
                  return (
                    <ProdCard
                      key={ind}
                      page="home"
                      setDisplay={setDisplay}
                      shopName={prod.shop.name}
                      img={prod.image.Location}
                      productName={prod.name}
                      productCurrency={prod.currency}
                      productPrice={prod.price}
                      productId={prod._id}
                      shopId={prod.shop._id}
                      likes={prod.allLikes}
                      shopVerified={prod.shop.verified}
                    />
                  );
                })}
              </div>

              {/* More link */}
              <Link className="more" to="/shop">
                Load more <i className="fas fa-chevron-right"></i>
              </Link>
            </>
          );
        }
      }
    }
  };

  const renderProdCat = () => {
    return (
      <div className="product-categories">
        <div className="prods-container">
          {/* Books */}
          {loadProducts("books")}

          {/* Fashion */}
          {loadProducts("fashion")}

          {/* Food */}
          {loadProducts("food")}

          {/* Gadgeds */}
          {loadProducts("gadgets")}

          {/* Kitchen */}
          {loadProducts("kitchen")}

          {/* Tech */}
          {loadProducts("tech")}

          {/* Other */}
          {loadProducts("other")}
        </div>
      </div>
    );
  };
  return <>{renderProdCat()}</>;
};

export default ProdCat;
