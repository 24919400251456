import ProdCard from "../../assets/ProdCard";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ProdCardLoader from "../../assets/ProdCardLoader";

const TopProducts = ({ setDisplay }) => {
  // Redux state
  const home_data = useSelector((s) => s.home_data);

  // Render products
  const renderTopProds = () => {
    if ("topProducts" in home_data) {
      if (home_data.topProducts.length >= 1) {
        return (
          <div className="top-products">
            <div className="heading">
              <h1>Top products</h1>
              <span></span>
            </div>

            <div className="prods-container">
              <div className="prod-card-container">
                {home_data.topProducts.map((prod, ind) => {
                  return (
                    <ProdCard
                      key={ind}
                      page="home"
                      setDisplay={setDisplay}
                      shopName={prod.shop.name}
                      shopVerified={prod.shop.verified}
                      img={prod.image.Location}
                      productName={prod.name}
                      productCurrency={prod.currency}
                      productPrice={prod.price}
                      productId={prod._id}
                      shopId={prod.shop._id}
                      likes={prod.allLikes}
                    />
                  );
                })}
              </div>

              <Link className="more" to="/shop">
                Load more <i className="fas fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        );
      } else {
        const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
        return (
          <div className="top-products">
            <div className="heading">
              <h1>Top products</h1>
              <span></span>
            </div>

            <div className="prods-container">
              <div className="prod-card-container">
                {arr.map((i, ind) => (
                  <ProdCardLoader key={ind} />
                ))}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  return <>{renderTopProds()}</>;
};

export default TopProducts;
