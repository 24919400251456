import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import InboxActions from "../redux/actions/Inbox";
import Functions from "../abstracts/Functions";
import axios from "axios";
import { useEffect } from "react";

const BottomNav = () => {
  const path = useLocation().pathname;

  // Redux
  const unseen_inbox = useSelector((state) => state.unseen_inbox);
  const inbox = useSelector((state) => state.inbox);

  // History
  const history = useHistory();

  // Get state values
  const cart = useSelector((state) => state.cart);

  // Dispatch
  const dispatch = useDispatch();

  // Use effect
  useEffect(() => {
    // Fetch all user's inbox
    axios({
      url: `${Functions.getAPI()}/inbox`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // If status is 200, let the user login
        if (res.status === 200) {
          // Dispatching
          dispatch(InboxActions.INBOX(data.inbox));
        }
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
        } else {
          history.push("/error");
          console.log(e);
        }
      });

    // Set the seen inbox value
    dispatch(InboxActions.UNSEEN_INBOX({ seen: inbox.seen, num: inbox.num }));
  }, [dispatch, history, inbox.num, inbox.seen]);

  // Setting the seen and number (number of unseen inboxes) to followee
  const setSeen = async () => {
    if ("inboxes" in inbox) {
      return axios({
        url: `${Functions.getAPI()}/inbox/status`,
        method: "POST",
        withCredentials: true,
        data: { seenInbox: true, owner: inbox.owner },
      })
        .then(async (res) => {
          const inbox = await res.data.data;
          // If status is 200, let the user login
          if (res.status === 200) {
            dispatch(
              InboxActions.UNSEEN_INBOX({ seen: inbox.seen, num: inbox.num })
            );
          }
        })
        .catch((e) => {
          const res = e.response;
          if (res) console.log(res.data);
          else console.log(e);
        });
    }
  };

  return (
    //    Bottom navigation
    <>
      <div className="bottom-nav">
        <div className="nav-container">
          <Link to="/shop" className={path === "/shop" ? "active" : ""}>
            <i className="fas fa-store"></i>
            <p>Explore</p>
          </Link>
          <Link
            to="/buyer/orders"
            className={path === "/buyer/orders" ? "active" : ""}
          >
            <i className="fas fa-file"></i>
            <p>Orders</p>
          </Link>
        </div>
        <div className="button-container">
          <Link to="/cart" className="float-button">
            <div className="cart">
              <span>
                <i className="fas fa-shopping-bag"></i>
              </span>
              <span className="cart-num">{cart.items.length} </span>
            </div>
          </Link>
        </div>
        <div className="nav-container">
          <Link
            onClick={setSeen}
            to="/inbox"
            className={path === "/inbox" ? "active" : ""}
          >
            {unseen_inbox.seen === false && (
              <div className="inbox">
                {Functions.numFormatter(unseen_inbox.num)}
              </div>
            )}

            <i className="fas fa-inbox"></i>
            <p>Inbox</p>
          </Link>

          <Link
            to="/buyer/account"
            className={path === "/buyer/account" ? "active" : ""}
          >
            <i className="fas fa-user"></i>
            <p>Acc</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default BottomNav;
