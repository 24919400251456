const register = () => {
  return {
    type: "register",
  };
};
const login = () => {
  return {
    type: "login",
  };
};
const logout = () => {
  return {
    type: "logout",
  };
};

const AuthActions = {
  register: register,
  login: login,
  logout: logout,
};

export default AuthActions;
