import { useState, createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../assets/Popup";
import FLASH from "../../redux/actions/Flash";
import { RESTORE_FLASH } from "../../redux/actions/Flash";
import AdminNav from "./assets/AdminNav";
import axios from "axios";
import AdminActions from "../../redux/actions/AdminActions";
import Functions from "../../abstracts/Functions";
import Form from "../../assets/Form";
import ImgUploader from "./assets/ImgUploader";
import ProductInfo from "./assets/ProductInfo";
import ProductOpts from "./assets/ProductOpts";

const AdminAdd = ({ history }) => {
  // Redux
  const dispatch = useDispatch();
  const flash = useSelector((state) => state.flash);
  const admin_info = useSelector((state) => state.admin_info);

  Functions.pageInfo({
    title: "Ordapple | Add Items To Your Shop",
    desc: "Adding Items To Your Shop",
  });

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  }, [dispatch]);

  // Messages
  const [msg, setMsg] = useState({});

  // States
  const [productNameVal, setProductNameVal] = useState("");
  const [productDescriptionVal, setProductDescriptionVal] = useState("");
  const [currencyVal, setCurrencyVal] = useState("R");
  const [deliveryVal, setDeliveryVal] = useState("No");
  const [productPriceVal, setProductPriceVal] = useState("");
  const [productCategoryVal, setProductCategoryVal] = useState("");
  const [tags, setTags] = useState("");
  const [display, setDisplay] = useState(false);
  const [file, setFile] = useState(null);
  const [filename, setFileName] = useState(null);
  const [next, setNext] = useState(1);
  const [opts, setOpts] = useState("");

  // Refs
  const productName = createRef();
  const productDescription = createRef();
  const currency = createRef();
  const delivery = createRef();
  const productPrice = createRef();
  const productCategory = createRef();

  const onChange = (e) => {
    const { value, name, style } = e.target;

    if (name === "productName") {
      setProductNameVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "productDescription") {
      setProductDescriptionVal(value);
      style.border = "3px solid rgb(0, 200, 0)";
    } else if (name === "currency") {
      if (value === "") {
        setMsg({ currency: "Required!" });
        style.border = "3px solid red";
      } else {
        setCurrencyVal(value);
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "delivery") {
      if (value === "") {
        setMsg({ delivery: "Required!" });
        style.border = "3px solid red";
      } else {
        setDeliveryVal(value);
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "productPrice") {
      const split = value.split("");
      if (split[0] === "-") {
        setMsg({ productPrice: "Negative values not allowed!" });
        style.border = "3px solid red";
      } else {
        setProductPriceVal(value);
        style.border = "3px solid rgb(0, 200, 0)";
      }
    } else if (name === "productCategory") {
      if (value === "") {
        setMsg({ productCategory: "Select product category!" });
        style.border = "3px solid red";
      } else {
        setProductCategoryVal(value);
        style.border = "3px solid rgb(0, 200, 0)";
      }
    }
  };

  // Add item function
  const add_item = (e) => {
    e.preventDefault();

    // Loading animation on submit button
    e.target.classList.add("btn-animate");
    e.target.innerText = "Processing...";

    const productName = productNameVal;
    const productDescription = productDescriptionVal;
    const currency = currencyVal;
    const delivery = deliveryVal;
    const productPrice = productPriceVal;
    const productCategory = productCategoryVal;
    const productImage = file;
    const options = opts;

    // Form data
    const data = {};
    data["name"] = productName;
    data["description"] = productDescription;
    data["currency"] = currency;
    data["delivery"] = delivery;
    data["price"] = productPrice;
    data["category"] = productCategory;
    data["tags"] = tags;
    data["image"] = productImage;
    data["options"] = options;
    data["filename"] = filename;

    axios({
      url: `${Functions.getAPI()}/admin/addItem/${admin_info._id}`,
      method: "POST",
      withCredentials: true,
      data,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;
        console.log(data);

        setDisplay(true);

        // Removing the loading animation from the button
        e.target.classList.remove("btn-animate");
        e.target.innerText = "Submit";

        // Dispatching
        dispatch(FLASH({ err: "", success: data.success, warn: "" }));
        dispatch(AdminActions.ADD_PRODUCT(data.product));
      })
      .catch((err) => {
        setDisplay(true);

        // Removing the loading animation from the button
        e.target.classList.remove("btn-animate");
        e.target.innerText = "Submit";

        const res = err.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(err);
        }
      });

    setMsg({ email: "", pass: "" });
  };

  // Going back and forward
  const back = (e) => {
    setNext(next - 1);
  };

  const forward = (e) => {
    if (next === 2) {
      const split = (val) => {
        const split = val.split("");
        return split;
      };

      const warning = () => {
        setDisplay(true);
        dispatch(
          FLASH({
            err: "",
            success: "",
            warn: "Make sure all fields are filled in correctly!",
          })
        );
      };
      if (productName.current.value.trim() === "") {
        setMsg({ productName: "Required field!" });
        warning();
      } else if (productPrice.current.value.trim() === "") {
        setMsg({ productPrice: "Required field!" });
        warning();
      } else if (split(productPrice.current.value) === "-") {
        setMsg({ productPrice: "Negative values not allowed!" });
        warning();
      } else if (productCategory.current.value.trim() === "") {
        setMsg({ productCategory: "Select product category!" });
        warning();
      } else if (currency.current.value.trim() === "") {
        setMsg({ currency: "Required field!" });
        warning();
      } else if (delivery.current.value.trim() === "") {
        setMsg({ delivery: "Required field!" });
        warning();
      } else if (productDescription.current.value.trim() === "") {
        setMsg({ productDescription: "Required field!" });
        warning();
      } else {
        setNext(next + 1);
      }
    } else {
      setNext(next + 1);
    }
  };

  return (
    <div>
      {/* Popup messages */}
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
          history={history}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}

      <AdminNav page="add-items" />

      {/* Uploading an image */}
      {next === 1 && (
        <ImgUploader
          setFile={setFile}
          forward={forward}
          setFileName={setFileName}
        />
      )}

      {/* {Product information} */}
      {next === 2 && (
        <ProductInfo
          productNameVal={productNameVal}
          onChange={onChange}
          productName={productName}
          msg={msg}
          productPriceVal={productPriceVal}
          productPrice={productPrice}
          productCategoryVal={productCategoryVal}
          productCategory={productCategory}
          currencyVal={currencyVal}
          currency={currency}
          deliveryVal={deliveryVal}
          delivery={delivery}
          productDescriptionVal={productDescriptionVal}
          productDescription={productDescription}
          tags={tags}
          setTags={setTags}
          back={back}
          forward={forward}
        />
      )}

      {/* Product's additional options */}
      {next === 3 && (
        <ProductOpts
          setOpts={setOpts}
          opts={opts}
          back={back}
          forward={forward}
          productCategoryVal={productCategoryVal}
        />
      )}

      {/* Final step of adding a product */}
      {next === 4 && (
        <Form>
          <div className="heading">
            <h1>Final step</h1>
          </div>
          <button type="submit" className="submit-button dry" onClick={back}>
            Back
          </button>
          <button type="submit" className="submit-button" onClick={add_item}>
            Save
          </button>
        </Form>
      )}
    </div>
  );
};

export default AdminAdd;
