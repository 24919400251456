const Success = () => {
  return (
    <div className="register-login login">
      <div className="form">
        <div className="heading">
          <h1 style={{ textAlign: "center" }}>Success message here</h1>
        </div>
        <br />
        <a href="/linkFromServer">Text link from server</a>
      </div>
    </div>
  );
};

export default Success;
