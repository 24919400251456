import { useEffect } from "react";
import { createRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Messages from "../../abstracts/Messages";
import FLASH from "../../redux/actions/Flash";
import axios from "axios";
import Functions from "../../abstracts/Functions";

const ResetPass = ({ history }) => {
  // Params info
  const { userId } = useParams();

  Functions.pageInfo({
    title: "Ordapple | Reset Password",
    desc: "Reset Password",
  });

  // Redux
  const dispatch = useDispatch();
  const flash = useSelector((state) => state.flash);

  // Use effect
  useEffect(() => {
    dispatch(FLASH({ err: "", success: "", warn: "" }));
  }, [dispatch]);

  // Messages
  const [msg, setMsg] = useState("");

  // States
  const [passVal, setPassVal] = useState("");

  // Refs
  const password = createRef();

  // On change event
  const onchange = (e) => {
    const { value, style } = e.target;
    setPassVal(value);
    if (value.length < 5) {
      style.border = "3px solid red";
      setMsg("Must be 5 char or more");
    } else {
      setMsg("");
      style.border = "3px solid rgb(0, 200, 0)";
    }
  };

  // Function to run when submitting
  const submitHandler = (e) => {
    if (password.current.value.trim() === "") {
      e.preventDefault();
      setMsg("Required field!");
    } else if (password.current.value.length < 5) {
      e.preventDefault();
      setMsg("Must be 5 char or more");
    } else {
      e.preventDefault();

      // Loading animation on submit button
      e.target.classList.add("btn-animate");
      e.target.innerText = "Processing...";

      const password = passVal;

      axios({
        url: `${Functions.getAPI()}/reset-password`,
        method: "POST",
        data: {
          password,
          userId,
        },
      })
        .then(async (res) => {
          // Waiting for the data before proceeding
          const data = await res.data;

          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          // Dispatching
          dispatch(FLASH({ err: "", success: data.success, warn: "" }));

          // Redirecting the user to shops page
          history.push("/login");
        })
        .catch((err) => {
          // Removing the loading animation from the button
          e.target.classList.remove("btn-animate");
          e.target.innerText = "Submit";

          const res = err.response;
          if (res) {
            if (res.data.err) {
              dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
            } else {
              dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
            }
          } else {
            console.log(err);
          }
        });

      setMsg("");
    }
  };

  return (
    <div className="register-login login">
      <div className="form">
        <div className="heading">
          <h1>Reset password</h1>
        </div>

        <form className="form-slide" method="POST">
          {flash.err !== "" && <Messages err={flash.err} />}
          {flash.success !== "" && <Messages success={flash.success} />}

          <input type="hidden" name="userId" value="<%= userId %>" />
          <div className="passwords">
            <input
              type="password"
              className="formInput"
              name="password"
              id="password"
              placeholder="New password"
              value={passVal}
              onChange={onchange}
              ref={password}
            />
          </div>
          <p style={{ color: "red" }} id="password-error">
            {msg}
          </p>
          <br />

          <button
            type="submit"
            className="submit-button"
            onClick={submitHandler}
          >
            Submit
          </button>
          <br />
          <Link to="/login">Login</Link>
        </form>
      </div>
    </div>
  );
};

export default ResetPass;
