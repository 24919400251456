import { Link } from "react-router-dom";

const AppSuccess = () => {
  return (
    <div className="error">
      <div className="error-container">
        <h2>Thank you,</h2>
        <br />
        <p>Message from server</p>
        <br />

        {/* If user is logged in */}
        <Link style={{ textAlign: "center" }} to="/shops">
          Continue shopping
        </Link>
        <p>OR</p>
        <Link style={{ marginTop: "10px", textAlign: "center" }} to="/">
          Go back home
        </Link>
        {/* If user is not logged in */}
        {/* <Link style={{ textAlign: "center" }} href="/">Go back home</Link> */}
      </div>
    </div>
  );
};

export default AppSuccess;
