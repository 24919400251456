import { Route } from "react-router";
import ProtectedRoute from "../ProtectedRoute";
import AdminAcc from "../admin/AdminAcc";
import AdminOrders from "../admin/AdminOrders";
import AdminAdd from "../admin/AdminAdd";
import AdminEditAcc from "../admin/AdminEditAcc";
import AdminApply from "../admin/AdminApply";
import AdminStats from "../admin/AdminStats";

const Admin = [
  {
    routeType: ProtectedRoute,
    path: "/admin/account",
    exact: true,
    component: AdminAcc,
  },
  {
    routeType: ProtectedRoute,
    path: "/admin/stats",
    exact: true,
    component: AdminStats,
  },
  {
    routeType: ProtectedRoute,
    path: "/admin/orders",
    exact: true,
    component: AdminOrders,
  },
  {
    routeType: ProtectedRoute,
    path: "/admin/add-items",
    exact: true,
    component: AdminAdd,
  },
  {
    routeType: ProtectedRoute,
    path: "/admin/edit-account",
    exact: true,
    component: AdminEditAcc,
  },
  {
    routeType: Route,
    path: "/admin/apply/",
    exact: true,
    component: AdminApply,
  },
  {
    routeType: Route,
    path: "/admin/apply/:plan",
    exact: true,
    component: AdminApply,
  },
];

export default Admin;
