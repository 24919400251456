import { Link } from "react-router-dom";

const ItemCard = ({
  name,
  price,
  shopId,
  id,
  img,
  deleteItem,
  currency,
  edit_prod,
}) => {
  return (
    <>
      <Link
        to={`/prod/${name}/${id}/${shopId}`}
        className="list-card"
        style={{ marginTop: "10px" }}
        onClick={(e) => {
          edit_prod(e, id);
        }}
      >
        <div className="img">
          {img ? (
            <img src={img} alt="product" />
          ) : (
            <p>
              <i className="fas fa-heart"></i>
            </p>
          )}
        </div>
        <div className="info">
          <h2>{name}</h2>
          <h3>
            {currency} {price}{" "}
          </h3>
        </div>
      </Link>
      <div className="buttons" style={{ marginBottom: "20px" }}>
        <button
          onClick={(e) => {
            deleteItem(e, id);
          }}
        >
          X
        </button>
      </div>
    </>
  );
};

export default ItemCard;
