import Styles from "../../abstracts/Styles";
import ShopCard from "../../assets/ShopCard";
import { useEmblaCarousel } from "embla-carousel/react";
import { useSelector } from "react-redux";

const TopShops = ({ setDisplay }) => {
  const [emblaRef] = useEmblaCarousel();

  // Redux state
  const home_data = useSelector((state) => state.home_data);

  // Render Top shops
  const renderTopShops = () => {
    if ("topShops" in home_data) {
      if (home_data.topShops.length >= 1) {
        //   Top shops
        return (
          <div className="top-shops">
            <div className="heading">
              <h1>Top shops</h1>
              <span></span>
            </div>

            <div className="embla" style={Styles.embla} ref={emblaRef}>
              <div
                style={Styles.embla__container}
                className="shops-card-container embla__container"
              >
                {home_data.topShops.map((shop, ind) => (
                  <ShopCard
                    key={ind}
                    page="home"
                    shopId={shop._id}
                    shopName={shop.shopName}
                    shopVerified={shop.verified}
                    aboutShop={shop.aboutShop}
                    shopLocation={shop.shopLocation}
                    workingHours={shop.workingHours}
                    visits={shop.visits}
                    followers={shop.followers}
                    img={shop.adminImage.imageLocation}
                    setDisplay={setDisplay}
                  />
                ))}
              </div>
            </div>
            <p className="slide-msg">
              <i className="fas fa-chevron-left"></i> Slide left or right
              <i className="fas fa-chevron-right"></i>
            </p>
          </div>
        );
      }
    }
  };

  return <>{renderTopShops()}</>;
};

export default TopShops;
