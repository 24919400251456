import Navs from "./assets/Navs";
import BottomNav from "./assets/BottomNav";
import Footer from "./assets/Footer";
import Subscribe from "./assets/Subscribe";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import AuthActions from "./redux/actions/AuthActions";
import USER from "./redux/actions/User";
import Functions from "./abstracts/Functions";

// Routes
import Home from "./components/routes/home";
import Auth from "./components/routes/auth";
import Error404 from "./components/Err/404";
import Admin from "./components/routes/admin";
import Buyer from "./components/routes/buyer";
import Job from "./components/routes/job";
import Shop from "./components/routes/shop";
import Static from "./components/routes/static";
import BuyerActions from "./redux/actions/BuyerActions";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();

    axios({
      url: `${Functions.getAPI()}/login`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Dispatching

        // Logging in the user
        dispatch(AuthActions.login());
        localStorage.setItem("auth", true);

        // Adding the user information
        dispatch(USER.USER_INFO(data.user));
        dispatch(BuyerActions.BUYER_ORDERS(data.user.orders.reverse()));

        // Getting user's shopping cart info
        dispatch(BuyerActions.ADD_TO_CART(data.user.cart));
      })
      .catch((e) => {
        if (e.response) {
          console.log(e.response.data);
        } else {
          console.log(e);
        }

        // Logging out the user
        dispatch(AuthActions.logout());
        localStorage.setItem("auth", false);

        // Removing the user information
        dispatch(USER.DELETE_ACCOUNT());
      });
  }, [dispatch]);

  return (
    <div className="container">
      {/* Routes */}
      <Router>
        {/* Main navigation */}
        <Navs />

        {/* Subscribe banner */}
        <Subscribe />

        <Switch>
          {/* Home */}
          {Home.map((route, ind) => (
            <route.routeType
              path={route.path}
              key={ind}
              exact={route.exact}
              component={route.component}
            />
          ))}

          {/* Static */}
          {Static.map((route, ind) => (
            <route.routeType
              path={route.path}
              key={ind}
              exact={route.exact}
              component={route.component}
            />
          ))}

          {/* Auth */}
          {Auth.map((route, ind) => (
            <route.routeType
              path={route.path}
              key={ind}
              exact={route.exact}
              component={route.component}
            />
          ))}

          {/* Shop */}
          {Shop.map((route, ind) => (
            <route.routeType
              path={route.path}
              key={ind}
              exact={route.exact}
              component={route.component}
            />
          ))}

          {/* Buyer */}
          {Buyer.map((route, ind) => (
            <route.routeType
              path={route.path}
              key={ind}
              exact={route.exact}
              component={route.component}
            />
          ))}

          {/* Admin */}
          {Admin.map((route, ind) => (
            <route.routeType
              path={route.path}
              key={ind}
              exact={route.exact}
              component={route.component}
            />
          ))}

          {/* Job */}
          {Job.map((route, ind) => (
            <route.routeType
              path={route.path}
              key={ind}
              exact={route.exact}
              component={route.component}
            />
          ))}

          {/* Not found */}
          <Route path="/*" component={Error404} />
        </Switch>

        {/* Bottom content (footer and navigation) */}
        <Footer />
        <BottomNav />
      </Router>
    </div>
  );
}

export default App;
