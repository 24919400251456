import { useState } from "react";
import MapData from "../abstracts/MapData";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Loader from "../abstracts/Loader";

// This component will display the map
const MapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: MapData.latLong.lat, lng: MapData.latLong.lng }}
    >
      {/* Displaying the marker */}
      {props.isMarkerShown && (
        <Marker
          position={
            props.address.lat !== null
              ? props.address
              : { lat: MapData.latLong.lat, lng: MapData.latLong.lng }
          }
        />
      )}
    </GoogleMap>
  ))
);

const GoogleMaps = ({
  updateDone,
  mapInputVal,
  getValue,
  setLocation,
  location,
}) => {
  // Address/location/place found
  const address = location;

  // Latitude and longitude
  const [latLong, setlatLong] = useState({ lat: null, lng: null });

  // getting the updated address when a user types
  const handleChange = (address) => {
    setLocation(address);
    getValue(address);
  };

  // when a user selects 1 option from the suggestions
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        const placeName = results[0].formatted_address;
        console.log(placeName);
        MapData.latLong.address = placeName;
        setLocation(placeName);
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setlatLong({ lat: latLng.lat, lng: latLng.lng });
        console.log("Success", latLng);
      })
      .catch((error) => console.error("Error", error));
  };

  const inputStyles = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  const mapStyles =
    window.innerWidth <= 600
      ? {
          width: " 100%",
          height: "400px",
        }
      : {
          width: " 100%",
          height: "240px",
        };
  const loaderStyles = {
    display: "flex",
    width: "100%",
    padding: "3px",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div className="register-login login goggle-maps">
      <div className="form">
        <div className="heading">
          <h1>Step 1 (Location)</h1>
        </div>

        <form action="#">
          <div className="map-cont" style={{ width: "100%" }}>
            <PlacesAutocomplete
              value={address}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  {/* Search box for places */}
                  <input
                    {...getInputProps({
                      placeholder: "Search Places ...",
                      className: "location-search-input",
                      id: "location",
                      style: inputStyles,
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {/* When the places are loading */}
                    {loading && (
                      <div style={loaderStyles}>
                        <Loader />
                      </div>
                    )}
                    {/* Displayed suggestions as you type */}
                    {suggestions.map((suggestion, i) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#eee", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          key={i}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          {/* The description contains the place's name */}
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <div id="map" style={mapStyles}>
              <MapComponent
                isMarkerShown
                address={latLong}
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDCz5UAbpNZgUBCGxwh8b_FZn52pQlwf-8&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `240px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          </div>
          {/* Displaying selected place on the google maps  */}
          <p id="textLocation">{address}</p>

          <button id="next" onClick={updateDone}>
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default GoogleMaps;
