import FaqCard from "../../assets/FaqCard";
import Functions from "../../abstracts/Functions";
import HelpData from "../../abstracts/HelpData";

const Faq = () => {
  Functions.pageInfo({
    title: "Ordapple | Frequently Asked Questions",
    desc: "Answers To Frequently Asked Questions",
  });

  return (
    <div className="help">
      <div className="help-container">
        <div className="heading">
          <h1>Frequently asked questions</h1>
        </div>

        {HelpData.map((data, ind) => (
          <FaqCard head={data.head} body={data.body} key={ind} />
        ))}
      </div>
    </div>
  );
};

export default Faq;
