import Functions from "../abstracts/Functions";
import { useSelector } from "react-redux";
import { useState } from "react";

const Subscribe = () => {
  // Use state
  const [subscribed, setSubscribed] = useState(false);

  // Redux
  const user = useSelector((state) => state.user);

  const subStyles = {
    width: "100%",
    padding: "15px",
    display: displaySub(),
    alignItems: "center",
    justifyContent: "center",
  };

  const publicVapidKey = process.env.REACT_APP_WEBPUSH_PUBLIC_KEY;

  // Web push notifications
  const subacribeToNotif = () => {
    // Notif prompt
    Notification.requestPermission().then(async (res) => {
      if (res === "granted") {
        navigator.serviceWorker.ready.then(async (registration) => {
          const sub = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: publicVapidKey,
          });

          Functions.sendNotif({
            sub,
            _id: user._id,
            body: "Thank Your for subscribing!",
            url: "https://www.ordapple.com",
            fetchUrl: `${Functions.getAPI()}/subscribe`,
          });

          setSubscribed(true);
        });
      }
    });
  };

  function displaySub() {
    if (subscribed || Notification.permission === "granted") {
      return "none";
    } else if (
      user._id &&
      Notification.permission !== "granted" &&
      Notification.permission !== "denied"
    ) {
      return "flex";
    } else {
      return "none";
    }
  }

  const subscribe = (e) => {
    e.preventDefault();
    subacribeToNotif();
  };

  return (
    <div className="sub" style={subStyles}>
      <p>Subscribe to receive notifications!</p>
      <button onClick={subscribe}>Subscribe</button>
    </div>
  );
};

export default Subscribe;
