import { Link } from "react-router-dom";

const Tile = ({ option, route }) => {
  return (
    <Link to={route} className="tile">
      <div className="img">
        <p>
          <i className="fas fa-heart"></i>
        </p>
      </div>
      <div className="info">
        <h2>{option}</h2>
      </div>
    </Link>
  );
};
export default Tile;
