const Flash = (
  state = { err: "", success: "", warn: "" },
  { type, payload }
) => {
  switch (type) {
    case "FLASH":
      return {
        ...state,
        err: payload.err,
        success: payload.success,
        warn: payload.warn,
      };
    case "RESTORE_FLASH":
      return { err: "", success: "", warn: "" };
    default:
      return state;
  }
};
export default Flash;
