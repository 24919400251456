import DesktopNav from "../abstracts/DesktopNav";
import DesktopSearch from "../abstracts/DesktopSearch";
import MobileNav from "../abstracts/MobileNav";
import MBLNavLinks from "../abstracts/MBLNavLinks";
import { useState } from "react";
import PopupOpts from "./PopupOpts";

const Navs = () => {
  // Use state
  const [search, setSearch] = useState(false);
  const [menu, setMenu] = useState(false);
  const [showOpts, setShowOpts] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const toggleSearch = () => {
    setSearch(!search);
  };

  const toggleDesktopSearch = (e) => {
    e.preventDefault();
    setShowSearch(!showSearch);
  };

  const toggleMenu = () => {
    setMenu(!menu);
  };

  // Toggle the display of popup options
  const togglePopupOpts = (e) => {
    e.preventDefault();
    setShowOpts(!showOpts);
  };

  return (
    <nav className="landing-nav">
      <DesktopNav
        togglePopupOpts={togglePopupOpts}
        toggleDesktopSearch={toggleDesktopSearch}
        setShowSearch={setShowSearch}
      />
      <DesktopSearch
        showSearch={showSearch}
        toggleDesktopSearch={toggleDesktopSearch}
      />
      <MobileNav
        toggleSearch={toggleSearch}
        toggleMenu={toggleMenu}
        menu={menu}
        search={search}
      />
      <MBLNavLinks menu={menu} toggleMenu={toggleMenu} />

      {/* Popup options */}
      <PopupOpts
        togglePopupOpts={togglePopupOpts}
        showOpts={showOpts}
        setShowOpts={setShowOpts}
      />
    </nav>
  );
};

export default Navs;
