const LOADING = () => {
  return {
    type: "LOADING",
  };
};
const FINISHED_LOADING = () => {
  return {
    type: "FINISHED_LOADING",
  };
};

const LoadinActions = {
  LOADING,
  FINISHED_LOADING,
};

export default LoadinActions;
