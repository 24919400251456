import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Functions from "../../abstracts/Functions";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import axios from "axios";
import JobNav from "./assets/JobNav";

const AgentStats = () => {
  Functions.pageInfo({
    title: "Ordapple | Agents Management System",
    desc: "Manage Agents. For Authorized staff only",
  });

  // Use state
  const [searchQ, setSearchQ] = useState("");
  const [agentOrders, setAgentOrders] = useState([0]);

  // Dispatch
  const dispatch = useDispatch();

  // Use effect
  useEffect(() => {
    dispatch(RESTORE_FLASH());
  });

  // Typing the agent code
  const onChange = (e) => {
    const { value } = e.target;
    setSearchQ(value);
  };

  // Search for agents
  const search = (e) => {
    e.preventDefault();

    axios({
      method: "GET",
      url: `${Functions.getAPI()}/employee/agents?q=${searchQ}`,
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // If there are orders with the search agent code
        // Then get the orders Otherwise return an array
        // with zero to be reduced to a 0 amount commision
        if (data.orders.length > 0) {
          // Setting state values
          setAgentOrders(data.orders);
        } else {
          setAgentOrders([0]);
        }
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  };

  // Algorith to get the agent's commision if the order (Items are bought)
  //  is purchased. Only deduct 8% for items below 500 and 5% for the ones
  // above 500.
  return (
    <>
      <JobNav page="" />
      <div className="account" style={{ marginTop: "-20px" }}>
        <div className="search-cont">
          <div className="inputs">
            <input
              type="text"
              placeholder="Agent code"
              value={searchQ}
              onChange={onChange}
            />

            <button onClick={search}>Go</button>
          </div>
          <p style={{ color: "#bbb", fontSize: "14px", textAlign: "center" }}>
            Search an agent to get their commission!
          </p>

          <div className="info">
            {/* Agent code */}
            <p style={{ color: "#777", fontSize: "14px" }}>
              Agent code: <span>{searchQ}</span>
            </p>

            {/* Commission */}
            <h1>
              Commision: <span> R{Functions.agentCommission(agentOrders)}</span>{" "}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentStats;
