// Logged in state
const auth = localStorage.getItem("auth")
  ? localStorage.getItem("auth")
  : false;

const Auth = (state = auth, action) => {
  switch (action.type) {
    case "login":
      return true;
    case "logout":
      return false;
    default:
      return state;
  }
};
export default Auth;
