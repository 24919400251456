const FLASH = (msg) => {
  return {
    type: "FLASH",
    payload: msg,
  };
};
export const RESTORE_FLASH = () => {
  return {
    type: "RESTORE_FLASH",
  };
};

export default FLASH;
