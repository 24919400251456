import ProdCard from "../../assets/ProdCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { RESTORE_FLASH } from "../../redux/actions/Flash";
import Popup from "../../assets/Popup";
import Functions from "../../abstracts/Functions";
import ProdCardLoader from "../../assets/ProdCardLoader";

const Explore = () => {
  const [pagination, setPagination] = useState({});
  const [products, setProducts] = useState([]);
  const [shops, setShops] = useState([]);

  // get state
  const flash = useSelector((state) => state.flash);

  Functions.pageInfo({
    title: `Ordapple | Explore our range of items`,
    desc: "Viewing searched items",
  });

  // Dispatch
  const dispatch = useDispatch();

  // Use state
  const [display, setDisplay] = useState(false);

  // Use effect
  useEffect(() => {
    // Dispatching
    dispatch(RESTORE_FLASH());

    // Fetch data
    axios({
      url: `${Functions.getAPI()}/products`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Setting state values
        setPagination({
          next: data.products.next,
          previous: data.products.previous,
          numOfPages: data.products.numOfPages,
        });
        setShops(data.shops);
        setProducts(data.products.data);
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  }, [dispatch]);

  // Going to the next page or previous page
  const paginate = (e, limit, page) => {
    e.preventDefault();

    // Reset the states
    setPagination({});
    setShops([]);
    setProducts([]);

    axios({
      url: `${Functions.getAPI()}/products?limit=${limit}&page=${page}`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Setting state values
        setPagination({
          next: data.products.next,
          previous: data.products.previous,
          numOfPages: data.products.numOfPages,
        });
        setShops(data.shops);
        setProducts(data.products.data);
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
        } else {
          console.log(e);
        }
      });
  };
  console.log("shops:", shops);

  const renderProdCard = () => {
    return products.map((product, ind) => (
      <ProdCard
        key={ind}
        page="shopItems"
        setDisplay={setDisplay}
        shopName={shops[ind].name}
        shopVerified={shops[ind].verified}
        img={product.image.Location}
        productName={product.name}
        productCurrency={product.currency}
        productPrice={product.price}
        productId={product._id}
        shopId={shops[ind]._id}
        shopOwner={shops[ind].shopOwner}
        likes={product.likes}
      />
    ));
  };

  const productsRender = () => {
    if (Object.keys(pagination).length > 0) {
      // Pagination Products
      return "page" in pagination.previous || "page" in pagination.next ? (
        <>
          <div className="heading">
            <h1>Explore items</h1>
          </div>
          <div className="prod-card-container pagination">
            {renderProdCard()}
          </div>
          {/*  Previous page */}
          <div className="pageForms">
            {"page" in pagination.previous && (
              <form className="pageForm">
                <button
                  type="submit"
                  className="back"
                  onClick={(e) => {
                    paginate(
                      e,
                      pagination.previous.limit,
                      pagination.previous.page
                    );
                  }}
                >
                  <i className="fas fa-chevron-left back"></i> Back
                </button>
              </form>
            )}

            {/* Next page  */}
            {"page" in pagination.next && (
              <form className="pageForm">
                <button
                  type="submit"
                  className="next"
                  onClick={(e) => {
                    paginate(e, pagination.next.limit, pagination.next.page);
                  }}
                >
                  Next <i className="fas fa-chevron-right next"></i>
                </button>
              </form>
            )}
          </div>{" "}
        </>
      ) : (
        // Products without pagination
        <>
          {products.length === 0 ? (
            <div className="heading">
              <h1 style={{ color: "#bbb" }}>No Items found!</h1>
            </div>
          ) : (
            <>
              <div className="heading">
                <h1>Explore items</h1>
              </div>
              <div className="prod-card-container pagination">
                {renderProdCard()}
              </div>
            </>
          )}
        </>
      );
    } else {
      // Array elements to loop, for creating a
      // pre-loader animation for products as placeholder
      const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
      return (
        <div className="prod-card-container pagination">
          {arr.map((_, ind) => (
            <ProdCardLoader key={ind} />
          ))}
        </div>
      );
    }
  };

  return (
    <div className="shopname">
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      <div className="shopname-container">
        <div className="products-container">{productsRender()}</div>
      </div>
    </div>
  );
};

export default Explore;
