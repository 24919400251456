import { Link } from "react-router-dom";

const PopupOpts = (props) => {
  return (
    <div
      className="glowing-form"
      style={props.showOpts ? { display: "flex" } : { display: "none" }}
    >
      <div className="contents">
        <div className="close" onClick={props.togglePopupOpts}>
          X
        </div>

        <Link
          to="/shop"
          className="dry"
          onClick={() => {
            props.setShowOpts(false);
          }}
        >
          Explore
        </Link>
        <Link
          to="/categories"
          className="dry"
          onClick={() => {
            props.setShowOpts(false);
          }}
        >
          Categories
        </Link>
        <Link
          to="/tags"
          className="dry"
          onClick={() => {
            props.setShowOpts(false);
          }}
        >
          Tags / Departments
        </Link>
        <Link
          to="/shops"
          className="dry"
          onClick={() => {
            props.setShowOpts(false);
          }}
        >
          Shops
        </Link>
      </div>
    </div>
  );
};

export default PopupOpts;
