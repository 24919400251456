import ChangeLog from "../abstracts/ChangeLog";

const VersionCard = () => {
  const ulStyles = {
    marginLeft: "20px",
  };

  return (
    <>
      {ChangeLog.map((el, ind) => (
        <div key={ind}>
          <p className="title">Version:</p>
          <p>{el.vnum}</p>
          <p className="title">Release date:</p>
          <p>{el.rd}</p>
          <br />
          <ul style={ulStyles}>
            {el.vd.map((text, i) => (
              <li key={i}>{text}</li>
            ))}
          </ul>
          <br />
        </div>
      ))}
    </>
  );
};

export default VersionCard;
