import React from "react";
import AppCrash from "./Err/AppCrash";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // This will update the UI to show the error component if an error occured
    return { hasError: true };
  }

  // Getting the error message
  componentDidCatch(err, info) {
    // Logging the error to an error reporting service
    // logErrorToMyService(err, info);

    console.log("err: ", err);
    console.log("err info: ", info);
  }

  // Rendering the error component
  render() {
    if (this.state.hasError) {
      return <AppCrash />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
