import AdminNav from "./assets/AdminNav";
import OrdersCard from "../../assets/OrdersCard";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import AdminActions from "../../redux/actions/AdminActions";
import axios from "axios";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import Popup from "../../assets/Popup";
import Functions from "../../abstracts/Functions";
import { useEffect } from "react";

const AdminOrders = () => {
  // Getting values from redux state
  const user = useSelector((state) => state.user);
  const orders = useSelector((state) => state.admin_orders);
  const admin_info = useSelector((state) => state.admin_info);
  const flash = useSelector((state) => state.flash);

  // Use state
  const [display, setDisplay] = useState(false);
  const [filterOrders, setFilterOrders] = useState("all");
  const [filteredOrders, setFilteredOrders] = useState(orders);

  Functions.pageInfo({
    title: "Ordapple | Manage Your Admin Orders",
    desc: "Managing Your Admin Orders",
  });

  // Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RESTORE_FLASH());

    axios({
      url: `${Functions.getAPI()}/admin/${user._id}`,
      method: "GET",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        dispatch(AdminActions.ORDERS(data.shop.orders.reverse()));
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(e);
        }
      });
  }, [dispatch, user._id]);

  useEffect(() => {
    if (filterOrders === "all") {
      setFilteredOrders(orders);
    } else if (filterOrders === "pending payment") {
      setFilteredOrders(orders.filter((o) => o.status === "pending payment"));
    } else if (filterOrders === "pending delivery") {
      setFilteredOrders(orders.filter((o) => o.status === "pending delivery"));
    } else if (filterOrders === "complete") {
      setFilteredOrders(orders.filter((o) => o.status === "complete"));
    }
  }, [dispatch, orders, filterOrders]);

  // DELETING ALL ORDERS
  const deleteAllOrders = (e) => {
    e.preventDefault();
    setDisplay(true);

    axios({
      url: `${Functions.getAPI()}/admin/delete-orders/${admin_info._id}`,
      method: "POST",
      withCredentials: true,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        dispatch(FLASH({ success: data.success, warn: "", err: "" }));
        dispatch(AdminActions.REMOVE_ALL_ORDERS());
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          console.log(res.data);

          // Dispatching
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ warn: res.data.warn, err: "", success: "" }));
          }
        } else {
          console.log(e);
        }
      });
  };

  const filter = (e) => {
    const { value } = e.target;
    setFilterOrders(value);
  };

  return (
    <div>
      {flash.err !== "" && (
        <Popup display={display} setDisplay={setDisplay} err={flash.err} />
      )}
      {flash.success !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          success={flash.success}
        />
      )}
      {flash.warn !== "" && (
        <Popup display={display} setDisplay={setDisplay} warn={flash.warn} />
      )}
      <AdminNav page="orders" />
      <div className="orders">
        <div className="orders-card-container">
          {/* Delete all orders */}
          <form
            className="delete-all-form"
            action="/buyer/delete-orders"
            method="POST"
          >
            <select
              name="filter"
              id="filter"
              value={filterOrders}
              onChange={filter}
            >
              <option value="all"> All</option>
              <option value="pending payment">Pending payment</option>
              <option value="pending delivery">Pending delivery</option>
              <option value="complete">Complete</option>
            </select>
            <button
              className="delete-all"
              type="submit"
              onClick={deleteAllOrders}
            >
              Delete All
            </button>
          </form>
          {/* Note */}
          <div className="note">
            <p>Please, delete all orders after cashing out!</p>
          </div>
          {/* Orders */}
          {filteredOrders.length >= 1 ? (
            filteredOrders.map((order) => (
              <OrdersCard
                key={order._id}
                buyer={false}
                display={display}
                setDisplay={setDisplay}
                dispatch={dispatch}
                orderNumber={order.orderNumber}
                dateOrdered={order.dateOrdered}
                itemsInOrder={order.itemsInOrder}
                customerName={order.customerName}
                customerEmail={order.customerEmail}
                customerPhone={order.customerPhone}
                customerAddress={order.customerAddress}
                pickUpTime={order.pickUpTime}
                elegibleForDelivery={order.elegibleForDelivery}
                totalPrice={order.totalPrice}
                status={order.status}
                agentCode={order.agentCode}
              />
            ))
          ) : (
            <h2 className="no-orders">No orders available!</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminOrders;
