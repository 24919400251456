const FollowWhs = ({ foll, toggleWhsDisplay }) => {
  return (
    <div className="followers-whs">
      <p className="followers">
        Followers
        <span className="dot"></span>
        <span className="stat">{foll}</span>
      </p>
      <button className="whs-btn" onClick={toggleWhsDisplay}>
        <i className="fas fa-pencil"></i> Working_hours
      </button>
    </div>
  );
};

export default FollowWhs;
