import JobNav from "./assets/JobNav";
import CardMenu from "./assets/CardMenu";
import CardContent from "./assets/CardContent";
import Functions from "../../abstracts/Functions";
import { createRef } from "react";
import ToggleDisplay from "../../abstracts/ToggleDisplay.js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FLASH, { RESTORE_FLASH } from "../../redux/actions/Flash";
import Popup from "../../assets/Popup";
import { useState } from "react";
import axios from "axios";
import Employee from "../../redux/actions/Employee";
import Content from "./assets/Content";
import Loader from "../../abstracts/Loader";
import socket from "../../config/socket";

const Stats = ({ history }) => {
  // Redux state
  const user = useSelector((state) => state.user);
  const flash = useSelector((state) => state.flash);
  const employee_stats = useSelector((state) => state.employee_stats);

  Functions.pageInfo({
    title: "Ordapple | App Statistics",
    desc: "View App Statistics. For Authorized staff only",
  });

  // Use state
  const [display, setDisplay] = useState(true);
  const [liveUsers, setLiveUsers] = useState(1);

  // REFS
  const ref1 = createRef();
  const ref2 = createRef();
  const ref3 = createRef();
  const ref4 = createRef();
  const ref5 = createRef();
  const ref6 = createRef();
  const ref7 = createRef();
  const ref8 = createRef();
  const ref9 = createRef();
  const ref10 = createRef();
  const ref11 = createRef();
  const ref12 = createRef();
  const ref13 = createRef();

  // Displaying and the content
  const toggleVisibleContent = (e) => {
    ToggleDisplay(e, [
      ref1,
      ref2,
      ref3,
      ref4,
      ref5,
      ref6,
      ref7,
      ref8,
      ref9,
      ref10,
      ref11,
      ref12,
      ref13,
    ]);
  };

  // Redux disparch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RESTORE_FLASH());

    axios({
      method: "GET",
      url: `${Functions.getAPI()}/${user._id}/employee/stats/`,
    })
      .then(async (res) => {
        // Waiting for the data before proceeding
        const data = await res.data;

        // Dispatching
        dispatch(Employee.GET_STATS(data));
      })
      .catch((e) => {
        const res = e.response;
        if (res) {
          if (res.data.err) {
            dispatch(FLASH({ err: res.data.err, success: "", warn: "" }));
          } else {
            dispatch(FLASH({ err: "", success: "", warn: res.data.warn }));
          }
        } else {
          console.log(e);
        }
      });
  }, [dispatch, user._id]);

  // Active users
  useEffect(() => {
    socket.on("active-users", (stat) => {
      setLiveUsers(stat.count);
    });
  }, [liveUsers]);

  const renderStats = () => {
    if (Object.keys(employee_stats).length >= 1) {
      return (
        <>
          <p className="total">
            Total users: <span>{employee_stats.totalUsers}</span>
          </p>
          {/* <!-- USERS --> */}
          <div className="users-container">
            <span></span>
            <span></span>
            <span></span>
            <span></span>

            <h1>Right now</h1>
            <h2 id="active-users">
              {Functions.numFormatter(
                liveUsers !== "" ? liveUsers : employee_stats.stats.liveUsers
              )}
            </h2>
            <p>Active users on site</p>
          </div>
          {/* <!-- Active users --> */}
          <div className="heading">
            <h2>Users info</h2>
          </div>

          {/* cl = the position of the component */}
          <CardMenu
            toggle={toggleVisibleContent}
            title="Active users p.w:"
            desc="Total number of 1-day active users for each day in a week."
            cl="1"
          />
          <CardContent ref={ref1}>
            <Content
              content={Functions.numFormatter(employee_stats.stats.activeUsers)}
              span="users"
            />
          </CardContent>

          {/* <!-- Total users --> */}
          <CardMenu
            toggle={toggleVisibleContent}
            title="Total users p.w:"
            desc="The total number of users in a week."
            cl="2"
          />
          <CardContent ref={ref2}>
            <Content
              content={employee_stats.stats.uniqueUsers}
              span={"Users"}
            />
          </CardContent>

          {/* <!-- Loyal users --> */}
          <CardMenu
            toggle={toggleVisibleContent}
            title="Loyalty:"
            desc="The number of days elapsed since users last visited the app."
            cl="3"
          />
          <CardContent ref={ref3}>
            <Content span="Users" content={employee_stats.stats.loyalUsers} />
          </CardContent>

          {/* <!-- Weekly users --> */}
          <CardMenu
            toggle={toggleVisibleContent}
            title="Weekly users:"
            desc="Total number of 7-day active users for each day in a week."
            cl="4"
          />
          <CardContent ref={ref4}>
            {employee_stats.stats.weeklyUsers.map((dailyUsers, ind) => (
              <Content
                key={ind}
                content={dailyUsers[1]}
                span={Functions.formatDate(dailyUsers[0])}
              />
            ))}
          </CardContent>

          {/* <!-- DEVICES -->
     <!-- Devices used --> */}
          <div className="heading">
            <h2>Device info</h2>
          </div>
          <CardMenu
            toggle={toggleVisibleContent}
            title="Devices used:"
            desc="The type of devices used to access the app."
            cl="5"
          />
          <CardContent ref={ref5}>
            {employee_stats.stats.device.map((users, ind) => (
              <Content
                key={ind}
                content={Functions.numFormatter(users[1])}
                span={users[0]}
              />
            ))}
          </CardContent>

          {/* <!-- GEO NETWORK -->
     <!-- Country --> */}
          <div className="heading">
            <h2>Geo Network info</h2>
          </div>
          <CardMenu
            toggle={toggleVisibleContent}
            title="Countries:"
            desc="The top countries where the app was access at."
            cl="6"
          />
          <CardContent ref={ref6}>
            {employee_stats.stats.country.map((users, ind) => (
              <Content
                key={ind}
                span={users[0]}
                content={Functions.numFormatter(users[1])}
              />
            ))}
          </CardContent>

          {/* <!-- Cities --> */}
          <CardMenu
            toggle={toggleVisibleContent}
            title="Cities:"
            desc="The top cities where the app was access at."
            cl="7"
          />
          <CardContent ref={ref7}>
            {employee_stats.stats.city.map((users, ind) => (
              <Content
                key={ind}
                span={users[0]}
                content={Functions.numFormatter(users[1])}
              />
            ))}
          </CardContent>

          {/* <!-- SESSIONS -->
     <!-- total sessions --> */}
          <div className="heading">
            <h2>Sessions</h2>
          </div>
          <CardMenu
            toggle={toggleVisibleContent}
            title="Sessions (30 days):"
            desc="The total number of sessions in 30 days."
            cl="8"
          />
          <CardContent ref={ref8}>
            <Content
              span="Sessions"
              content={Functions.numFormatter(employee_stats.stats.sessions)}
            />
          </CardContent>

          {/* <!-- total seconds spend on the app --> */}
          <CardMenu
            toggle={toggleVisibleContent}
            title="session durations:"
            desc="Total duration of users sessions."
            cl="9"
          />
          <CardContent ref={ref9}>
            <Content
              content={Functions.msToMinSec(
                employee_stats.stats.sessionDuration
              )}
            />
          </CardContent>

          {/* <!-- The percentage of single-page session (i.e., session in which the person left the property from the first page). --> */}
          <CardMenu
            toggle={toggleVisibleContent}
            title="Bounce rate:"
            desc="The percentage of single-page session (i.e., session in
           which the person left the property from the first page)."
            cl="10"
          />
          <CardContent ref={ref10}>
            <Content
              content={`${Functions.percentage(
                employee_stats.stats.bounceRate
              )}%`}
            />
          </CardContent>

          {/* <!-- SOURCES --> */}
          {/* <!-- The source of referrals. --> */}
          <div className="heading">
            <h2>Sources</h2>
          </div>
          <CardMenu
            toggle={toggleVisibleContent}
            title="Sources:"
            desc="The source of referrals."
            cl="11"
          />
          <CardContent ref={ref11}>
            {employee_stats.stats.source.map((users, ind) => (
              <Content
                key={ind}
                span={users[0]}
                content={Functions.numFormatter(users[1])}
              />
            ))}
          </CardContent>

          {/* <!-- organicSearches --> */}
          <CardMenu
            toggle={toggleVisibleContent}
            title="Organic searches:"
            desc=" The number of organic searches happened in a session. This
           is search engine agnostic."
            cl="12"
          />
          <CardContent ref={ref12}>
            <Content
              content={Functions.percentage(
                employee_stats.stats.organicSearches
              )}
            />
          </CardContent>

          {/* Traffic */}
          <div className="heading">
            <h2>Page traffic</h2>
          </div>
          {/* <!-- organicSearches --> */}
          <CardMenu
            toggle={toggleVisibleContent}
            title="Page views:"
            desc="The total number of pageviews for the app."
            cl="13"
          />
          <CardContent ref={ref13}>
            <Content
              content={Functions.numFormatter(employee_stats.stats.pageviews)}
              span="Views"
            />
          </CardContent>
        </>
      );
    } else {
      return <Loader />;
    }
  };

  return (
    <div>
      {flash.err !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          err={flash.err}
          history={history}
        />
      )}
      {flash.warn !== "" && (
        <Popup
          display={display}
          setDisplay={setDisplay}
          warn={flash.warn}
          history={history}
        />
      )}

      <JobNav page="stats" />
      <div className="account">
        <div className="account-container">
          <div className="stats">
            <div className="mobile-view">{renderStats()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
