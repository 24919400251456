import Functions from "../../abstracts/Functions";
import Tags from "../../abstracts/Tags";
import Tile from "../../assets/Tile";

const Departments = () => {
  // Seperating the string with spaces by dashes
  const toDash = (str) => str.split(" ").join("-");

  const tags = Tags.map((i) => {
    return {
      name: i.replace("#", ""),
      route: `/department/${toDash(i.replace("#", ""))}`,
    };
  });

  Functions.pageInfo({
    title: "Filter Items By Department",
    desc: "Get Items From A Specific Department",
  });

  return (
    <div className="tile-container">
      <div className="heading">
        <h1>Tags / Departments</h1>
      </div>

      <div className="tile-content">
        {tags.map((item, ind) => {
          return <Tile key={ind} option={item.name} route={item.route} />;
        })}
      </div>
    </div>
  );
};
export default Departments;
