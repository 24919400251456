const Popup = ({
  err,
  success,
  warn,
  display,
  setDisplay,
  history,
  redirect,
}) => {
  const closePopup = (e) => {
    if (history !== undefined) {
      e.preventDefault();
      setDisplay(false);
      if (redirect !== undefined) {
        history.push(redirect);
      } else {
        history.goBack();
      }
    } else {
      e.preventDefault();
      setDisplay(false);
    }
  };
  if (err !== undefined) {
    return (
      <div
        style={display ? { display: "flex" } : { display: "none" }}
        className="popup-container"
      >
        <div className="popup">
          <h2 className="err">Error!</h2>
          <p> {err} </p>
          <button className="btn-err" onClick={closePopup}>
            OK
          </button>
        </div>
      </div>
    );
  } else if (success !== undefined) {
    return (
      <div
        style={display ? { display: "flex" } : { display: "none" }}
        className="popup-container"
      >
        <div className="popup">
          <h2 className="success">Success!</h2>
          <p> {success} </p>
          <button className="btn-success" onClick={closePopup}>
            OK
          </button>
        </div>
      </div>
    );
  } else if (warn !== undefined) {
    return (
      <div
        style={display ? { display: "flex" } : { display: "none" }}
        className="popup-container"
      >
        <div className="popup">
          <h2 className="warn">Warning!</h2>
          <p> {warn} </p>
          <button className="btn-warn" onClick={closePopup}>
            OK
          </button>
        </div>
      </div>
    );
  }
};

export default Popup;
